import axios from 'axios';
import HttpError from '@/shared/classes/Error/HttpError';
import { log } from './utils';
const Signal = require('signals');

export default class DataService {
  constructor() {
    this._initialiseSignals();
    this._initAxiosDefaults();
    this._currentRequestId = undefined;
  }

  /**
   * Allows a single request to ignore any session errors.
   * Automatically restored after the next request.
   */
  silenceSessionErrorHandling() {
    axios.interceptors.response.eject(this.sessionErrorHandler);
    delete this.sessionErrorHandler;
    return this;
  }

  setBearer(bearerToken) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + bearerToken;
  }

  setRequestId(requestId) {
    this._currentRequestId = requestId;
    return this;
  }

  resetRequestId() {
    this._currentRequestId = undefined;
  }

  getFromAPI(url, params = {}) {
    log(['getFromAPI', url, params]);
    return axios
      .get(url, {
        params: params,
      })
      .finally(() => {
        this.resetRequestId();
      });
  }

  putToAPI(url, data = {}) {
    log(['putToAPI', url, data]);
    return axios.put(url, data).finally(() => {
      this.resetRequestId();
    });
  }

  postToAPI(url, data = {}) {
    log(['postToAPI', url, data]);
    return axios.post(url, data).finally(() => {
      this.resetRequestId();
    });
  }

  deleteFromAPI(url, params = {}) {
    log(['deleteFromAPI', url, params]);
    return axios
      .delete(url, {
        data: params,
      })
      .finally(() => {
        this.resetRequestId();
      });
  }

  isSessionError(statusCode) {
    return [400, 401, 403, 419].includes(statusCode);
  }

  _initAxiosDefaults() {
    axios.defaults.baseURL = process.env.VUE_APP_BACKEND_ROOT;
    this._createSilencableResponseHandlers();
    // always restore any silenced handlers once a response is received.
    this._restoreHandlersHandler = axios.interceptors.response.use(
      (response) => {
        log(['successful response', response]);
        this._createSilencableResponseHandlers();
        return response;
      },
      (error) => {
        log(['error response', error]);
        this._createSilencableResponseHandlers();
        return Promise.reject(error);
      },
    );
  }

  _createSilencableResponseHandlers() {
    this._createSessionErrorHandler();
    this._createServerErrorHandler();
    this._createConnectionErrorHandler();
  }

  _createSessionErrorHandler() {
    if (!this.sessionErrorHandler) {
      this.sessionErrorHandler = axios.interceptors.response.use(undefined, (error) => {
        if (error.response && this.isSessionError(error.response.status)) {
          error = new HttpError(error, error.response.status, this._currentRequestId);
          this.sessionError.dispatch(error);
        }
        return Promise.reject(error);
      });
    }
  }

  _createServerErrorHandler() {
    if (!this.serverErrorHandler) {
      this.serverErrorHandler = axios.interceptors.response.use(undefined, (error) => {
        if (error.response && [500, 501, 502, 503].includes(error.response.status)) {
          error = new HttpError(error, error.response.status, this._currentRequestId);
          this.serverError.dispatch(error);
        }
        return Promise.reject(error);
      });
    }
  }

  _createConnectionErrorHandler() {
    if (!this.connectionErrorHandler) {
      this.connectionErrorHandler = axios.interceptors.response.use(undefined, (error) => {
        if (!error.response) {
          this.connectionError.dispatch(this._currentRequestId);
        }
        return Promise.reject(error);
      });
    }
  }

  _initialiseSignals() {
    this.sessionError = new Signal();
    this.serverError = new Signal();
    this.connectionError = new Signal();
  }
}
