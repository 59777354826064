import { states as performWorkFlowStates } from './workflow';
import { events as performBrokerMessages } from '@/shared/classes/Perform/events';
import MeasurementRunner from '@/controller/classes/MeasureController/MeasurementRunner';
import { roundAccurately } from '@/shared/utils';

const Signal = require('signals');

export default class MeasureController {
  constructor(messageBroker, targetId) {
    this._messageBroker = messageBroker;
    this.targetId = targetId;
    this._performResolver = undefined;
    this._performRejecter = undefined;
    this.measurementRunner = undefined;
    this._measurementType = undefined;
    this.testTypeLimits = {};
    this.maxLogMar = this.DEFAULT_MAX_LOGMAR;
    this.minLogMar = this.DEFAULT_MIN_LOGMAR;

    this._initialiseSignals();
    this._initialiseWorkflow();
  }

  get DEFAULT_MAX_LOGMAR() {
    return 0.8;
  }

  get DEFAULT_MIN_LOGMAR() {
    return 0.3;
  }

  get workflowState() {
    return this._workflowState;
  }

  set workflowState(state) {
    if (!Object.values(performWorkFlowStates).includes(state)) {
      throw new Error(`Invalid workflow state ${state}`);
    }
    this._workflowState = state;
    this.workflowStateChanged.dispatch(state);

    this._dispatchAppStateUpdate();
  }

  get measurementType() {
    return this._measurementType;
  }

  set measurementType(measurementType) {
    this._measurementType = measurementType;
    // default to the first test type available
    this.testType = measurementType.testTypes[0];
    this.terminationCriteria = measurementType.defaultTermination;
    this.scoringMethod = measurementType.scoringMethods[0];
    this.crowdingBox = measurementType.defaultCrowdingBox ?? true;
    this.maxLogMar = measurementType.maxLogMar ?? this.DEFAULT_MAX_LOGMAR;
    this.minLogMar = measurementType.minLogMar ?? this.DEFAULT_MIN_LOGMAR;
    this.symbolSpacingScale = measurementType.spacingScale ?? 1;
  }

  get testType() {
    return this._testType;
  }

  // object defining the generator and optotype to use
  // for carrying out measurements
  set testType(testType) {
    if (!this.measurementType) {
      throw new Error('Cannot have a testType without a measurement type setting');
    }
    const availableTestType = this.measurementType.testTypes.filter(
      (availableTestType) =>
        availableTestType &&
        availableTestType.generator === testType.generator &&
        availableTestType.optotype === testType.optotype,
    );
    if (availableTestType.length === 0) {
      throw new Error('Invalid test type selection for current measurementType');
    }
    this._testType = testType;

    this._setLogMarRangeByTestType();
  }

  /**
   * The current test type may impact the logMar limits for the measurement, this
   * method updates them as appropriately.
   *
   * @private
   */
  _setLogMarRangeByTestType() {
    this.maxLogMar = this.testTypeLimits[this.testType.id]
      ? Math.min(this.testTypeLimits[this.testType.id].max, this.measurementType.maxLogMar)
      : this.measurementType.maxLogMar;
    this.minLogMar = this.testTypeLimits[this.testType.id]
      ? Math.max(this.testTypeLimits[this.testType.id].min, this.measurementType.minLogMar)
      : this.measurementType.minLogMar;
  }

  /**
   * Set the logMar limits that can be supported for each test type that might be
   * performed in this measurement. Updates the set limits for the current test type
   * to reflect this limitation (or resets to the configured limits of the measurement type)
   *
   * @param limits
   */
  setLogMarLimitsForTestTypes(limits) {
    if (!this.measurementType) {
      throw new Error('Cannot set test type limits when no measurement type is set.');
    }

    this.testTypeLimits = this.measurementType.testTypes.reduce((byTestTypeId, testType) => {
      if (!limits[testType.id]) {
        return byTestTypeId;
      }

      if (
        limits[testType.id].max < this.measurementType.maxLogMar ||
        limits[testType.id].min > this.measurementType.minLogMar
      ) {
        byTestTypeId[testType.id] = {
          max: this.getLogMarTestMaxLimit(
            Math.min(limits[testType.id].max, this.measurementType.maxLogMar),
          ),
          min: this.getLogMarTestMinLimit(
            Math.max(limits[testType.id].min, this.measurementType.minLogMar),
          ),
        };
      }

      return byTestTypeId;
    }, {});

    // apply the override or set to the current measurementType limit
    this._setLogMarRangeByTestType();
  }

  getMeasurementSettings() {
    return {
      measurementTypeId: this.measurementType.id,
      testType: this.testType,
      terminationCriteria: this.terminationCriteria,
      scoringMethodId: this.scoringMethod.id,
      distance: this.measurementType.distance,
      crowdingBox: this.crowdingBox,
      maxLogMar: this.getLogMarTestMaxLimit(this.maxLogMar),
      minLogMar: this.getLogMarTestMinLimit(this.minLogMar),
      symbolSpacingScale: this.symbolSpacingScale,
      defaultLateralityId: this.measurementType.defaultLateralityId,
    };
  }

  getLogMarTestMaxLimit(max) {
    return roundAccurately(Math.floor(max * 10) / 10, 2);
  }

  getLogMarTestMinLimit(min) {
    return roundAccurately(Math.ceil(min * 10) / 10, 2);
  }

  isTesting() {
    return this.workflowState === performWorkFlowStates.TEST;
  }

  /**
   * Set measurement settings, and reset the score history if any values are changed from the current values
   * @param settings
   */
  setMeasurementSettings(settings) {
    let needToReset = false;
    [
      'distance',
      'testType',
      'terminationCriteria',
      'scoringMethod',
      'crowdingBox',
      'maxLogMar',
      'minLogMar',
    ].forEach((attr) => {
      if (settings[attr] !== undefined) {
        if (this[attr] !== settings[attr]) {
          this[attr] = settings[attr];
          needToReset = true;
        }
      }
    });

    if (needToReset) {
      this._initResults();
      if (this.measurementRunner) {
        this.measurementRunner.cancel();
      }
    }
  }

  _initialiseWorkflow() {
    this.workflowState = performWorkFlowStates.INIT;
  }

  begin(measurementType, onStartCallback, onFinishCallback) {
    this._initResults();
    this.measurementType = measurementType;

    return this._messageBroker
      .sendAcknowledgedMessage(performBrokerMessages.PERFORM_INIT, this.targetId)
      .then(() => {
        if (onStartCallback !== undefined) {
          onStartCallback();
        }
        return this._createPerformPromise(onFinishCallback);
      })
      .catch((error) => {
        this._initResults(false);
        throw error;
      });
  }

  _initResults(setStartTime) {
    if (setStartTime === undefined) {
      setStartTime = true;
    }
    this.results = [];
    this.startTime = setStartTime ? new Date() : undefined;
  }

  _createPerformPromise(finishCallback) {
    return new Promise((resolve, reject) => {
      this._performResolver = () => {
        if (finishCallback) {
          finishCallback();
        }
        resolve();
      };
      this._performRejecter = (err) => {
        if (finishCallback) {
          finishCallback();
        }
        reject(err);
      };
    });
  }

  /**
   * Perform a measurement for the given laterality and correction type
   *
   * @param lateralityId
   * @param correctionTypeId
   * @returns {*}
   */
  performTest(lateralityId, correctionTypeId) {
    const measurementSettings = this.getMeasurementSettings();
    const runner = new MeasurementRunner(this._messageBroker, this.targetId, measurementSettings);
    return (
      runner
        .begin(() => {
          this.measurementRunner = runner;
          this.currentTestSettings = {
            lateralityId: lateralityId,
            correctionTypeId: correctionTypeId,
          };
          this.workflowState = performWorkFlowStates.TEST;
        })
        .then(([score, terminated]) => {
          const result = {
            logMar: score,
            lateralityId: lateralityId,
            correctionTypeId: correctionTypeId,
            lines: runner.measurementLines,
            outsideMax: terminated ? false : score === measurementSettings.maxLogMar,
            outsideMin: terminated ? false : score !== measurementSettings.maxLogMar,
          };

          this.addResult(result);
        })
        // rejection essentially indicates the test was cancelled
        .catch(() => {})
        .finally(() => {
          return this.setToPendingTestState().then(() => {
            this.measurementRunner = undefined;
            this.currentTestSettings = undefined;
          });
        })
    );
  }

  addResult(result) {
    if (this.results === undefined) {
      this.results = [];
    }
    this.results.push(result);
  }

  setToPendingTestState() {
    return this._messageBroker
      .sendAcknowledgedMessage(performBrokerMessages.PERFORM_INIT, this.targetId)
      .then(() => {
        this.workflowState = performWorkFlowStates.INIT;
      });
  }

  finish() {
    if (!this._performResolver) {
      throw new Error('cannot finish - not started yet.');
    }
    return this._messageBroker
      .sendAcknowledgedMessage(performBrokerMessages.PERFORM_INIT, this.targetId)
      .then(() => {
        this.workflowState = performWorkFlowStates.INIT;
        this._complete();
      });
  }

  release() {
    this._removeAllSignalHandlers();
  }

  _complete() {
    if (this._performResolver !== undefined) {
      this._performResolver();
    }
    this._resetResolver();
  }

  _fail() {
    if (this._performRejecter !== undefined) {
      this._performRejecter();
    }
    this._resetResolver();
  }

  _resetResolver() {
    this._performResolver = undefined;
    this._performRejecter = undefined;
  }

  _initialiseSignals() {
    this.workflowStateChanged = new Signal();
    this.appStateUpdate = new Signal();
  }

  _removeAllSignalHandlers() {
    this.workflowStateChanged.removeAll();
    this.appStateUpdate.removeAll();
  }

  _dispatchAppStateUpdate() {
    this.appStateUpdate.dispatch();
  }
}
