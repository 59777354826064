<template>
  <router-view v-if="!restarting" />
</template>

<style lang="scss"></style>
<script>
/* istanbul ignore file */
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('controller', {
      targetRoute: (state) => state.targetRoute,
    }),
    restarting() {
      return this.targetRoute === 'restart';
    },
  },
};
</script>
