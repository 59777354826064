import { createStore } from 'vuex';
import controllerStore from '@/controller/store';
import patientStore from '@/patient/store';

export default createStore({
  modules: {
    controller: controllerStore,
    patient: patientStore,
  },
});
