import { roundAccurately } from '@/shared/utils';

/**
 * Calculate the size a symbol must be for the given logMar and distance
 *
 * @param logMar
 * @param distance
 * @returns {number}
 */
export const symbolSizeFor = (logMar, distance) => {
  const angle = (5 * Math.pow(10, logMar)) / 60;
  const mmScale = Math.tan((angle * Math.PI) / 180);
  return mmScale * distance;
};

/**
 * returns the logMar represented by a symbol at the given size and distance
 * (rounded to 2 d.p.)
 *
 * @param size
 * @param distance
 * @returns {number}
 */
export const symbolLogMarFor = (size, distance) => {
  const mmScale = size / distance;
  const angle = (Math.atan(mmScale) * 180) / Math.PI;
  return roundAccurately(Math.log(12 * angle) / Math.log(10), 2);
};

/**
 * Returns the lowest logMar that can displayed that will be at least 1 pixel smaller than logMar 0.1 greater in size.
 *
 * @param pixelSize
 * @param distance
 * @param startLogMar
 * @param limitLogMar
 * @returns {number|*}
 */
export const minLogMarFor = (pixelSize, distance, startLogMar, limitLogMar) => {
  let logMar = startLogMar;
  const calcSize = (lm, d) => {
    return symbolSizeFor(lm, d) / pixelSize;
  };

  while (logMar > limitLogMar) {
    if (calcSize(logMar, distance) - calcSize(roundAccurately(logMar - 0.1, 2), distance) < 1) {
      return logMar;
    }
    logMar = roundAccurately(logMar - 0.1, 2);
  }

  return limitLogMar;
};
