import { commsChannelFactory } from '@/shared/classes/CommsChannel';
import { log } from '@/shared/utils';
import { v4 as uuid } from 'uuid';
import { events } from './events';

const Signal = require('signals');

export class MessageBroker {
  constructor(channelConfiguration) {
    this._initialiseSignals();
    this._channel = commsChannelFactory(channelConfiguration.type, channelConfiguration.settings);
    this._id = uuid();
    this._expectedMessages = [];
    this._timedoutMessageDelay = 2500;
    this._timeoutsForAcknowledgedMessages = [];
    this._timedoutMessages = [];
    this.isSubscribed = false;
  }

  get id() {
    return this._id;
  }

  /**
   * Connect/ensure connection established to the underlying comms channel for sending messages
   *
   * @returns {*}
   */
  connect() {
    return this._channel.subscribe().then(() => {
      if (!this.isSubscribed) {
        // only set up standard message broken channel handling once
        this.isSubscribed = true;
        this._initialiseChannelEventHandlers();
        this._channel.broadcast(events.CLIENT_CONNECTED, {
          id: this._id,
        });
      }
    });
  }

  disconnect() {
    this._channel.disconnect();
  }

  sendUnacknowledgedMessage(messageType, targetId, data) {
    const messageWrapper = this._buildMessageWrapper(messageType, targetId, data);

    return this._channel.broadcast(events.MESSAGE_TO_NOT_ACK, messageWrapper).then(() => true);
  }

  /**
   * Set a callback handler for the given messageType
   *
   * @param messageType
   * @param handler
   */
  attachMessageHandler(messageType, handler) {
    if (this._messageHandlers[messageType] === undefined) {
      this._messageHandlers[messageType] = [handler];
    } else {
      this._messageHandlers[messageType].push(handler);
    }
  }

  /**
   * Send a message to the specified targetId and return a promise that resolves when the message has been acknowledged.
   *
   * @param messageType
   * @param targetid
   * @param data
   * @returns {Promise<minimist.Opts.unknown>|*}
   */
  sendAcknowledgedMessage(messageType, targetId, data) {
    const messageWrapper = this._buildMessageWrapper(messageType, targetId, data);

    const [messagePromise, messageId] = this._expectMessage(messageType);
    messageWrapper.messageId = messageId;

    this._timeoutsForAcknowledgedMessages[messageId] = setTimeout(() => {
      this._messageTimeout(messageId);
    }, this._timedoutMessageDelay);

    this._channel.broadcast(events.MESSAGE_TO_ACK, messageWrapper);
    return messagePromise;
  }

  release() {
    this._releaseSignalHandlers();
    this._rejectExpectedMessages('message broker being released.');
    this.disconnect();
  }

  _messageTimeout(messageId) {
    // add message to list of timed out messages
    this._timedoutMessages[messageId] = messageId;
    this.messageAcknowledgmentDelayed.dispatch(messageId);
  }

  _clearMessageTimeouts(messageId) {
    if (Object.hasOwnProperty.call(this._timeoutsForAcknowledgedMessages, messageId)) {
      clearTimeout(this._timeoutsForAcknowledgedMessages[messageId]);
      delete this._timeoutsForAcknowledgedMessages[messageId];
    }
    this._clearTimedoutMessage(messageId);
  }

  _clearTimedoutMessage(messageId) {
    if (Object.hasOwnProperty.call(this._timedoutMessages, messageId)) {
      delete this._timedoutMessages[messageId];
      this.delayedMessageAcknowledged.dispatch(messageId);
    }
  }

  _buildMessageWrapper(messageType, targetId, data) {
    return {
      targetId: targetId,
      messageType: messageType,
      data: data === undefined ? {} : data,
    };
  }

  /**
   * Generate an "expected message" object that contains  a unique id and references to the resolve/reject methods of
   * a promise for that message to be sent. Push this onto the expected message stack for future resolution/rejection
   * when receiving message acks on the underlying comms channel.
   *
   * Returns the promise and the id of the message.
   *
   * @param event
   * @returns {(Promise<unknown>|*)[]}
   * @private
   */
  _expectMessage(event) {
    const message = {
      id: uuid(),
    };

    const promise = new Promise((resolve, reject) => {
      if (!this._channel) {
        reject(
          new Error(
            'cannot expect message until you have subscribed to the controller broadcastChannel',
          ),
        );
      } else {
        message.resolve = resolve;
        message.reject = reject;
      }
    });

    this._expectedMessages.push(message);

    return [promise, message.id];
  }

  /**
   * Call back for receiving message ack from the underlying comms channel and resolving the expected message promises.
   *
   * Rejects the oldest expected message if it does not match the received ack.
   *
   * @param event
   * @private
   */
  _handleMessageAck(event) {
    if (!this._expectedMessages.length) {
      return;
    }

    this._clearMessageTimeouts(event.messageId);

    // This always expects messages to be acknowledged in the correct order.
    // In the future, it may be useful to automatically resolve older messages (if there
    // are connectivity challenges we need to handle)
    const currentExpectedMessage = this._expectedMessages.shift();

    if (
      !Object.prototype.hasOwnProperty.call(event, 'messageId') ||
      currentExpectedMessage.id !== event.messageId
    ) {
      currentExpectedMessage.reject('mismatch on message expectation');
      return;
    }

    currentExpectedMessage.resolve();
  }

  _rejectExpectedMessages(reason) {
    this._expectedMessages.forEach((expectedMessage) => {
      expectedMessage.reject(new Error(reason));
    });
  }

  _initialiseSignals() {
    this.remoteConnectionReady = new Signal();
    this.messageAcknowledgmentDelayed = new Signal();
    this.delayedMessageAcknowledged = new Signal();
  }

  _releaseSignalHandlers() {
    this.remoteConnectionReady.removeAll();
    this.messageAcknowledgmentDelayed.removeAll();
    this.delayedMessageAcknowledged.removeAll();
  }

  _handleMessage(message) {
    if (message.targetId !== this._id) {
      return false;
    }

    const messageType = message.messageType;
    if (!this._messageHandlers || !this._messageHandlers[messageType]) {
      return false;
    }

    // call handlers
    this._messageHandlers[messageType].forEach((handler) => handler(message.data));

    return true;
  }

  _handleAcknowledgeableMessage(acknowledgeableMessage) {
    if (!this._handleMessage(acknowledgeableMessage)) {
      return;
    }

    // acknowledge message
    this._channel.broadcast(events.MESSAGE_ACK, { messageId: acknowledgeableMessage.messageId });
  }

  /**
   * Signal client connection unless the client id is our own
   *
   * @param clientId
   * @private
   */
  _handleClientConnected(data) {
    if (data.id === this._id) {
      return;
    }
    log(`client connected ${data.id}`);
    this.remoteConnectionReady.dispatch(data.id);
  }

  /**
   * Map expected channel events to handlers
   * @private
   */
  _initialiseChannelEventHandlers() {
    [
      [events.CLIENT_CONNECTED, '_handleClientConnected'],
      [events.MESSAGE_ACK, '_handleMessageAck'],
      [events.MESSAGE_TO_ACK, '_handleAcknowledgeableMessage'],
      [events.MESSAGE_TO_NOT_ACK, '_handleMessage'],
    ].forEach((cmdMap) => this._channel.waitFor(cmdMap[0], this[cmdMap[1]].bind(this)));

    this._messageHandlers = {};
  }
}

export const createConnectingMessageBroker = (commsChannelConfig) => {
  const messageBroker = new MessageBroker(commsChannelConfig);
  return messageBroker.connect().then((_) => messageBroker);
};
