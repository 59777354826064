const lang = {
  error: {
    app: {
      bad_state: {
        title: 'Oops',
        body: 'You cannot refresh or edit the address bar in your browser whilst using the application.',
      },
      history_navigation: {
        title: 'Oops',
        body: 'You cannot use the back and forward buttons in your browser whilst using the application.',
      },
    },
    message_broker: {
      error: {
        title: 'Error communicating',
        body: 'We could not connect to the control system',
      },
      delay: {
        title: 'Waiting for ECP connection',
        body: 'There is a delay communicating with the ECP system',
      },
    },
    get_user_media: {
      title: "We can't access your devices camera, microphone or speakers",
      body: 'The assessment cannot continue without them.',
    },
  },
  confirm: {
    abandon_assessment: {
      title: 'Are you sure you want to leave?',
      body: 'Your consultant will be notified and your assessment will end immediately.',
    },
    get_user_media: {
      title: 'Please check your devices camera, microphone or speakers',
      body: 'Please ensure they are not in use by another application and that you have granted this page permission to access them, then click below to try again.',
      cancel_label: 'Retry',
      okay_label: 'Abandon assessment',
    },
  },
};

export default lang;
