<template>
  <div v-if="showConfirm" class="pop-up pop-up-confirm" data-test="confirm-popup">
    <div class="pop-up-content">
      <div class="panel">
        <div class="panel-header">
          <h1>{{ confirmData.title }}</h1>
        </div>
        <div class="panel-content">
          <p>{{ confirmData.body }}</p>
        </div>
        <div class="btn-row space-above">
          <button class="item-left" data-test="confirm-popup-cancel" @click="confirmPopupCancel">
            {{ confirmData.cancelLabel ?? 'No, remain' }}
          </button>
          <button
            class="item-right btn-secondary"
            data-test="confirm-popup-okay"
            @click="confirmPopupOkay"
          >
            {{ confirmData.okayLabel ?? 'Yes, exit' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ConfirmPopup',
  computed: {
    ...mapState('patient', {
      confirmData: (state) => state.confirm,
    }),
    showConfirm() {
      return this.confirmData !== undefined;
    },
  },
  methods: {
    ...mapActions('patient', ['removeConfirmAction']),
    confirmPopupCancel() {
      this.confirmData.cancelCallback();
      this.removeConfirmAction();
    },
    confirmPopupOkay() {
      this.confirmData.okayCallback();
      this.removeConfirmAction();
    },
  },
};
</script>

<style scoped></style>
