import { roundAccurately } from '@/shared/utils';

export const rightLateralityId = 'r';
export const leftLateralityId = 'l';
export const binocularLateralityId = 'b';

export const lateralities = [
  {
    id: rightLateralityId,
    externalId: 'RIGHT',
    display: 'Right',
    slug: 'right',
  },
  {
    id: binocularLateralityId,
    externalId: 'BINOCULAR',
    display: 'Binocular',
    slug: 'binocular',
  },
  {
    id: leftLateralityId,
    externalId: 'LEFT',
    display: 'Left',
    slug: 'left',
  },
];

export const lateralityById = (id) => {
  return lateralities.filter((laterality) => laterality.id === id)[0];
};

export const sidedLateralityIds = [rightLateralityId, leftLateralityId];

export const testTypes = [
  {
    id: 'letter-sloan-letter',
    externalId: 'SLOAN-LETTER',
    generator: 'letter',
    optotype: 'sloan-letter',
    displayName: 'Sloan Letter',
  },
  {
    id: 'letter-landolt-c',
    externalId: 'LANDOLT-C',
    generator: 'letter',
    optotype: 'landolt-c',
    displayName: 'Landolt C',
  },
  {
    id: 'letter-auckland-picture',
    externalId: 'AUCKLAND-PICTURE',
    generator: 'letter',
    optotype: 'auckland-picture',
    displayName: 'Auckland Picture',
  },
  {
    id: 'word-word',
    externalId: 'WORD',
    generator: 'word',
    optotype: 'word',
    displayName: 'Word',
  },
];

export const testTypeById = (testTypeId) => {
  return testTypes.filter((testType) => testType.id === testTypeId)[0];
};

export const SYMBOL_SCORING = 'symbol-scoring';
export const LINE_SCORING = 'line-scoring';
export const scoringMethods = [
  {
    id: SYMBOL_SCORING,
    externalId: 'SINGLE',
    displayName: 'Single item',
  },
  {
    id: LINE_SCORING,
    externalId: 'LINE',
    displayName: 'Line by line',
  },
];

export const scoringMethodById = (scoringMethodId) => {
  return scoringMethods.filter((scoringMethod) => scoringMethod.id === scoringMethodId)[0];
};

export const measurementTypes = [
  {
    id: 'distance',
    externalId: 'DISTANCE',
    displayName: 'Distance',
    slug: 'distance',
    distance: 3000,
    displayDistance: '3m / 10ft',
    instructions: {
      auto: 'Instruct the patient to back away from their device ensuring their face remains visible. Tell them to stop when the distance box on the left signals the patient is at the correct distance (3m / 10ft). The patient will remain at that position for the entire distance measurement. <br/><br/>If the patient moves once the measurement has started, the distance tracking must be completed again. Please select stop measurement and then check distance.',
      manual:
        'In manual mode, instruct the patient to measure out 3m / 10ft from their device, marking the spot with an object. The patient will remain at that position for the entire distance measurement.',
    },
    maxLogMar: 0.8,
    minLogMar: -0.4,
    testTypes: [
      testTypeById('letter-sloan-letter'),
      testTypeById('letter-landolt-c'),
      testTypeById('letter-auckland-picture'),
    ],
    scoringMethods: scoringMethods,
    defaultTermination: 3,
    defaultCrowdingBox: false,
    defaultLateralityId: rightLateralityId,
    spacingScale: 0.5,
  },
  {
    id: 'near',
    externalId: 'NEAR',
    displayName: 'Near',
    slug: 'near',
    distance: 400,
    displayDistance: '0.40m / 40cm / 16in',
    instructions: {
      auto: 'Instruct the patient to move so they are comfortably positioned 0.40m / 40cm / 16in from their device.',
      manual:
        'In manual mode, instruct the patient to measure out 0.40m / 40cm / 16in from their device and ensure they are comfortably seated at that distance. The patient will remain at that position for the entire near measurement.',
    },
    maxLogMar: 0.8,
    minLogMar: 0.2,
    testTypes: [
      testTypeById('word-word'),
      testTypeById('letter-sloan-letter'),
      testTypeById('letter-landolt-c'),
      testTypeById('letter-auckland-picture'),
    ],
    scoringMethods: scoringMethods,
    defaultTermination: 3,
    defaultCrowdingBox: false,
    defaultLateralityId: rightLateralityId,
    spacingScale: 1,
  },
];

export const measurementTypeById = (measurementTypeId) => {
  return measurementTypes.filter((measurementType) => measurementType.id === measurementTypeId)[0];
};

export const correctionTypes = [
  {
    id: 'none',
    display: 'None',
    slug: 'none',
    externalId: 'NONE',
  },
  {
    id: 'glasses',
    display: 'Glasses',
    slug: 'glasses',
    externalId: 'GL',
  },
  {
    id: 'cl',
    display: 'Contact Lens',
    slug: 'contact-lens',
    externalId: 'CL',
  },
  {
    id: 'u-glasses',
    display: 'Updated Glasses',
    slug: 'updated-glasses',
    externalId: 'UP-GL',
  },
  {
    id: 'u-cl',
    display: 'Updated Contact Lens',
    slug: 'updated-contact-lens',
    externalId: 'UP-CL',
  },
];

export const correctionTypeById = (id) => {
  return correctionTypes.filter((correctionType) => correctionType.id === id)[0];
};

export const measurementUnits = [
  {
    id: 'lm',
    externalId: 'LOGMAR',
    displayName: 'LogMAR',
    legendLabel: 'LogMAR',
    isPrimary: true,
    fromLogMar: (value) => {
      return roundAccurately(value, 2).toFixed(2);
    },
  },
  {
    id: 'etdrs',
    externalId: 'ETDRS',
    displayName: 'ETDRS',
    legendLabel: 'ETDRS',
    isPrimary: true,
    fromLogMar: (value) => {
      return 85 - roundAccurately(value / 0.02, 2);
    },
  },
  {
    id: 'snlmt',
    externalId: 'METRIC',
    displayName: 'UK - 6m',
    legendLabel: 'UK - 6m',
    isPrimary: false,
    fromLogMar: (value) => {
      return '6/' + roundAccurately(Math.pow(10, value) * 6, 1);
    },
  },
  {
    id: 'snlft',
    externalId: 'IMPERIAL',
    displayName: 'US - 20ft',
    legendLabel: 'US - 20ft',
    isPrimary: false,
    fromLogMar: (value) => {
      return '20/' + roundAccurately(Math.pow(10, value) * 20, 1);
    },
  },
  {
    id: 'decimal',
    externalId: 'DECIMAL',
    displayName: 'EU - 0.0',
    legendLabel: 'EU - 0.0',
    isPrimary: false,
    fromLogMar: (value) => {
      return roundAccurately(1 / Math.pow(10, value), 3);
    },
  },
];

export const measurementUnitById = (id) => {
  return measurementUnits.filter((measurementUnit) => measurementUnit.id === id)[0];
};
