import { mapActions, mapGetters, mapState } from 'vuex';
import { routeSwitcher } from '@/shared/mixins/routeSwitcher';

/**
 * Watches store for changes in target route and triggers route change in router
 */
export const patientRouteSwitcher = {
  mixins: [routeSwitcher],
  computed: {
    ...mapState('patient', {
      targetRoute: (state) => state.targetRoute,
    }),
    ...mapGetters('patient', ['isLoggedIn']),
  },
  methods: {
    ...mapActions('patient', ['announceTerminationAction']),
  },
};
