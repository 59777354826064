const lang = {
  settings: {
    title: {
      edittingPre: 'Settings for ',
      edittingPost: '',
      summaryPre: '',
      summaryPost: ' vision',
    },
  },
  sidebar: {
    results: {
      title: {
        pre: '',
        post: ' vision results',
      },
    },
  },
  error: {
    app: {
      bad_state: {
        title: 'Bad state',
        body: 'Refreshing or editing the address bar can cause errors in your application.',
      },
      history_navigation: {
        title: 'Bad state',
        body: 'Using the back and forward buttons in your browser can cause errors in your application.',
      },
      login: {
        title: 'Error',
        body: 'We could not verify your request, please try again later.',
      },
      patient_info: {
        title: 'Error',
        body: 'We could not retrieve information for your patient. Please reload or try again later.',
      },
      user_info: {
        title: 'Error',
        body: 'We could not retrieve your information. Please reload or try again later.',
      },
    },
    message_broker: {
      // connection error
      error: {
        title: 'Error communicating',
        body: 'We could not connect to the control system',
      },
      delay: {
        title: 'Waiting for patient connection',
        body: 'There is a delay communicating with the patient application. Please hold on few more moments.',
      },
    },
    patient: {
      abandon_assessment: {
        title: 'The patient has left the session',
        body: 'Please contact the patient for further information.',
      },
    },
    get_user_media: {
      title: "We can't access your devices camera, microphone or speakers",
      body: 'The assessment cannot continue without them.',
    },
    validation: {
      email:
        'Please enter a valid email address. The invitation cannot be sent without a valid email address.',
      mobile:
        'Please enter a valid mobile number including your area code e.g. 447123456789. The invitation can still be sent without a valid mobile number.',
    },
  },
  confirm: {
    stop_measurement: {
      title: 'Are you sure?',
      body: 'Confirm you wish to stop this measurement and return to the main controls.',
    },
    stop_assessment: {
      title: 'Are you sure?',
      body: 'Confirm you wish to stop the assessment and end your session with your patient.',
      cancel_label: 'No, remain',
      okay_label: 'Yes, exit',
    },
    settings: {
      change_title: 'Are you sure?',
      currently_measuring:
        'Editing these settings now will restart the measurement and your results will be lost.',
      has_results: 'The current results will be removed and NOT saved.',
    },
    get_user_media: {
      title: 'Please check your devices camera, microphone or speakers',
      body: 'Please ensure they are not in use by another application and that you have granted this page permission to access them, then select okay to try again.',
    },
  },
  results: {
    not_recorded: {
      display: '-',
    },
    outsideMax: {
      display: 'Poor',
      footnote_indicator: '<sup>+</sup>',
      footnote: 'Patient acuity too poor to measure remotely.',
    },
    outsideMin: {
      footnote_indicator: '<sup>*</sup>',
      footnote: 'Patient acuity may be better than scored.',
    },
    key: {
      pre: 'Results shown in format: ',
      post: '',
    },
  },
};

export default lang;
