import BaseOptotype from '@/shared/classes/Optotype/BaseOptotype';

export default class LandoltC extends BaseOptotype {
  gapDirections = ['up', 'up-right', 'right', 'down-right', 'down', 'down-left', 'left', 'up-left'];

  transformForGapDirections = {
    up: 'transform: rotate(-90deg);',
    'up-right': 'transform: rotate(-45deg);',
    right: '',
    'down-right': 'transform: rotate(45deg);',
    down: 'transform: rotate(90deg);',
    'down-left': 'transform: rotate(135deg);',
    left: 'transform: rotate(180deg);',
    'up-left': 'transform: rotate(-135deg);',
  };

  iconStyle = 'width: 1em; height: 1em; vertical-align: middle; overflow: hidden;';

  controllerWrapperStyle = '--symbol-font-size:40px; --crowding-box-width:15px';

  get symbolOptions() {
    return this.gapDirections;
  }

  /**
   * Spacing scale is a ratio provided for tests at distance where the spacing
   * is reduced to allow larger symbols to be rendered in the available horizontal
   * space. Typically set to half for distance, and 1 otherwise.
   *
   * @param symbolId
   * @param spacingScale
   * @returns {{spacer: string, id, content: string}}
   */
  getSymbolDisplay(symbolId, spacingScale) {
    if (!this.symbolOptions.includes(symbolId)) {
      throw new Error(`Unrecognised ${symbolId}`);
    }

    if (spacingScale === undefined) {
      spacingScale = 1;
    }

    const spacerStyle = `width: ${spacingScale}em; height: 1em; vertical-align: middle; overflow: hidden;`;

    return {
      content:
        '<svg style="' +
        this.transformForGapDirections[symbolId] +
        ' ' +
        this.iconStyle +
        '"><use xlink:href="/svg/sloan-letters-2021.svg#C"></use></svg>',
      spacer: `<svg style="${spacerStyle}"><use xlink:href="/svg/sloan-letters-2021.svg#X"></use></svg>`,
      id: symbolId,
    };
  }
}
