export const states = {
  AWAITING_PATIENT: 'assessment-patient-await',
  PATIENT_CONNECTED: 'assessment-patient-connected',
  PATIENT_READY: 'assessment-patient-ready',
  IDENTIFY_PATIENT: 'assessment-identify-patient',
  PATIENT_ID_SUCCESS: 'assessment-patient-identified',
  PATIENT_ID_FAIL: 'assessment-patient-not-identified',
  COMPLETED: 'assessment-completed',
  PATIENT_LEFT_BEFORE_READY: 'assessment-patient-left-before-ready',
  PATIENT_LEFT: 'assessment-patient-left',
};
