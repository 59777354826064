/**
 * Handles various error scenarios
 *
 * If the app is refreshed, or the address bar is edited the page will load without the main App JS. This results in a
 * terminal error.
 *
 * If the user tries to navigate using the back or forward buttons the 'popstate' event fires. We can interept this and
 * try to recover the current page.
 *
 * @type {{beforeUnmount(): void, S: *, methods: {appErrorState(*): void, onPopState(*): void}, created(): void, mounted(): void}}
 */
export const appStatusCheck = {
  created() {
    if (this.appClass === undefined) {
      this.showStateError();
    }
  },
};
