import Pusher from 'pusher-js';

export default class PusherChannel {
  constructor(config) {
    this.pusher = new Pusher(config.settings.APP_KEY, config.settings.options);
    this.channelName = config.channelName;
    this.channel = undefined;
  }

  /**
   * Simple wrapper to subscribe to a private channel
   *
   * @returns {Channel}
   * @private
   */
  _createPusherChannel() {
    // the channel must always be prefixed, but the expectation is that this is what will have already
    // been defined from the configuration.
    const subscriptionChannelName = this.channelName.startsWith('private-')
      ? this.channelName
      : `private-${this.channelName}`;

    return this.pusher.subscribe(subscriptionChannelName);
  }

  /**
   * Abstraction of completing the subscription process (essentially handling the
   * event binding on the pusher subscription event of the channel)
   *
   * @param success
   * @param failure
   * @private
   */
  _completeSubscription(success, failure) {
    this.channel.bind(
      'pusher:subscription_succeeded',
      function () {
        success(this);
      }.bind(this),
    );
    this.pusher.connection.bind(
      'error',
      function (error) {
        this.channel = undefined;
        failure(error);
      }.bind(this),
    );
    this.channel.bind(
      'pusher:subscription_error',
      function (data) {
        this.channel = undefined;
        failure(new Error('unexpected error subscribing to pusher channel'));
      }.bind(this),
    );
  }

  /**
   *
   * @private
   */
  _createChannel() {
    this.channel = this._createPusherChannel();
  }

  /**
   * Carries out the subscription to the pusher channel,
   * resolving on a subscription success
   * rejecting on a subscription error
   *
   * @returns {Promise<any>}
   */
  subscribe() {
    return new Promise((resolve, reject) => {
      if (this.channel) {
        resolve(this);
      } else {
        this._createChannel();
        this._completeSubscription(resolve, reject);
      }
    });
  }

  disconnect() {
    if (this.channel) {
      this.pusher.disconnect();
      delete this.channel;
    }
  }

  /**
   * Send an event with the given data that resolves as soon as it is sent.
   *
   * N.B. event identifiers are prefixed with 'client-' to leverage Pusher client event
   * pattern: https://pusher.com/docs/channels/using_channels/events/#triggering-client-events
   *
   * @param event
   * @param data
   * @returns {Promise<any>}
   */
  broadcast(event, data) {
    if (data === undefined) {
      data = {};
    }

    return new Promise((resolve, reject) => {
      if (!this.channel) {
        reject(new Error('cannot broadcast on unsubscribed controller broadcastChannel'));
      }
      this.channel.trigger('client-' + event, data);
      resolve();
    });
  }

  /**
   * Will call the callback each time the given event occurs on the broadcastChannel
   *
   * 'client-' prefix reflects the behaviour of broadcast method above
   */
  waitFor(event, callback) {
    if (this.channel) {
      this.channel.bind('client-' + event, callback);
    }
  }
}
