import { sharedMutations } from '@/shared/store/mutations';

export const controllerMutations = Object.assign(
  {
    setPatientInfo(state, patientInfo) {
      if (patientInfo !== undefined) {
        patientInfo = { ...patientInfo };
        if (patientInfo.dob) {
          const dobUTC = new Date(patientInfo.dob);
          patientInfo.dob = new Date(
            dobUTC.getUTCFullYear(),
            dobUTC.getUTCMonth(),
            dobUTC.getUTCDate(),
          );
          const today = new Date();
          let age = today.getFullYear() - patientInfo.dob.getFullYear();
          const months = today.getMonth() - patientInfo.dob.getMonth();
          if (months < 0 || (months === 0 && today.getDate() < patientInfo.dob.getDate())) {
            age--;
          }
          patientInfo.age = age;
        }
        patientInfo.displayName = ['firstName', 'surname']
          .map((attr) => patientInfo[attr])
          .filter((value) => value && value.length !== 0)
          .join(' ');
      }
      state.patientInfo = patientInfo;
    },
    setUserInfo(state, userInfo) {
      if (userInfo !== undefined) {
        userInfo = { ...userInfo };
        if (!userInfo.displayName) {
          userInfo.displayName = ['jobTitle', 'firstName', 'surname']
            .map((attr) => userInfo[attr])
            .filter((value) => value && value.length !== 0)
            .join(' ');
        }
      }
      state.userInfo = userInfo;
    },
    setInvitationDetails(state, details) {
      if (details) {
        state.invitationDetails = {
          link: details.link,
          pin: details.pin,
        };
      } else {
        state.invitationDetails = undefined;
      }
    },
    setAssessmentController(state, assessmentController) {
      state.assessmentController = assessmentController;
    },
    setAssessmentWorkflowState(state, workflowState) {
      state.assessmentWorkflowState = workflowState;
    },
    setVideoController(state, videoController) {
      state.videoController = videoController;
    },
    setExaminationController(state, examinationController) {
      state.examinationController = examinationController;
    },
    setExaminationWorkflowState(state, examinationWorkflowState) {
      state.examinationWorkflowState = examinationWorkflowState;
    },
    setCalibrationController(state, calibrationController) {
      state.calibrationController = calibrationController;
    },
    setCalibrationWorkflowState(state, calibrationWorkflowState) {
      state.calibrationWorkflowState = calibrationWorkflowState;
    },
    setCalibrationData(state, calibrationData) {
      state.calibrationData = calibrationData;
    },
    setPatientDistanceTrackingController(state, patientDistanceTrackingController) {
      state.patientDistanceTrackingController = patientDistanceTrackingController;
    },
    setShowGlobalSettings(state, data) {
      state.showGlobalSettings = data;
    },
    setGlobalSettingsConfirmed(state, data) {
      state.globalSettingsConfirmed = data;
    },
    setSidebarResults(state, data) {
      state.sidebarResults = data;
    },
    setVideoCallStartTimestamp(state, data) {
      state.videoCallStartTimestamp = data;
    },
    setInitialApplicationQuery(state, data) {
      state.initialApplicationQuery = data;
    },
  },
  sharedMutations,
);
