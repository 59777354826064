<template>
  <aside class="global-settings">
    <span class="background" @click="closeGlobalSettings">&nbsp;</span>
    <div class="panel">
      <div class="panel-header">
        <h1 class="hidden">Settings</h1>
        <h2>Measurement units</h2>
      </div>
      <div class="panel-content">
        <span class="form-element hide-label">
          <span class="label">Measurement units</span>
          <div class="radio-group items-2" data-test="primary-measurement-unit-radios">
            <label
              v-for="measurementUnit in primaryMeasurementUnits"
              :key="measurementUnit.id"
              class="form-radio"
              :for="measurementUnit.id"
            >
              <input
                type="radio"
                v-model="primaryMeasurementUnitId"
                :id="measurementUnit.id"
                :value="measurementUnit.id"
              />
              <span>{{ measurementUnit.displayName }}</span>
            </label>
          </div>
        </span>
        <span class="form-element">
          <span class="label">Snellen</span>
          <div class="radio-group items-3" data-test="secondary-measurement-unit-radios">
            <label
              v-for="measurementUnit in secondaryMeasurementUnits"
              :key="measurementUnit.id"
              class="form-radio"
              :for="measurementUnit.id"
            >
              <input
                type="radio"
                v-model="secondaryMeasurementUnitId"
                :id="measurementUnit.id"
                :value="measurementUnit.id"
              />
              <span>{{ measurementUnit.displayName }}</span>
            </label>
          </div>
        </span>
      </div>
      <div class="panel-header">
        <h2>Audio</h2>
      </div>
      <div class="panel-content">
        <span class="form-element">
          <label class="label">Speaker</label>
          <div class="form-row grow-content items-1">
            <div class="form-select" v-if="audioOutputs">
              <select v-model="audioOutputDevice" data-test="audio-output-select">
                <option
                  v-for="audioOutput in audioOutputs"
                  :key="audioOutput.deviceId"
                  :value="audioOutput.deviceId"
                >
                  {{ audioOutput.label }}
                </option>
              </select>
            </div>
            <span v-else>Audio output must be set in device preferences.</span>
          </div>
        </span>
        <span class="form-element">
          <label class="label">Microphone</label>
          <div class="form-row grow-content items-1">
            <div class="form-select">
              <select v-model="audioInputDevice" data-test="audio-input-select">
                <option
                  v-for="audioInput in audioInputs"
                  :key="audioInput.deviceId"
                  :value="audioInput.deviceId"
                >
                  {{ audioInput.label }}
                </option>
              </select>
            </div>
          </div>
          <div id="audioMeter" class="audio-meter">
            <span class="audio-meter-level">&nbsp;</span>
          </div>
        </span>
      </div>
      <div class="panel-header">
        <h2>Video</h2>
      </div>
      <div class="panel-content">
        <span class="form-element">
          <label class="label">Camera</label>
          <div class="form-row grow-content items-1">
            <div class="form-select">
              <select v-model="videoInputDevice" data-test="video-input-select">
                <option
                  v-for="videoInput in videoInputs"
                  :key="videoInput.deviceId"
                  :value="videoInput.deviceId"
                >
                  {{ videoInput.label }}
                </option>
              </select>
            </div>
          </div>
        </span>
        <div class="video-preview">
          <video
            class="videoTrack mirror-video"
            :srcObject="videoSrc"
            v-if="videoSrc"
            autoPlay
            playsinline
            muted
          />
          <div class="no-feed" v-if="!videoSrc">No feed</div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { controllerLocalSettings } from '@/controller/mixins/localSettings';
import { getMediaDevices } from '@/shared/utils-video';
import { measurementUnitById, measurementUnits } from '@/controller/library';
import { mapActions } from 'vuex';
import lang from '@/controller/lang/en_GB';

export default {
  name: 'GlobalSettings',
  mixins: [controllerLocalSettings],
  data() {
    return {
      videoSrc: undefined,
      videoInputDevice: undefined,
      videoInputs: undefined,

      audioInputDevice: undefined,
      audioInputs: undefined,
      audioMeter: undefined,
      audioLevel: 0,
      audioLevelInterval: undefined,

      audioOutputDevice: undefined,
      audioOutputs: undefined,
      primaryMeasurementUnitId: undefined,
      secondaryMeasurementUnitId: undefined,
    };
  },
  computed: {
    primaryMeasurementUnits() {
      return measurementUnits.filter((measurementUnit) => measurementUnit.isPrimary);
    },
    secondaryMeasurementUnits() {
      return measurementUnits.filter((measurementUnit) => !measurementUnit.isPrimary);
    },
  },
  methods: {
    ...mapActions('controller', [
      'showConfirmAction',
      'addErrorAction',
      'setShowGlobalSettingsAction',
      'setGlobalSettingsConfirmedAction',
    ]),
    gotMediaDevices(devices, selectionProperty, selectedProperty, localSettingKey) {
      if (devices.length > 0) {
        this[selectionProperty] = devices;
        const initialDeviceId = this.getLocalSetting(localSettingKey) ?? devices[0].deviceId;
        this[selectedProperty] = devices.map((device) => device.deviceId).includes(initialDeviceId)
          ? initialDeviceId
          : devices[0].deviceId;
      }
    },
    updateAudioMeter() {
      this.audioMeter.querySelector('.audio-meter-level').style.width = this.audioLevel + '%';
    },
    setDefaultMeasurementUnits() {
      const primaryMeasurementUnit =
        measurementUnitById(this.getLocalSetting('primaryMeasurementUnitId')) ??
        this.primaryMeasurementUnits[0];
      this.primaryMeasurementUnitId = primaryMeasurementUnit.id;
      const secondaryMeasurementUnit =
        measurementUnitById(this.getLocalSetting('secondaryMeasurementUnitId')) ??
        this.secondaryMeasurementUnits[0];
      this.secondaryMeasurementUnitId = secondaryMeasurementUnit.id;
    },
    closeGlobalSettings() {
      this.setShowGlobalSettingsAction(false);
      this.setGlobalSettingsConfirmedAction();
    },
    initVideoMediaDevices() {
      // console.log('initVideoMediaDevices');
      return getMediaDevices(['videoinput']).then((devices) => {
        // console.log(['Got video devices', devices]);
        this.gotMediaDevices(devices, 'videoInputs', 'videoInputDevice', 'videoInputDeviceId');
      });
    },
    initAudioInputMediaDevices() {
      // console.log('initAudioInputMediaDevices');
      return getMediaDevices(['audioinput']).then((devices) => {
        // console.log(['Got audio input devices', devices]);
        this.gotMediaDevices(devices, 'audioInputs', 'audioInputDevice', 'audioInputDeviceId');
        this.audioMeter = document.getElementById('audioMeter');
        this.audioLevelInterval = setInterval(() => {
          this.updateAudioMeter();
        }, 50);
      });
    },
    initAudioOutputMediaDevices() {
      // console.log('initAudioOutputMediaDevices');
      return getMediaDevices(['audiooutput']).then((devices) => {
        this.gotMediaDevices(devices, 'audioOutputs', 'audioOutputDevice', 'audioOutputDeviceId');
      });
    },
    /**
     * Triggered from the media initialisation methods - show a message that when dismissed
     * will fire the given callback (which typically should be the calling method itself to try again)
     *
     * @param callback
     */
    onGetMediaDevicesError(callback) {
      this.showConfirmAction({
        title: lang.confirm.get_user_media.title,
        body: lang.confirm.get_user_media.body,
        cancelCallback: () => {
          this.addErrorAction({
            id: 'get-user-media',
            title: lang.error.get_user_media.title,
            body: lang.error.get_user_media.body,
            end: true,
          });
        },
        okayCallback: () => {
          callback();
        },
      });
    },
    async initAudioMeter(mediaStream) {
      const audioContext = new AudioContext();
      await audioContext.audioWorklet.addModule('/js/audioWorklets/volumeMeter.js');
      const source = audioContext.createMediaStreamSource(mediaStream);
      const node = new AudioWorkletNode(audioContext, 'volumeMeter');
      node.port.onmessage = (event) => {
        if (event.data.volume) {
          let currentLevel = Math.round(event.data.volume * 200);
          if (currentLevel > 100) {
            currentLevel = 100;
          }

          this.audioLevel = currentLevel;
        }
      };
      source.connect(node).connect(audioContext.destination);
    },
  },
  created() {
    this.setDefaultMeasurementUnits();
  },
  mounted() {
    const init = () => {
      this.initVideoMediaDevices()
        .then(this.initAudioInputMediaDevices)
        .then(this.initAudioOutputMediaDevices)
        .catch(() => {
          this.onGetMediaDevicesError(init);
        });
    };
    init();
  },
  beforeUnmount() {
    clearInterval(this.audioLevelInterval);
  },
  watch: {
    videoInputDevice: {
      async handler() {
        if (this.videoInputDevice) {
          this.videoSrc = await navigator.mediaDevices.getUserMedia({
            video: {
              deviceId: { exact: this.videoInputDevice },
            },
          });
          await this.setLocalSetting('videoInputDeviceId', this.videoInputDevice);
        }
      },
    },
    audioInputDevice: {
      async handler() {
        if (this.audioInputDevice) {
          await navigator.mediaDevices
            .getUserMedia({
              audio: {
                deviceId: { exact: this.audioInputDevice },
              },
            })
            .then(async (mediaStream) => {
              this.initAudioMeter(mediaStream).catch(() => {});
            })
            .then(() => {
              this.setLocalSetting('audioInputDeviceId', this.audioInputDevice);
            })
            .catch((e) => {
              throw new Error('Microphone connection error' + e.name);
            });
        }
      },
    },
    audioOutputDevice: {
      async handler() {
        if (this.audioOutputDevice) {
          await this.setLocalSetting('audioOutputDeviceId', this.audioOutputDevice);
        }
      },
    },
    primaryMeasurementUnitId: {
      async handler() {
        if (this.primaryMeasurementUnitId) {
          await this.setLocalSetting('primaryMeasurementUnitId', this.primaryMeasurementUnitId);
        }
      },
    },
    secondaryMeasurementUnitId: {
      async handler() {
        if (this.secondaryMeasurementUnitId) {
          await this.setLocalSetting('secondaryMeasurementUnitId', this.secondaryMeasurementUnitId);
        }
      },
    },
  },
};
</script>

<style scoped></style>
