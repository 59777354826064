import { mapActions, mapGetters, mapState } from 'vuex';
import { routeSwitcher } from '@/shared/mixins/routeSwitcher';

/**
 * Watches store for changes in target route and triggers route change in router
 */
export const controllerRouteSwitcher = {
  mixins: [routeSwitcher],
  computed: {
    ...mapState('controller', {
      targetRoute: (state) => state.targetRoute,
      initialApplicationQuery: (state) => state.initialApplicationQuery,
    }),
    ...mapGetters('controller', ['isLoggedIn']),
  },
  methods: {
    ...mapActions('controller', ['announceTerminationAction']),
    restartApplication() {
      this.$router
        .push({
          name: 'controller.start',
          query: this.initialApplicationQuery,
          meta: {
            isRestarting: true,
          },
        })
        .catch()
        .then(() => {
          if (window) {
            this.removeListeners();
            window.location.reload();
          }
        });
    },
  },
};
