import { states as assessmentWorkflowStates } from '@/controller/classes/AssessmentController/workflow';
import { states as examinationWorkflowStates } from '@/controller/classes/ExaminationController/workflow';
import { states as calibrationWorkflowStates } from '@/controller/classes/CalibrationController/workflow';
import { states as measureWorkflowStates } from '@/controller/classes/MeasureController/workflow';
import { correctionTypeById, lateralityById } from '@/controller/library';
import { combineRouteElements } from '@/shared/utils';

export function getAppRouteElements(assessmentController) {
  const routeElements = {
    [assessmentWorkflowStates.AWAITING_PATIENT]: { routeName: ['controller', 'start'] },
    [assessmentWorkflowStates.PATIENT_CONNECTED]: { routeName: ['controller', 'start'] },
    [assessmentWorkflowStates.PATIENT_READY]: { routeName: ['controller', 'start'] },
    [assessmentWorkflowStates.PATIENT_LEFT]: { routeName: ['controller', 'end'] },
    [assessmentWorkflowStates.PATIENT_ID_FAIL]: { routeName: ['controller', 'end'] },
    [assessmentWorkflowStates.COMPLETED]: { routeName: ['controller', 'end'] },
    // clear out the query string when we move into the main application process
    [assessmentWorkflowStates.IDENTIFY_PATIENT]: {
      routeName: ['controller', 'assessment', 'patient-ident'],
      query: {},
    },
    [assessmentWorkflowStates.PATIENT_ID_SUCCESS]: () => {
      return combineRouteElements(
        { routeName: ['controller', 'assessment'], params: {} },
        getExaminationControllerRouteElements(assessmentController.examinationController),
      );
    },
  }[assessmentController.workflowState];

  if (routeElements instanceof Function) {
    return routeElements();
  }
  return routeElements;
}

function getExaminationControllerRouteElements(examinationController) {
  if (examinationController === undefined) {
    return;
  }

  const routeElements = {
    [examinationWorkflowStates.INIT]: { routeName: ['examination', 'start'] },
    [examinationWorkflowStates.CALIBRATE]: () => {
      return combineRouteElements(
        { routeName: ['examination', 'calibrate'], params: {} },
        getCalibrationControllerRouteElements(examinationController.calibrationController),
      );
    },
    [examinationWorkflowStates.PERFORM]: () => {
      return combineRouteElements(
        { routeName: ['examination', 'measure'], params: {} },
        getMeasureControllerRouteElements(examinationController.measureController),
      );
    },
  }[examinationController.workflowState];

  if (routeElements instanceof Function) {
    return routeElements();
  }
  return routeElements;
}

function getCalibrationControllerRouteElements(calibrationController) {
  if (calibrationController === undefined) {
    return;
  }

  return (
    {
      [calibrationWorkflowStates.INIT]: { routeName: [], params: {} },
      [calibrationWorkflowStates.CALIBRATE_SCREEN_PIXEL_SIZE_CREDIT_CARD]: {
        routeName: [],
        params: { calibration_type: 'pixel-size-cc' },
      },
      [calibrationWorkflowStates.CALIBRATE_SCREEN_PIXEL_SIZE_H]: {
        routeName: [],
        params: { calibration_type: 'pixel-size-h' },
      },
    }[calibrationController.workflowState] ?? {}
  );
}

function getMeasureControllerRouteElements(measureController) {
  if (measureController === undefined) {
    return;
  }

  return (
    {
      [measureWorkflowStates.INIT]: {
        routeName: [],
        params: {
          examination_type:
            measureController.measurementType.slug ?? measureController.measurementType.id,
        },
      },
      [measureWorkflowStates.TEST]: {
        routeName: ['create'],
        params: {
          examination_type:
            measureController.measurementType.slug ?? measureController.measurementType.id,
          correction: measureController.currentTestSettings
            ? correctionTypeById(measureController.currentTestSettings.correctionTypeId).slug
            : undefined,
          laterality: measureController.currentTestSettings
            ? lateralityById(measureController.currentTestSettings.lateralityId).slug
            : undefined,
        },
      },
    }[measureController.workflowState] ?? {}
  );
}
