<template>
  <button
    @click="toggleMuted"
    class="btn-reverse mute-video form-group-item"
    :class="[{ 'btn-toggled': isMuted }]"
    data-test="btn-camera-mute"
    :disabled="!counterStartTimestamp"
  >
    <span class="sr-only">Hide video</span
    ><span class="icon"
      ><svg class="default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M13,21H5a5,5,0,0,1-5-5V8A5,5,0,0,1,5,3h8a5,5,0,0,1,5,5v8A5,5,0,0,1,13,21ZM5,5A3,3,0,0,0,2,8v8a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8a3,3,0,0,0-3-3ZM21.3,18.9a2.81,2.81,0,0,1-2.1-1l-2.5-3.1a3.49,3.49,0,0,1-.8-2.2V11.4a3.49,3.49,0,0,1,.8-2.2l2.5-3.1A2.7,2.7,0,0,1,24,7.8v8.4A2.69,2.69,0,0,1,21.3,18.9Zm0-11.8h0a.46.46,0,0,0-.5.3l-2.5,3.1a1.22,1.22,0,0,0-.3.9v1.2a1.4,1.4,0,0,0,.3.9l2.5,3.1a.55.55,0,0,0,.5.3.68.68,0,0,0,.7-.7V7.8a.55.55,0,0,0-.3-.5A.52.52,0,0,0,21.3,7.1Z"
        /></svg
      ><svg class="alt" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M7.8,5H13a3,3,0,0,1,3,3v2.6a2.2,2.2,0,0,0-.1.8v1.7l5.7,5.7a2.7,2.7,0,0,0,2.3-2.7V7.8a2.7,2.7,0,0,0-4.8-1.7L18,7.6A4.94,4.94,0,0,0,13,3H5.8Zm13,2.4a.46.46,0,0,1,.5-.3.52.52,0,0,1,.4.2.55.55,0,0,1,.3.5v8.4a.68.68,0,0,1-.7.7.55.55,0,0,1-.5-.3l-2.5-3.1a1.4,1.4,0,0,1-.3-.9V11.4a1.22,1.22,0,0,1,.3-.9Z"
        />
        <path
          d="M15.2,18A2.87,2.87,0,0,1,13,19H5a3,3,0,0,1-3-3V8A2.87,2.87,0,0,1,3,5.8L1.5,4.4A5.15,5.15,0,0,0,0,8v8a5,5,0,0,0,5,5h8a5.15,5.15,0,0,0,3.6-1.5Z"
        />
        <rect
          x="11"
          y="-3.4"
          width="2"
          height="30.8"
          transform="translate(-4.97 12) rotate(-45)"
        /></svg
    ></span>
  </button>
</template>

<script>
import { controllerLocalSettings } from '@/controller/mixins/localSettings';
import { mapState } from 'vuex';

export default {
  name: 'VideoCameraMute',
  mixins: [controllerLocalSettings],
  data() {
    return {
      settingKey: 'videoMuted',
    };
  },
  computed: {
    ...mapState('controller', {
      counterStartTimestamp: (state) => state.videoCallStartTimestamp,
    }),
    isMuted() {
      return this.getLocalSetting(this.settingKey);
    },
  },
  methods: {
    toggleMuted() {
      this.setLocalSetting(this.settingKey, !this.isMuted);
    },
  },
  mounted() {
    // console.log('Video mute button created');
    if (this.getLocalSetting(this.settingKey) === undefined) {
      // console.log('Is currently undefined');
      this.setLocalSetting(this.settingKey, false);
    }
  },
};
</script>

<style scoped></style>
