import DataService from '@/shared/data-service';
import lang from '@/shared/lang/en_GB';
import { keyChecker } from '@/shared/utils';
import HttpError from '@/shared/classes/Error/HttpError';

export const sharedActions = {
  createDataServiceAction({ commit, dispatch, state }) {
    if (state.dataService) {
      // only create once
      return;
    }
    const dataService = new DataService();

    dataService.serverError.add((error) => {
      const errorDefinition = {
        id: 'server-error',
        title: lang.error_message_server_error_title,
        body: lang.error_message_server_error_body,
        restart: true,
        dismissable: false,
      };
      if (error && error instanceof HttpError) {
        errorDefinition.title += ` - ${error.status}`;
        if (error.requestId) {
          errorDefinition.debugInfo = error.requestId;
        }
      }
      dispatch('addErrorAction', errorDefinition);
    });

    dataService.sessionError.add((error) => {
      const errorDefinition = {
        id: 'session-error',
        title: lang.error_message_session_expired_title,
        body: lang.error_message_session_expired_title,
        restart: true,
        dismissable: false,
      };
      if (error && error instanceof HttpError) {
        errorDefinition.title += ` - ${error.status}`;
        if (error.requestId) {
          errorDefinition.debugInfo = error.requestId;
        }
      }
      dispatch('addErrorAction', errorDefinition);
    });

    dataService.connectionError.add((requestId) => {
      dispatch('addErrorAction', {
        id: 'connection-error',
        title: lang.error_message_no_connection_title,
        body: lang.error_message_no_connection_body,
        debugInfo: requestId,
        restart: true,
        dismissable: false,
      });
    });

    commit('setDataService', dataService);
  },
  setApiAction({ commit }, api) {
    commit('setApi', api);
  },
  baseLogoutAction({ state }) {
    if (state.api && state.api.loggedIn) {
      return state.api.logout();
    }
    return Promise.resolve();
  },
  addErrorAction({ commit, state }, errorData) {
    if (!Object.prototype.hasOwnProperty.call(errorData, 'id')) {
      errorData = {
        id: 'invalid-error-object',
        title: 'Unexpected Error occurred',
        body: 'Stack trace below, please copy and send to <a target="blank" href="mailto:VA-support@sparca.com?subject=VA Unexpected Error occurred&body=Please paste the error text here">VA-support@sparca.com</a>',
        copyData: `Location: ${window.location.href}\n${
          Error().stack
        }\nOriginal Error object:\n${errorData}`,
      };
    }
    const errors = state.errors;
    errors.push(errorData);
    commit('setErrors', errors);
  },
  removeErrorAction({ commit, state }, errorId) {
    const errors = state.errors;
    if (errors.find((error) => error.id === errorId)) {
      const newErrors = errors.filter((error) => error.id !== errorId);
      commit('setErrors', newErrors);
    }
  },
  removeAllErrorsAction({ commit }) {
    commit('setErrors', []);
  },
  showConfirmAction({ commit }, confirmData) {
    commit('setConfirmData', confirmData);
  },
  removeConfirmAction({ commit }) {
    commit('setConfirmData', undefined);
  },
  initLocalSettingsAction({ commit }, data) {
    commit('setLocalSettings', data);
  },
  setLocalSettingPropertyAction({ commit, state }, data) {
    if (!keyChecker(data, ['property', 'value'])) {
      throw new Error("Required keys missing. Requires 'property' and 'value'");
    }

    let settings = state.localSettings;
    if (settings === undefined) {
      settings = {};
    }
    settings[data.property] = data.value;

    commit('setLocalSettings', settings);
  },
};
