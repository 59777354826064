export const sharedMutations = {
  setDataService(state, dataService) {
    state.dataService = dataService;
  },
  setApi(state, api) {
    state.api = api;
  },
  setMessageBroker(state, messageBroker) {
    state.messageBroker = messageBroker;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setConfirmData(state, confirm) {
    state.confirm = confirm;
  },
  setTargetRoute(state, route) {
    state.targetRoute = route;
  },
  setVideoCallSettings(state, data) {
    state.videoCallSettings = data;
  },
  setLocalSettings(state, data) {
    state.localSettings = data;
  },
};
