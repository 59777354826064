import BaseOptotype from '@/shared/classes/Optotype/BaseOptotype';

export default class AucklandPicture extends BaseOptotype {
  letterSet = [
    'butterfly',
    'car',
    'duck',
    'flower',
    'heart',
    'house',
    'moon',
    'rabbit',
    'rocket',
    'tree',
  ];
  iconStyle = 'width: 1em; height: 1em; vertical-align: middle; overflow: hidden;';

  fontScale = 1.646;
  controllerWrapperStyle = '--symbol-font-size:40px; --crowding-box-width:15px';

  get symbolOptions() {
    return this.letterSet;
  }

  getSymbolDisplay(symbolId, spacingScale) {
    if (!this.letterSet.includes(symbolId)) {
      throw new Error(`Unrecognised ${symbolId}`);
    }

    if (spacingScale === undefined) {
      spacingScale = 1;
    }

    const spacerStyle = `width: ${spacingScale}em; height: 1em; vertical-align: middle; overflow: hidden;`;

    return {
      content:
        '<svg style="' +
        this.iconStyle +
        '"><use xlink:href="/svg/auckland-pictures.svg#' +
        symbolId +
        '"></use></svg>',
      spacer: `<svg style="${spacerStyle}"><use xlink:href="/svg/auckland-pictures.svg#butterfly"></use></svg>`,
      id: symbolId,
    };
  }
}
