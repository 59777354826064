/**
 * Provides shared video settings methods to support local storage
 * caching and retrieval.
 *
 * Must be used in conjunction with module specific mixin
 * that maps:
 *      state property of localSettings
 *      state action of initLocalSettingsAction
 */
export const localSettings = {
  data() {
    return {
      unstoredProperties: {
        audioMuted: {
          default: false,
        },
        videoMuted: {
          default: false,
        },
      },
    };
  },
  methods: {
    async setLocalSetting(property, value) {
      if (property && value !== undefined) {
        await this.setLocalSettingPropertyAction({
          property: property,
          value: value,
        });
        // console.log(['Checking for set Local setting: ', this.unstoredProperties, property, Object.prototype.hasOwnProperty.call(this.unstoredProperties, property)]);
        if (!Object.prototype.hasOwnProperty.call(this.unstoredProperties, property)) {
          // console.log(['go for local storage', this.localStorageKey, JSON.stringify(this.localSettings)]);
          window.localStorage.setItem(this.localStorageKey, JSON.stringify(this.localSettings));
        }
      }
    },
    getLocalSetting(property) {
      if (
        !this.localSettings ||
        !Object.prototype.hasOwnProperty.call(this.localSettings, property)
      ) {
        if (Object.prototype.hasOwnProperty.call(this.unstoredProperties, property)) {
          return this.unstoredProperties[property].default;
        }
        return undefined;
      }

      return this.localSettings[property];
    },
  },
  created() {
    if (this.localSettings === undefined) {
      let initialSettings = {};
      for (const [key] of Object.entries(this.unstoredProperties)) {
        initialSettings[key] = this.unstoredProperties[key].default;
      }
      // console.log(['Created localSettings key', this.localStorageKey, window.localStorage.getItem(this.localStorageKey)]);
      if (
        window.localStorage.getItem(this.localStorageKey) &&
        window.localStorage.getItem(this.localStorageKey) !== 'undefined'
      ) {
        const savedSettings = JSON.parse(window.localStorage.getItem(this.localStorageKey));
        initialSettings = { ...savedSettings, ...initialSettings };
      }
      this.initLocalSettingsAction(initialSettings);
    }
  },
};
