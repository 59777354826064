export const getConfig = (key, fallback) => {
  return parseVariable(process.env[key], fallback);
};

const parseVariable = (value, fallback) => {
  if (typeof value === 'undefined') {
    return fallback;
  }

  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value);

    case 'number':
      return JSON.parse(value);

    default:
      return value;
  }
};

export default { getConfig };
