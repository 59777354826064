/* istanbul ignore file */
import ControllerWrapper from '@/controller/views/Index';
import store from '@/shared/store';

const requireAuth = (to, from, next) => {
  if (store.getters['controller/isLoggedIn']) {
    next();
  } else {
    next({
      name: 'controller.start',
      query: to.query,
    });
  }
};

const routes = [
  {
    path: '/c',
    name: 'controller.home',
    redirect: (to) => {
      return {
        name: 'controller.start',
        query: to.query,
      };
    },
    component: ControllerWrapper,
    meta: {
      metaTitle: 'Home',
    },
    children: [
      {
        path: 'start',
        name: 'controller.start',
        component: () => import(/* webpackChunkName: "controller" */ '../views/Start.vue'),
        meta: {
          layoutClass: 'grid-left-main',
          metaTitle: 'Start',
        },
      },
      {
        path: 'assessment',
        name: 'controller.assessment',
        component: () =>
          import(/* webpackChunkName: "controller" */ '../views/AssessmentContainer.vue'),
        meta: {
          layoutClass: 'grid-right-main',
          metaTitle: 'Assessment',
        },
        beforeEnter: requireAuth,
        children: [
          {
            path: 'patient-ident',
            name: 'controller.assessment.patient-ident',
            component: () => import(/* webpackChunkName: "controller" */ '../views/Identify'),
            meta: {
              layoutClass: 'grid-right-main',
              metaTitle: 'Identify patient',
            },
          },
          {
            path: 'calibrate/:calibration_type?',
            name: 'controller.assessment.examination.calibrate',
            component: () => import(/* webpackChunkName: "controller" */ '../views/Examination'),
            meta: {
              layoutClass: 'grid-right-main',
              metaTitle: 'Calibrate',
            },
          },
          {
            path: 'manage',
            name: 'controller.assessment.examination.start',
            component: () => import(/* webpackChunkName: "controller" */ '../views/Examination'),
            meta: {
              layoutClass: 'grid-right-main',
              metaTitle: 'Controls',
            },
          },
          {
            path: ':examination_type',
            name: 'controller.assessment.examination.measure',
            component: () => import(/* webpackChunkName: "controller" */ '../views/Examination'),
            meta: {
              layoutClass: 'grid-right-main',
              metaTitle: 'Measure',
            },
          },
          {
            path: ':examination_type/:laterality/:correction',
            name: 'controller.assessment.examination.measure.create',
            component: () => import(/* webpackChunkName: "controller" */ '../views/Examination'),
            meta: {
              layoutClass: 'grid-right-main',
              metaTitle: 'Measure',
            },
          },
          {
            path: 'end',
            name: 'controller.end',
            component: () => import(/* webpackChunkName: "controller" */ '../views/End.vue'),
            meta: {
              layoutClass: 'grid-right-main',
              metaTitle: 'End',
              hideExitBtn: true,
            },
          },
        ],
      },
      {
        path: 'error',
        name: 'controller.error',
        component: () => import(/* webpackChunkName: "controllerError" */ '../views/Error.vue'),
        meta: {
          layoutClass: 'grid-single',
          metaTitle: 'Error',
          hideExitBtn: true,
        },
      },
      {
        path: 'exit',
        name: 'controller.exit',
        component: () => import(/* webpackChunkName: "controllerExit" */ '../views/Exit.vue'),
        meta: {
          layoutClass: 'grid-single',
          metaTitle: 'Exit',
          hideExitBtn: true,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'controller.404',
    component: () => import(/* webpackChunkName: "controller404" */ '../views/404.vue'),
    meta: {
      metaTitle: 'Page not found',
      hideExitBtn: true,
    },
  },
];

export default routes;
