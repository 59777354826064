import { createRouter, createWebHistory } from 'vue-router';
import ControllerRoutes from '@/controller/router';
import PatientRoutes from '@/patient/router';
import lang from '@/shared/lang/en_GB';

let allRoutes = [];
allRoutes = allRoutes.concat(ControllerRoutes, PatientRoutes);

// This setting to new const step nesc otherwise router can't loop through routes. Reason unclear.
const routes = allRoutes;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/**
 * Update document title with relevant page title
 */
router.beforeEach((to, from, next) => {
  // If a route with a title was found, set the document (page) title to that value.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTitle);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.metaTitle + lang.page_title_suffix;
  next(); // make sure to always call next()!
});

export default router;
