import { keyChecker, log, generateCommsDefinitionForPusher, roundAccurately } from '@/shared/utils';
import {
  correctionTypeById,
  lateralityById,
  measurementTypeById,
  measurementUnitById,
  scoringMethodById,
} from '@/controller/library';
import BaseApi from '@/shared/api';
import { getConfig } from '@/shared/config';
import HttpError from '@/shared/classes/Error/HttpError';

export default class ControllerApi extends BaseApi {
  constructor(dataService) {
    super(dataService, '/c/auth/refresh');
  }

  login(token, patientId, userId) {
    let authEndpoint = '/c/auth';
    const postData = {
      token: token,
    };
    if (patientId && userId) {
      postData.patientId = patientId;
      postData.userId = userId;
      if (getConfig('VUE_APP_ALLOW_MANUAL_LOGIN', false)) {
        authEndpoint = '/c/authTest';
      }
    }

    return this.dataService
      .silenceSessionErrorHandling()
      .setRequestId('login')
      .postToAPI(authEndpoint, postData)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          this.setBearer(response.data.bearer);
          this.loggedIn = true;
          return true;
        }
        this.loggedIn = false;
        throw new Error('login response status not successful');
      })
      .catch((error) => {
        log(['login error', error]);
        this.loggedIn = false;
        if (error.response) {
          return Promise.reject(error.response);
        }

        return Promise.reject(error);
      });
  }

  logout() {
    return this.dataService
      .deleteFromAPI('/c/invitation')
      .catch(() => {}) // failing to invalidate the invitation is not an error state
      .then(() => {
        return super.logout();
      });
  }

  getPatientInfo() {
    return this.dataService
      .setRequestId('patient-info')
      .getFromAPI('/c/aos/patient-info')
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error('unexpected success response for patient info retrieval from API.');
      });
  }

  getUserInfo() {
    return this.dataService
      .setRequestId('user-info')
      .getFromAPI('/c/aos/user-info')
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error('unexpected success response for user info retrieval from API.');
      });
  }

  /**
   * Get the comms channel definition to use for async communication
   *
   * @returns {{}}
   */
  getCommsDefinition() {
    if (!this.loggedIn) {
      throw new Error('not logged in');
    }

    if (!this.sessionData || !this.sessionData.msgChannelName) {
      throw new Error('No message broker channel name available for comms definition.');
    }

    return generateCommsDefinitionForPusher(this._bearerToken, this.sessionData.msgChannelName);
  }

  async initialiseNewAssessment() {
    const response = await this.dataService
      .setRequestId('invite-generate')
      .postToAPI('/c/invitation/generate');

    // need token and pin for a validation invitation
    if (!keyChecker(response.data, ['token', 'pin'])) {
      throw new Error('Missing required keys');
    }

    let baseInviteUrl = getConfig('VUE_APP_PATIENT_LOGIN');
    if (!baseInviteUrl) {
      baseInviteUrl = (window ? window.location.origin : '') + '/p';
    }

    return {
      invitationLink: baseInviteUrl + `/${response.data.token}`,
      invitationPin: response.data.pin,
    };
  }

  sendInvitationEmail(email, name, url, pin) {
    return this.dataService
      .silenceSessionErrorHandling()
      .setRequestId('invite-email')
      .postToAPI('/c/invitation/send-email', {
        email: email,
        name: name,
        url: url,
        pin: pin,
      })
      .catch((error) => {
        if (error.response && this.dataService.isSessionError(error.response.status)) {
          throw new HttpError(error, error.response.status, 'invitation-email');
        }
        throw error;
      });
  }

  sendInvitationSms(mobileNumber, name, url, pin) {
    return this.dataService
      .silenceSessionErrorHandling()
      .setRequestId('invite-mobile')
      .postToAPI('/c/invitation/send-sms', {
        mobile: mobileNumber,
        name: name,
        url: url,
        pin: pin,
      })
      .catch((error) => {
        if (error.response && this.dataService.isSessionError(error.response.status)) {
          throw new HttpError(error, error.response.status, 'invitation-sms');
        }
        throw error;
      });
  }

  async getVideoCallInformation() {
    const response = await this.dataService
      .setRequestId('video-info')
      .postToAPI('/auth/video-call/');

    if (!keyChecker(response.data, ['type', 'settings'])) {
      throw new Error('Missing required keys');
    }

    return {
      type: response.data.type,
      settings: response.data.settings,
    };
  }

  formatAppResultsForApi(measurementSettings, results) {
    return {
      type: measurementTypeById(measurementSettings.measurementTypeId).externalId,
      distance: measurementSettings.distance,
      note: results.notes ?? '',
      startedAt: results.startTime ? results.startTime.toISOString() : '',
      endedAt: results.endTime ? results.endTime.toISOString() : '',
      measurementUnitPrimary: measurementUnitById(measurementSettings.measurementUnitPrimaryId)
        .externalId,
      measurementUnitSecondary: measurementUnitById(measurementSettings.measurementUnitSecondaryId)
        .externalId,
      settings: {
        optotype: measurementSettings.testType.externalId,
        terminationCriteria: measurementSettings.terminationCriteria,
        lineOf: measurementSettings.lineLength ?? 5,
        scoringMethod: scoringMethodById(measurementSettings.scoringMethodId).externalId,
        crowdingBox: measurementSettings.crowdingBox,
        maxLogmar: measurementSettings.maxLogMar,
        minLogmar: measurementSettings.minLogMar,
      },
      measurements: results.measurements.map((measurement) => {
        return {
          correction: correctionTypeById(measurement.correctionTypeId).externalId,
          laterality: lateralityById(measurement.lateralityId).externalId,
          logmar: measurement.outsideMax ? '' : roundAccurately(measurement.logMar, 2), // not recorded if outside max
          outsideMax: measurement.outsideMax,
          outsideMin: measurement.outsideMin,
          scorePrimary: measurement.scorePrimary,
          scoreSecondary: measurement.scoreSecondary,
          lines: measurement.lines.map((line) => {
            return {
              logmar: roundAccurately(line.logMar, 2),
              symbols: line.measurementLineSymbols.map((measurementSymbol) => {
                return {
                  symbol: measurementSymbol.symbol,
                  success: measurementSymbol.success,
                };
              }),
            };
          }),
        };
      }),
    };
  }

  async saveResults(formattedResults) {
    const response = await this.dataService
      .setRequestId('save-results')
      .postToAPI('/c/patient-examinations', formattedResults);

    if (response.status === 201) {
      return true;
    }

    throw new Error('Unhandled problem with saving');
  }
}
