import { events as messageBrokerMessages } from '@/shared/classes/PatientDistanceTracking/events';

export default class PatientDistanceTrackingController {
  constructor(messageBroker, targetId) {
    this._messageBroker = messageBroker;
    this._targetId = targetId;
    this.trackingMode = PatientDistanceTrackingController.AUTO_MODE;
    this._targetTrackingDistance = undefined;
    this.isCalibrated = false;
    this.autoTrackingActive = false;
    this.currentDistance = undefined;
    this.haveTrackedForCurrentTarget = false;
  }

  static get MANUAL_MODE() {
    return 'manual';
  }

  static get AUTO_MODE() {
    return 'auto';
  }

  initialise() {
    if (this._initialised) {
      return;
    }
    this._initialiseMessageBrokerHandlers();
    this._initialised = true;
  }

  get targetTrackingDistance() {
    return this._targetTrackingDistance;
  }

  set targetTrackingDistance(value) {
    if (value === this._targetTrackingDistance) {
      return;
    }
    this._targetTrackingDistance = value;
    this.haveTrackedForCurrentTarget = false;
  }

  isManual() {
    return this.trackingMode === PatientDistanceTrackingController.MANUAL_MODE;
  }

  isAuto() {
    return this.trackingMode === PatientDistanceTrackingController.AUTO_MODE;
  }

  enableManualMode() {
    this.trackingMode = PatientDistanceTrackingController.MANUAL_MODE;
  }

  enableAutoMode() {
    this.trackingMode = PatientDistanceTrackingController.AUTO_MODE;
  }

  trackingLost() {
    return this.isAuto() && this.autoTrackingActive && this.currentDistance === 0;
  }

  initPatientDistanceTracking(data) {
    this.enableAutoMode();
    return this._messageBroker
      .sendAcknowledgedMessage(messageBrokerMessages.CALIBRATE, this._targetId, data)
      .then(() => {
        this.autoTrackingActive = true;
      });
  }

  resetPatientDistanceTracking() {
    return this._messageBroker
      .sendAcknowledgedMessage(messageBrokerMessages.RESET, this._targetId)
      .then(() => {
        this.autoTrackingActive = false;
        this.isCalibrated = false;
      });
  }

  pause() {
    if (!this.isAuto()) {
      return Promise.resolve();
    }

    this.autoTrackingActive = false;
    if (this.targetTrackingDistance) {
      this.haveTrackedForCurrentTarget = true;
    }
    return this._messageBroker.sendAcknowledgedMessage(messageBrokerMessages.PAUSE, this._targetId);
  }

  unpause() {
    if (!this.isAuto()) {
      return Promise.resolve();
    }

    this.autoTrackingActive = true;
    return this._messageBroker.sendAcknowledgedMessage(
      messageBrokerMessages.UNPAUSE,
      this._targetId,
    );
  }

  release() {
    // no action required at the moment
  }

  _handleDistanceMeasured(data) {
    if (this.autoTrackingActive) {
      // once we get our first distance value, we assume we are calibrated
      if (data.distance && data.distance > 0) {
        this.isCalibrated = true;
      }
      this.currentDistance = data.distance;
    }
  }

  _initialiseMessageBrokerHandlers() {
    [[messageBrokerMessages.DISTANCE_MEASURED, '_handleDistanceMeasured']].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );
  }
}
