/**
 * Watches store for changes in target route and triggers route change in router
 */
export const routeSwitcher = {
  methods: {
    beforeUnloadListener(event) {
      if (this.isLoggedIn) {
        event.preventDefault();
        event.returnValue = 'Leaving the page will break this assessment.';
      }
    },
    pageHideListener() {
      if (this.announceTerminationAction) {
        this.announceTerminationAction();
      }
    },
    removeListeners() {
      if (window) {
        window.removeEventListener('pagehide', this.pageHideListener);
        window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
      }
    },
  },
  watch: {
    targetRoute: {
      handler() {
        if (this.targetRoute === 'restart' && this.restartApplication !== undefined) {
          this.restartApplication();
          return;
        }
        if (!this.targetRoute.routeName) {
          console.error(['invalid target route object', this.targetRoute]);
        }
        this.$router
          .push({
            name: Array.isArray(this.targetRoute.routeName)
              ? this.targetRoute.routeName.filter((n) => n).join('.')
              : this.targetRoute.routeName,
            params: this.targetRoute.params,
            query: this.targetRoute.query ?? this.$route.query,
          })
          .then((_) => {});
      },
    },
  },
  mounted() {
    if (window) {
      window.addEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
      window.addEventListener('pagehide', this.pageHideListener);
    }
  },
  beforeUnmount() {
    this.removeListeners();
  },
};
