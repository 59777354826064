/* istanbul ignore file */
import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import App from './App.vue';
import router from './shared/router';
import store from './shared/store';
import { getConfig } from './shared/config';

import('./shared/assets/main.scss');

const app = createApp(App).use(store).use(router);

const gtmPropertyId = getConfig('VUE_APP_GTM_PROPERTY_ID');

if (gtmPropertyId) {
  const debugGTM = getConfig('VUE_APP_GTM_DEBUG', false);

  app.use(
    createGtm({
      id: gtmPropertyId, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      debug: debugGTM, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    }),
  );
}
app.mount('#app');
