import { createConnectingMessageBroker } from '@/shared/classes/MessageBroker';
import { sharedActions } from '@/shared/store/actions';
import { getAppRouteElements } from '@/patient/router/appStateRoutes';
import AssessmentClient from '@/patient/classes/AssessmentClient';
import PatientApi from '@/patient/api';
import { states as assessmentDisplayStates } from '@/patient/classes/AssessmentClient/displayStates';
import lang from '@/patient/lang/en_GB';
import { FaceDistance } from '@/packages/face-distance-tracking-js/src/js/face-distance';
import { debounce } from '@/shared/utils';

export const patientActions = Object.assign(
  {
    async loginPatientAction({ dispatch, state }, credentials) {
      await dispatch('createDataServiceAction');
      await dispatch('setApiAction', new PatientApi(state.dataService));

      const initialisationData = await state.api.login(
        credentials.invitationId,
        credentials.invitationPin,
      );

      return createConnectingMessageBroker(initialisationData.commsDefinition)
        .then((broker) => {
          dispatch('setAssessmentClientAction', new AssessmentClient(broker));
          return true; // indicate success
        })
        .catch(() => {
          dispatch('addErrorAction', {
            id: 'connection-error',
            title: lang.error.message_broker.error.title,
            body: lang.error.message_broker.error.body,
            dismissable: false,
          });
        });
    },
    setAssessmentClientAction({ commit, dispatch, state }, assessmentClient) {
      commit('setAssessmentClient', assessmentClient);

      assessmentClient.waitForControllerReady().then(() => {
        dispatch('initialiseExaminationClientAction');
      });

      assessmentClient.appStateUpdate.add(async () => {
        await dispatch('checkRouteByAppStateAction');
        dispatch('checkForLogoutByAppStateAction');
      });
      assessmentClient.newError.add((errorData) => {
        dispatch('addErrorAction', errorData);
      });
      assessmentClient.clearError.add((errorId) => {
        dispatch('removeErrorAction', errorId);
      });
    },
    /**
     * Will set up the necessary components for patient distance tracking, loading the model
     * so that it is ready to receive a video stream from the video call with the controller.
     *
     * @param commit
     * @param state
     * @returns {Promise<void>}
     */
    async initialisePatientDistanceTrackingAction({ commit, dispatch, state }) {
      commit('setPatientDistanceTrackerReady', false);

      await dispatch(
        'setPatientDistanceTrackingClientAction',
        state.assessmentClient.patientDistanceTrackingClient,
      );

      commit(
        'setPatientDistanceTracker',
        new FaceDistance({
          // localModels: false,
          // // TEST
          // videoElem: true,
          // canvasElem: true,
          distanceCallback: (obj) => {
            state.patientDistanceTrackingClient.updateDistance(obj.distance);
          },
        }),
      );

      // do all the heavy lifting, and then pause the tracking until we need it
      await state.patientDistanceTracker.loadModel();
      commit('setPatientDistanceTrackerModelReady', true);
    },
    setPatientDistanceTrackingClientAction(
      { commit, dispatch, state },
      patientDistanceTrackingClient,
    ) {
      commit('setPatientDistanceTrackingClient', patientDistanceTrackingClient);

      patientDistanceTrackingClient.initialise();

      patientDistanceTrackingClient.setCalibrationCallback((height, gender) => {
        dispatch('calibratePatientDistanceTrackerAction', [height, gender]);
      });

      patientDistanceTrackingClient.setResetCallback(() => {
        dispatch('resetPatientDistanceTrackerAction');
      });

      patientDistanceTrackingClient.setActiveCallback((active) => {
        if (active) {
          dispatch('startPatientDistanceTrackingAction');
        } else {
          dispatch('pausePatientDistanceTrackingAction');
        }
      });
    },
    /**
     *
     * @param state
     * @param height
     * @param gender
     */
    async calibratePatientDistanceTrackerAction({ commit, state }, [height, gender]) {
      if (!state.patientDistanceTrackerReady) {
        throw new Error('Face Distance Tracker not ready');
      }

      // reset call in case we've already calibrated previously
      state.patientDistanceTracker.reset();
      if (height === undefined) {
        height = 182;
      }
      if (gender === undefined) {
        gender = 'MALE';
      }
      state.patientDistanceTracker.height = height;
      state.patientDistanceTracker.gender = gender;
      state.patientDistanceTracker.calibrate();
    },
    resetPatientDistanceTrackerAction({ state }) {
      if (!state.patientDistanceTrackerReady) {
        throw new Error('Face Distance Tracker not ready');
      }
      state.patientDistanceTracker.reset();
    },
    pausePatientDistanceTrackingAction({ state }) {
      if (state.patientDistanceTrackerReady) {
        state.patientDistanceTracker.pause();
      }
    },
    startPatientDistanceTrackingAction({ state }) {
      if (state.patientDistanceTrackerReady) {
        state.patientDistanceTracker.unpause();
      }
    },
    initialiseVideoClientAction({ commit, state }) {
      if (!state.assessmentClient) {
        console.error('cannot initialise video when patient controller not set.');
        return;
      }

      commit('setVideoClient', state.assessmentClient.createVideoClient());
    },
    initialiseExaminationClientAction({ dispatch, state }) {
      if (!state.assessmentClient) {
        console.error('cannot initialise measurement client when assessment controller not set.');
        return;
      }
      dispatch('setExaminationClientAction', state.assessmentClient.createExaminationClient());
    },
    setExaminationClientAction({ commit, dispatch }, examinationClient) {
      commit('setExaminationClient', examinationClient);

      dispatch('setExaminationDisplayStateAction', examinationClient.examinationDisplayState);

      examinationClient.examinationDisplayStateChanged.add((displayState) => {
        dispatch('setExaminationDisplayStateAction', displayState);
      });
    },
    setExaminationDisplayStateAction({ commit }, examinationDisplayState) {
      commit('setExaminationDisplayState', examinationDisplayState);
    },

    initialiseCalibrationDisplayStateAction({ dispatch }, calibrationClient) {
      calibrationClient.calibrationDisplayStateChanged.add((displayState) => {
        dispatch('setCalibrationDisplayStateAction', displayState);
      });
    },
    setCalibrationDisplayStateAction({ commit }, state) {
      commit('setCalibrationDisplayState', state);
    },
    checkRouteByAppStateAction({ commit, state }) {
      const newRoute = getAppRouteElements(state.assessmentClient);

      if (
        Array.isArray(newRoute.routeName) &&
        newRoute.routeName.length > 0 &&
        newRoute.routeName !== undefined
      ) {
        commit('setTargetRoute', newRoute);
      }
    },
    checkForLogoutByAppStateAction({ dispatch, state }) {
      if (
        state.assessmentClient.assessmentDisplayState ===
        assessmentDisplayStates.ASSESSMENT_FINISHED
      ) {
        dispatch('logoutAction');
      }
    },
    logoutAction({ dispatch }) {
      dispatch('baseLogoutAction');
    },
    announceTerminationAction({ state }) {
      if (state.assessmentClient) {
        state.assessmentClient.abandonAssessment();
      }
    },
    setVideoParticipantTracksAction({ commit, dispatch }, participants) {
      commit('setVideoParticipantTracks', participants);
      dispatch('setPatientDistanceTrackerMediaAction');
    },
    setPatientDistanceTrackerMediaAction: debounce(
      async ({ commit, state }) => {
        if (
          state.videoParticipants &&
          state.videoParticipants.local &&
          state.videoParticipants.local.video
        ) {
          // TODO check the test on this part
          if (state.patientDistanceTrackerReady) {
            // don't need to trigger run on the face distance tracker library
            const mediaStream = new MediaStream([state.videoParticipants.local.video]);
            return state.patientDistanceTracker.loadMediaStream(mediaStream);
          }

          await state.patientDistanceTracker
            .loadMediaStream(new MediaStream([state.videoParticipants.local.video]))
            .then(async () => {
              await state.patientDistanceTracker.run();
              commit('setPatientDistanceTrackerReady', true);
            })
            // TODO: handle distance tracking init failures more gracefully
            .catch((error) => {
              console.error('Patient distance tracker init failures.');
              console.error(error);
            });
        }
      },
      // in testing we want to avoid delays
      process.env.NODE_ENV === 'test' ? 0 : 2000,
    ),
  },
  sharedActions,
);
