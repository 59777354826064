/**
 * Provides shared video settings methods
 */
import { localSettings } from '@/shared/mixins/localSettings';
import { mapActions, mapState } from 'vuex';

export const controllerLocalSettings = {
  mixins: [localSettings],
  data() {
    return {
      localStorageKey: 'ControllerLocalSettings',
    };
  },
  computed: {
    ...mapState('controller', {
      localSettings: (state) => state.localSettings,
    }),
  },
  methods: {
    ...mapActions('controller', ['initLocalSettingsAction', 'setLocalSettingPropertyAction']),
  },
};
