/* istanbul ignore file */
import PatientWrapper from '@/patient/views/Index';
import PatientAuth from '@/patient/views/Auth';
import store from '@/shared/store';

const requireAuth = (to, from, next) => {
  if (store.getters['patient/isLoggedIn']) {
    next();
  } else {
    next({
      name: 'patient.home',
    });
  }
};

const routes = [
  {
    path: '/',
    redirect: '/p/',
    name: 'patient.home',
    meta: {
      metaTitle: 'Home',
    },
  },
  /**
   * PATIENT
   */
  {
    path: '/p/:invitation_id?' /* if no token display generic content */,
    name: 'patient.assessment.auth',
    component: PatientAuth,
    meta: {
      metaTitle: 'Log in',
    },
  },
  {
    path: '/p',
    name: 'patient.assessment.index',
    component: PatientWrapper,
    meta: {
      metaTitle: 'Home',
    },
    beforeEnter: requireAuth,
    children: [
      {
        path: 'cancelled',
        name: 'patient.assessment.cancelled',
        component: () =>
          import(/* webpackChunkName: "patientCancelled" */ '../views/Cancelled.vue'),
        meta: {
          metaTitle: 'Cancelled',
        },
      },
      {
        path: 'welcome',
        name: 'patient.assessment.welcome',
        component: () => import(/* webpackChunkName: "patient" */ '../views/Welcome.vue'),
        meta: {
          metaTitle: 'Welcome',
        },
      },
      {
        path: 'measure',
        name: 'patient.assessment.examination',
        component: () => import(/* webpackChunkName: "patient" */ '../views/Examination.vue'),
        meta: {
          metaTitle: 'Examination',
          videoWrapperClass: 'content-wrapper full-width-remote',
          examinationWrapperClass: 'not-flex',
        },
        children: [
          {
            path: 'calibrate',
            name: 'patient.assessment.examination.calibrate',
            component: () => import(/* webpackChunkName: "patient" */ '../views/Examination.vue'),
            meta: {
              metaTitle: 'Calibrate',
              videoWrapperClass: 'ignore',
              examinationWrapperClass: 'video-hide-local video-toggle full-height',
            },
          },
          {
            path: 'perform',
            name: 'patient.assessment.examination.measure',
            component: () => import(/* webpackChunkName: "patient" */ '../views/Examination.vue'),
            meta: {
              metaTitle: 'Test',
              videoWrapperClass: 'ignore',
              examinationWrapperClass: 'video-hide-local video-toggle full-height',
            },
          },
        ],
      },
      {
        path: 'end',
        name: 'patient.assessment.end',
        component: () => import(/* webpackChunkName: "patient" */ '../views/End.vue'),
        meta: {
          metaTitle: 'Thank you',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'patient.404',
    component: () => import(/* webpackChunkName: "patient404" */ '../views/404.vue'),
    meta: {
      metaTitle: 'Page not found',
    },
  },
];

export default routes;
