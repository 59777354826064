import { generatorFactory } from '@/controller/classes/MeasureController/Generator';
import { optotypeFactory } from '@/shared/classes/Optotype';
import { SYMBOL_SCORING, LINE_SCORING } from '@/controller/library';
import MeasurementLine from '@/controller/classes/MeasureController/MeasurementLine';
import { events } from '@/shared/classes/Measurement/events';
import { roundAccurately } from '@/shared/utils';
import { symbolSizeFor } from '@/controller/utils';

export default class MeasurementRunner {
  static get DEFAULT_LOGMAR() {
    return 0.6;
  }

  static get SYMBOL_SCORING() {
    return SYMBOL_SCORING;
  }

  static get LINE_SCORING() {
    return LINE_SCORING;
  }

  constructor(messageBroker, targetId, measurementSettings) {
    this._messageBroker = messageBroker;
    this._targetId = targetId;
    this.setMeasurementSettings(measurementSettings);
    this._optotypes = [];
    this._initialise();
  }

  setMeasurementSettings(settings) {
    this.generator = generatorFactory(settings.testType.generator, settings.testType.optotype);
    this.optotype = settings.testType.optotype;
    this.terminationCriteria = settings.terminationCriteria ? settings.terminationCriteria : 3;
    this.lineLength = settings.lineLength ? settings.lineLength : 5;
    this.scoringMethodId = settings.scoringMethodId
      ? settings.scoringMethodId
      : MeasurementRunner.SYMBOL_SCORING;
    this.symbolSpacingScale = settings.symbolSpacingScale ?? 1;
    this.maxLogMar = settings.maxLogMar !== undefined ? settings.maxLogMar : 0.8;
    this.minLogMar = settings.minLogMar !== undefined ? settings.minLogMar : 0.3;
    this.startLogMar =
      this.maxLogMar < MeasurementRunner.DEFAULT_LOGMAR
        ? this.maxLogMar
        : MeasurementRunner.DEFAULT_LOGMAR;
    if (this.minLogMar > this.maxLogMar) {
      throw new Error('Config Error: minimum logMar must be less than maximum logMar.');
    }
    this.distanceInMm = settings.distance; // expect this to be in mm
    this.crowdingBoxOn = settings.crowdingBox;
  }

  set currentLogMar(value) {
    const rounded = roundAccurately(value, 2);
    this._currentLogMar = isNaN(rounded) ? 0 : rounded;
  }

  get currentLogMar() {
    return this._currentLogMar;
  }

  get rangeFindingLines() {
    return this.measurementLines.filter((measurementLine) => measurementLine.length === 1);
  }

  get rangeFound() {
    const rangeFindingLines = this.rangeFindingLines;
    if (rangeFindingLines.length <= 1) {
      return false;
    }
    return (
      rangeFindingLines.filter((line) => line.successCount === 1).length > 0 &&
      rangeFindingLines.filter((line) => line.failCount === 1).length > 0
    );
  }

  get currentlyRangeFinding() {
    return this.scoringMeasurementLines.length === 0 && !this.rangeFound;
  }

  get scoringMeasurementLines() {
    return this.measurementLines.filter((measurementLine) => measurementLine.length > 1);
  }

  get currentMeasurementLine() {
    if (this.measurementLines.length === 0) {
      return undefined;
    }
    return this.measurementLines[this.measurementLines.length - 1];
  }

  getCurrentMeasurementLineOptotype() {
    if (!Object.keys(this._optotypes).includes(this.optotype)) {
      this._optotypes[this.optotype] = optotypeFactory(this.optotype);
    }

    return this._optotypes[this.optotype];
  }

  setCurrentMeasurementLineSymbolSuccessState(index, success) {
    if (index >= this.currentMeasurementLine.measurementLineSymbols.length) {
      throw new Error(
        'Cannot set symbol success as symbol index does not exist in current measurement line',
      );
    }
    this.currentMeasurementLine.measurementLineSymbols[index].success = success;
  }

  setCurrentMeasurementLineSymbolHighlightedState(index, state) {
    if (index >= this.currentMeasurementLine.measurementLineSymbols.length) {
      throw new Error(
        'Cannot set symbol highlighted as symbol index does not exist in current measurement line',
      );
    }
    if (this.currentMeasurementLine.measurementLineSymbols.length <= 1) {
      throw new Error('Cannot set symbol highlighted when not showing multiple symbols');
    }
    if (state) {
      this.currentMeasurementLine.measurementLineSymbols.forEach((measurementLineSymbol) => {
        measurementLineSymbol.highlighted = false;
      });
    }
    this.currentMeasurementLine.measurementLineSymbols[index].highlighted = state;

    this._sendLine(this.currentMeasurementLine, true);
  }

  /**
   * Returns a promise that resolves when the next step is complete
   *
   * @returns {*|Promise<unknown>}
   */
  next() {
    if (!this.currentMeasurementLine || !this.currentMeasurementLine.isComplete) {
      return Promise.reject(
        new Error('cannot take the next step when measurement line is not complete'),
      );
    }

    if (this.canTerminate()) {
      return new Promise((resolve) => {
        this.finish(this.calculateScore(), true);
        resolve();
      });
    }
    return this.generateNextLine();
  }

  /**
   * We can terminate if we have gone through the test and reached the termination criteria
   *
   * @returns {boolean}
   */
  canTerminate() {
    if (!this.currentMeasurementLine || this.currentlyRangeFinding) {
      return false;
    }
    return this.thresholdReached() && this.terminationCriteriaReached();
  }

  /**
   * Has a full scoring line been completely successfully read
   *
   * @returns {boolean}
   */
  thresholdReached() {
    return (
      this.scoringMeasurementLines.filter(
        (scoringLine) => scoringLine.successCount === this.lineLength,
      ).length > 0
    );
  }

  /**
   * Has there been an assessment line that has enough failures to terminate the test
   *
   * @returns {boolean}
   */
  terminationCriteriaReached() {
    return (
      this.scoringMeasurementLines.filter(
        (scoringLine) => scoringLine.failCount >= this.terminationCriteria,
      ).length > 0
    );
  }

  refreshCurrentLine() {
    if (!this.measurementLines.length) {
      throw new Error('no line available to refresh');
    }

    const newLine = new MeasurementLine(
      this.currentMeasurementLine.logMar,
      this.generator.generateLine(
        this.measurementLines.slice(0, -1),
        this.currentMeasurementLine.length,
      ),
    );

    return this._sendLine(newLine).then(() => {
      this.measurementLines[this.measurementLines.length - 1] = newLine;
      return true;
    });
  }

  /**
   * Returns a promise that resolves with true if a new line is generated, and false if it was not possible to generate a new line
   *
   * @returns Promise
   */
  generateNextLine() {
    if (!this.currentMeasurementLine.isComplete) {
      throw new Error('cannot generate next line when current line is not complete.');
    }

    if (this.adjustLogMar()) {
      return this._newLine(this.currentlyRangeFinding ? 1 : this.lineLength).then(() => true);
    } else {
      // in case of floating point drift, we check this with rounding
      const currentlyAtMax =
        roundAccurately(this.currentLogMar, 1) === roundAccurately(this.maxLogMar, 1);

      // we cannot adjust the logMar, so we're either at min or max logMar
      // if we're at min logMar and we've not even range found, then we might be able
      // to score them still from a full line. So generate at the current logMar
      if (this.currentlyRangeFinding && !currentlyAtMax) {
        return this._newLine(this.lineLength).then(() => true);
      }

      // otherwise we terminate early
      return new Promise((resolve) => {
        // perform score calculation when at min log mar, otherwise return the max
        const finalScore = currentlyAtMax ? this.maxLogMar : this.calculateScore();
        this.finish(finalScore, false);
        resolve(false);
      });
    }
  }

  /**
   *
   * @param lineLength
   * @returns {*}
   * @private
   */
  _newLine(lineLength) {
    if (lineLength === undefined) {
      lineLength = 1;
    }
    const newLine = new MeasurementLine(
      this.currentLogMar,
      this.generator.generateLine(
        this.measurementLines.map((measurementLine) => measurementLine.measurementSymbols),
        lineLength,
      ),
    );
    return this._sendLine(newLine).then(() => {
      this.measurementLines.push(newLine);
    });
  }

  _sendLine(measurementLine, noDelay = false) {
    return this._messageBroker.sendAcknowledgedMessage(events.SHOW_SYMBOLS, this._targetId, {
      symbolSize: this.calculateSymbolSize(measurementLine.logMar),
      symbols: measurementLine.measurementLineSymbols,
      optotype: this.optotype,
      crowdingBoxOn: this.crowdingBoxOn,
      symbolSpacingScale: this.symbolSpacingScale,
      noDelay: noDelay,
    });
  }

  calculateSymbolSize(logMar) {
    return symbolSizeFor(logMar, this.distanceInMm);
  }

  /**
   * Adjust the logMar for the next line to be generated, based on the current state of the measurement lines
   * Returns true when this is successful, false if we are already at the limit of the logMar available for
   * the measurement.
   *
   * @returns {boolean}
   */
  adjustLogMar() {
    if (this.currentlyRangeFinding) {
      return this._adjustLogMarForRangeFinding();
    }

    if (!this.thresholdReached()) {
      return this._adjustLogMarForThresholding();
    }

    return this._adjustLogMarForScoring();
  }

  _adjustLogMarForRangeFinding() {
    if (this.currentMeasurementLine.failCount === 1) {
      return this.increaseLogMar(0.2);
    }
    return this.decreaseLogMar(0.2);
  }

  _adjustLogMarForThresholding() {
    if (this.scoringMeasurementLines.length !== 0) {
      // need to make the symbols larger to find a line that patient can completely read
      return this.increaseLogMar(0.1);
    }

    // first thresholding line should be 0.1 logMar smaller than the smallest successful range finding
    const rangeLogMars = this.rangeFindingLines.filter(
      (rangeFindingLine) => rangeFindingLine.successCount === 1,
    );

    this.currentLogMar = rangeLogMars[rangeLogMars.length - 1].logMar;

    return true;
  }

  _adjustLogMarForScoring() {
    // because we might have a thresholding line with failures, we need to work out the smallest
    // logMar line we've got results for, and then show the next size down
    const lowestLogMar = Math.min(
      ...this.scoringMeasurementLines.map((scoringLine) => scoringLine.logMar),
    );

    if (lowestLogMar !== undefined) {
      this.currentLogMar = lowestLogMar;
    }

    return this.decreaseLogMar(0.1);
  }

  /**
   * Returns true when logMar successfully increased by the given amount, false if we cannot increase logMar
   *
   * @param logMar
   * @returns {boolean}
   */
  increaseLogMar(logMar) {
    if (this.currentLogMar >= this.maxLogMar) {
      return false;
    }

    this.currentLogMar = Math.min(this.maxLogMar, this.currentLogMar + logMar);
    return true;
  }

  /**
   * Returns true when logMar successfully decreased by the given amount, false if we cannot decrease logMar
   *
   * @param logMar
   * @returns {boolean}
   */
  decreaseLogMar(logMar) {
    if (this.currentLogMar <= this.minLogMar) {
      return false;
    }

    this.currentLogMar = Math.max(this.minLogMar, this.currentLogMar - logMar);
    return true;
  }

  /**
   * Calculates the logMar score based on the current set of assessment lines
   *
   * @returns {*}
   */
  calculateScore() {
    const failedLines = this.scoringMeasurementLines.filter(
      (measurementLine) => measurementLine.failCount >= this.terminationCriteria,
    );

    const baseLogMar =
      failedLines.length > 0
        ? Math.min(...failedLines.map((failedLine) => failedLine.logMar))
        : this.currentLogMar;

    if (this.scoringMethodId === MeasurementRunner.SYMBOL_SCORING) {
      const failCount = this.scoringMeasurementLines.reduce((failCount, scoringLine) => {
        return failCount + scoringLine.failCount;
      }, 0);
      return baseLogMar + 0.02 * failCount;
    }

    // ensure we handle any floating point issues
    const rounded = roundAccurately(failedLines.length ? baseLogMar + 0.1 : baseLogMar, 2);
    return isNaN(rounded) ? 0 : rounded;
  }

  _initialise() {
    this.measurementLines = [];
    this.currentLogMar =
      this.startLogMar !== undefined ? this.startLogMar : MeasurementRunner.DEFAULT_LOGMAR;
    this._runnerResolver = undefined;
    this._runnerRejector = undefined;
  }

  /**
   * Start the measurement process
   *
   * @param onStartCallback
   * @returns {*}
   */
  begin(onStartCallback) {
    return this._newLine().then(() => {
      onStartCallback();
      return this._createRunnerPromise();
    });
  }

  cancel() {
    this._runnerRejector();
  }

  /**
   * Finish the measurement with the given score. The terminated flag indicates whether the score
   * was achieved by reaching the termination criteria or not (out of range if not)
   *
   * @param score
   * @param terminated {boolean}
   */
  finish(score, terminated) {
    if (terminated === undefined) {
      terminated = true;
    }
    this._runnerResolver([score, terminated]);
  }

  _createRunnerPromise() {
    return new Promise((resolve, reject) => {
      this._runnerResolver = resolve;
      this._runnerRejector = reject;
    });
  }
}
