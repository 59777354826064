import MeasurementLineSymbol from '@/controller/classes/MeasureController/MeasurementLineSymbol';

export default class MeasurementLine {
  constructor(logMar, symbolIds) {
    this.logMar = logMar;
    this.measurementSymbols = symbolIds;
  }

  get length() {
    return this.measurementSymbols.length;
  }

  get measurementSymbols() {
    return this._measurementLineSymbols.map(
      (measurementLineSymbol) => measurementLineSymbol.symbol,
    );
  }

  get measurementLineSymbols() {
    return this._measurementLineSymbols;
  }

  set measurementSymbols(symbolIds) {
    this._measurementLineSymbols = symbolIds.map((symbolId) => new MeasurementLineSymbol(symbolId));
  }

  get isComplete() {
    return (
      this.measurementLineSymbols.filter(
        (measurementLineSymbol) => measurementLineSymbol.success === undefined,
      ).length === 0
    );
  }

  get failCount() {
    return this.measurementLineSymbols.filter(
      (measurementLineSymbol) => measurementLineSymbol.success === false,
    ).length;
  }

  get successCount() {
    return this.measurementLineSymbols.filter(
      (measurementLineSymbol) => measurementLineSymbol.success === true,
    ).length;
  }

  remainingSymbolsSuccess() {
    this.measurementLineSymbols.forEach((measurementLineSymbol) => {
      if (measurementLineSymbol.success === undefined) {
        measurementLineSymbol.success = true;
      }
    });
  }

  remainingSymbolsFail() {
    this.measurementLineSymbols.forEach((measurementLineSymbol) => {
      if (measurementLineSymbol.success === undefined) {
        measurementLineSymbol.success = false;
      }
    });
  }
}
