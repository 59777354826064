import { events as performBrokerMessages } from '@/shared/classes/Perform/events';
import { states as performDisplayStates } from '@/patient/classes/MeasureClient/displayStates';
import { events as measureBrokerMessages } from '@/shared/classes/Measurement/events';
import { optotypeFactory } from '@/shared/classes/Optotype/index';

import Signal from 'signals';

export default class MeasureClient {
  constructor(messageBroker, targetId) {
    this._messageBroker = messageBroker;
    this.targetId = targetId;
    this._initialiseSignals();
    this._initialisePerformDisplayState();
    this._messageBrokerHandlersSetup = false;
    this._optotypes = [];
  }

  get performDisplayState() {
    return this._performDisplayState;
  }

  set performDisplayState(state) {
    if (!Object.values(performDisplayStates).includes(state)) {
      throw new Error(`Invalid display state ${state}`);
    }
    this._performDisplayState = state;

    this.appStateUpdate.dispatch();
  }

  get currentSymbols() {
    return this._currentSymbols;
  }

  get currentSymbolSettings() {
    if (!this._currentSymbols) {
      return undefined;
    }

    if (!Object.keys(this._optotypes).includes(this._currentSymbols.optotype)) {
      this._optotypes[this._currentSymbols.optotype] = optotypeFactory(
        this._currentSymbols.optotype,
      );
    }

    return {
      currentSymbols: this._currentSymbols,
      optotype: this._optotypes[this._currentSymbols.optotype],
    };
  }

  set currentSymbols(data) {
    this._currentSymbols = data;
  }

  /**
   * Reset the state of the calibration client, ensuring it will react to message broker events
   * that are sent from the controller
   */
  start() {
    this._initialiseMessageBrokerHandlers();
  }

  _initialisePerformDisplayState() {
    this.currentSymbols = undefined;
    this.performDisplayState = performDisplayStates.INIT;
  }

  _handlePerformTest(data) {
    this.performDisplayState = performDisplayStates.TEST;
  }

  _handleShowSymbols(data) {
    this.performDisplayState = performDisplayStates.TEST;
    this.currentSymbols = data;
  }

  _initialiseMessageBrokerHandlers() {
    if (this._messageBrokerHandlersSetup) {
      return;
    }

    [
      [performBrokerMessages.PERFORM_INIT, '_initialisePerformDisplayState'],
      [performBrokerMessages.PERFORM_TEST, '_handlePerformTest'],
      [measureBrokerMessages.SHOW_SYMBOLS, '_handleShowSymbols'],
    ].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );

    this._messageBrokerHandlersSetup = true;
  }

  _initialiseSignals() {
    this.appStateUpdate = new Signal();
  }
}
