import { events as calibrationBrokerMessages } from '@/shared/classes/Calibration/events';
import { states as calibrationDisplayStates } from '@/patient/classes/CalibrationClient/displayStates';
import Signal from 'signals';

export default class CalibrationClient {
  constructor(messageBroker, targetId) {
    this._messageBroker = messageBroker;
    this.targetId = targetId;
    this._initialiseSignals();
    this._initialiseCalibrationDisplayState();
    this._messageBrokerHandlersSetup = false;
  }

  get calibrationDisplayState() {
    return this._calibrationDisplayState;
  }

  set calibrationDisplayState(state) {
    if (!Object.values(calibrationDisplayStates).includes(state)) {
      throw new Error(`Invalid display state ${state}`);
    }
    this._calibrationDisplayState = state;

    this.appStateUpdate.dispatch();
  }

  /**
   * Reset the state of the calibration client, ensuring it will react to message broker events
   * that are sent from the controller
   */
  start() {
    this._initialiseMessageBrokerHandlers();
  }

  setCalibrationPixelSizeHeight(height) {
    this._calibrationHeight = height;
    this._saveCalibrationPixelSize(true);
  }

  setCalibrationPixelSizeKnownPixelHeight(knownPixelHeight) {
    this._calibrationPixelSizeKnownPixelHeight = knownPixelHeight;
    this._saveCalibrationPixelSize();
  }

  _saveCalibrationPixelSize(sendMessage) {
    if (sendMessage === undefined) {
      sendMessage = false;
    }

    if (
      this._calibrationHeight !== undefined &&
      this._calibrationPixelSizeKnownPixelHeight !== undefined
    ) {
      this._calibrationPixelSize =
        this._calibrationHeight / this._calibrationPixelSizeKnownPixelHeight;

      if (sendMessage) {
        return this._messageBroker.sendAcknowledgedMessage(
          calibrationBrokerMessages.SET_PIXEL_SIZE,
          this.targetId,
          this._calibrationPixelSize,
        );
      }
    } else if (sendMessage) {
      return new Error('Message requested but pixel size not sent');
    }
  }

  _initialiseCalibrationDisplayState() {
    this.calibrationDisplayState = calibrationDisplayStates.INIT;
  }

  _handleCalibrateScreenPixelSizeH() {
    this.calibrationDisplayState = calibrationDisplayStates.SCREEN_PIXEL_SIZE_H;
  }

  _handleCalibrateScreenPixelSizeCreditCard() {
    this.calibrationDisplayState = calibrationDisplayStates.SCREEN_PIXEL_SIZE_CREDIT_CARD;
  }

  _handleCalibratePatientDistance() {
    this.calibrationDisplayState = calibrationDisplayStates.PATIENT_DISTANCE;
  }

  _handleCalibrateSetCalibrationHeight(height) {
    this.setCalibrationPixelSizeHeight(height);
  }

  _initialiseMessageBrokerHandlers() {
    if (this._messageBrokerHandlersSetup) {
      return;
    }

    [
      [calibrationBrokerMessages.CALIBRATE_INIT, '_initialiseCalibrationDisplayState'],
      [calibrationBrokerMessages.CALIBRATE_SCREEN_PIXEL_SIZE_H, '_handleCalibrateScreenPixelSizeH'],
      [
        calibrationBrokerMessages.CALIBRATE_SCREEN_PIXEL_SIZE_CREDIT_CARD,
        '_handleCalibrateScreenPixelSizeCreditCard',
      ],
      [calibrationBrokerMessages.CALIBRATE_PATIENT_DISTANCE, '_handleCalibratePatientDistance'],
      [
        calibrationBrokerMessages.SET_PIXEL_SIZE_LETTER_HEIGHT,
        '_handleCalibrateSetCalibrationHeight',
      ],
    ].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );

    this._messageBrokerHandlersSetup = true;
  }

  _initialiseSignals() {
    this.appStateUpdate = new Signal();
  }
}
