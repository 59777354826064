import { events as measurementBrokerMessages } from '@/shared/classes/Measurement/events';
import { states as examinationDisplayStates } from '@/patient/classes/ExaminationClient/displayStates';
import CalibrationClient from '@/patient/classes/CalibrationClient';
import MeasureClient from '@/patient/classes/MeasureClient';

const Signal = require('signals');

export default class ExaminationClient {
  constructor(messageBroker, targetId) {
    this._messageBroker = messageBroker;
    this.targetId = targetId;

    this._initialiseSignals();
    this._initialiseExaminationDisplayState();
    this._initialiseMessageBrokerHandlers();
    this._initialiseDisplaySettings();
  }

  get displaySettings() {
    return this._displaySettings;
  }

  get calibrationClient() {
    if (this._calibrationClient === undefined) {
      this._calibrationClient = new CalibrationClient(this._messageBroker, this.targetId);
      this._calibrationClient.appStateUpdate.add(() => {
        this._dispatchAppStateUpdate();
      });
    }

    return this._calibrationClient;
  }

  get measurementClient() {
    if (this._measurementClient === undefined) {
      this._measurementClient = new MeasureClient(this._messageBroker, this.targetId);
      this._measurementClient.appStateUpdate.add(() => {
        this._dispatchAppStateUpdate();
      });
    }

    return this._measurementClient;
  }

  get examinationDisplayState() {
    return this._examinationDisplayState;
  }

  set examinationDisplayState(state) {
    if (!Object.values(examinationDisplayStates).includes(state)) {
      throw new Error(`Invalid display state ${state}`);
    }
    this._examinationDisplayState = state;
    this.examinationDisplayStateChanged.dispatch(state);

    this._dispatchAppStateUpdate();
  }

  setMeasurementContainerSize(measurementContainerSize) {
    this._displaySettings.container.width = measurementContainerSize.width;
    this._displaySettings.container.height = measurementContainerSize.height;

    this._sendPatientDisplaySettings();
  }

  _sendPatientDisplaySettings() {
    if (this._displaySettings.pixelSize !== undefined) {
      this._messageBroker.sendUnacknowledgedMessage(
        measurementBrokerMessages.UPDATE_PATIENT_DISPLAY_SETTINGS,
        this.targetId,
        this._displaySettings,
      );
    } else {
      console.warn('Not sending patient display update as pixel size not set');
    }
  }

  _initialiseExaminationDisplayState() {
    this._examinationDisplayState = examinationDisplayStates.AWAITING_ACTION;
  }

  _handleWait() {
    this.examinationDisplayState = examinationDisplayStates.AWAITING_ACTION;
  }

  // _handleCalibrateInit () {
  _handleCalibrate() {
    this.examinationDisplayState = examinationDisplayStates.CALIBRATE;
  }

  _handlePerform() {
    this.examinationDisplayState = examinationDisplayStates.PERFORM;
  }

  _handleSetPixelSize(pixelSize) {
    this._displaySettings.pixelSize = pixelSize;

    this._sendPatientDisplaySettings();
  }

  _initialiseMessageBrokerHandlers() {
    [
      [measurementBrokerMessages.CALIBRATE, '_handleCalibrate'],
      [measurementBrokerMessages.WAIT, '_handleWait'],
      [measurementBrokerMessages.PERFORM, '_handlePerform'],
      [measurementBrokerMessages.SET_PIXEL_SIZE, '_handleSetPixelSize'],
    ].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );
  }

  _initialiseDisplaySettings() {
    this._displaySettings = {
      pixelSize: undefined,
      container: {
        width: undefined,
        height: undefined,
      },
    };
  }

  _dispatchAppStateUpdate() {
    this.appStateUpdate.dispatch();
  }

  _initialiseSignals() {
    this.examinationDisplayStateChanged = new Signal();
    this.appStateUpdate = new Signal();
  }
}
