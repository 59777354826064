<template>
  <div class="notice" v-if="notice" data-test="notice-panel">
    <div class="notice-text">{{ notice }}</div>
  </div>
</template>

<script>
import { getConfig } from '@/shared/config';

export default {
  name: 'Notice',
  props: {
    notice: {
      type: String,
      default: () => {
        return getConfig('VUE_APP_NOTICE');
      },
    },
  },
  computed: {
    hasNotice() {
      return !!this.notice;
    },
  },
  created() {
    if (this.hasNotice) {
      document.getElementsByTagName('body')[0].classList.add('has-notice');
    }
  },
};
</script>

<style scoped></style>
