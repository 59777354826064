import { mapActions } from 'vuex';
import { browserNavigationCheck } from '@/shared/mixins/browserNavigationCheck';
import lang from '@/controller/lang/en_GB';

export const controllerBrowserNavigationCheck = {
  mixins: [browserNavigationCheck],
  methods: {
    ...mapActions('controller', ['addErrorAction']),
    showBrowserNavigationError() {
      // console.log('Trigger error');
      this.addErrorAction({
        id: 'browser-navigation',
        title: lang.error.app.history_navigation.title,
        body: lang.error.app.history_navigation.body,
        dismissable: true,
      });
    },
  },
};
