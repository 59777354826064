<template>
  <header class="header header-controller">
    <span class="logo-wrapper">
      <span class="logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155 22">
          <path
            d="M124.64,19.51a1,1,0,0,0,1.72,0l8.53-14.37a.77.77,0,0,0-1.34-.76L125.7,17.61a.23.23,0,0,1-.4,0L117.45,4.38a.77.77,0,0,0-1.34.76Z"
            style="fill: #3ac7ff; fill-rule: evenodd"
          />
          <path
            id="A"
            d="M144.64,4.49a1,1,0,0,1,1.72,0h0l8.53,14.37a.77.77,0,0,1-1.34.76h0L151.1,15.5H139.9l-2.45,4.12a.75.75,0,0,1-.57.37h-.1a.76.76,0,0,1-.67-1.14h0Zm1.06,1.9a.23.23,0,0,0-.4,0h0L140.79,14h9.42Z"
            style="fill: #3ac7ff; fill-rule: evenodd"
          />
          <rect id="line" x="100" width="1" height="22" style="fill: #113c50" />
          <path
            d="M74.1,10.12h-7a4.22,4.22,0,0,1-4.27-4.18,4.23,4.23,0,0,1,4.27-4.19H78.42a.88.88,0,0,0,.89-.87A.88.88,0,0,0,78.42,0H67.06A6,6,0,0,0,61,5.94a6,6,0,0,0,6.06,5.94h7a4.19,4.19,0,1,1,0,8.37H62.25a.88.88,0,1,0,0,1.75H74.1a5.94,5.94,0,1,0,0-11.88"
            style="fill: #fff; fill-rule: evenodd"
          />
          <path
            d="M42.61,0h.53a11,11,0,0,1,.25,22h-.53a11,11,0,0,1-.25-22ZM43,2h-.34a9,9,0,0,0-.05,18h.71a9,9,0,0,0,.05-18Z"
            style="fill: #fff"
          />
          <path
            d="M14.18.67a1.38,1.38,0,0,0-2.36,0L.14,20.44A1,1,0,0,0,1.07,22,1.07,1.07,0,0,0,2,21.48L12.73,3.28a.32.32,0,0,1,.54,0L24,21.48a1.07,1.07,0,0,0,.92.52,1,1,0,0,0,.93-1.56Z"
            style="fill: #fff; fill-rule: evenodd"
          />
        </svg>
      </span>
    </span>
    <span class="buttons-wrapper">
      <div class="form-group space-right">
        <VideoTimer /><!--
                    --><VideoCameraMute /><!--
                    --><VideoAudioMute />
      </div>
      <button
        class="btn-reverse end-session space-right"
        @click.prevent="endSession"
        :disabled="hideExitBtn"
      >
        Exit<span class="icon"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 9 9"
            style="enable-background: new 0 0 9 9"
            xml:space="preserve"
          >
            <path
              d="M7.7,8.6C7.4,8.6,7.2,8.5,7,8.3L4.5,5.8L2,8.3c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l2.5-2.5L0.6,1.9
                            c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L4.5,3L7,0.5c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L5.9,4.4l2.5,2.5c0.4,0.4,0.4,1,0,1.4
                            C8.2,8.5,7.9,8.6,7.7,8.6z"
            /></svg
        ></span>
      </button>
      <button
        class="btn-reverse settings"
        ref="settingsButton"
        @click.prevent="toggleShowGlobalSettings"
      >
        <span class="sr-only">Settings</span
        ><span class="icon"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 20"
            style="enable-background: new 0 0 24 20"
            xml:space="preserve"
          >
            <path d="M2,18H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h1c0.6,0,1,0.4,1,1S2.6,18,2,18z" />
            <path d="M23,18H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h15c0.6,0,1,0.4,1,1S23.6,18,23,18z" />
            <path d="M9,11H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S9.6,11,9,11z" />
            <path d="M23,11h-8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S23.6,11,23,11z" />
            <path d="M16,4H1C0.4,4,0,3.6,0,3s0.4-1,1-1h15c0.6,0,1,0.4,1,1S16.6,4,16,4z" />
            <path d="M23,4h-1c-0.6,0-1-0.4-1-1s0.4-1,1-1h1c0.6,0,1,0.4,1,1S23.6,4,23,4z" />
            <path
              d="M5,20c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S6.7,20,5,20z M5,15.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S5.8,15.5,5,15.5z"
            />
            <path
              d="M12,13c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S13.7,13,12,13z M12,8.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S12.8,8.5,12,8.5z"
            />
            <path
              d="M19,6c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S20.7,6,19,6z M19,1.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S19.8,1.5,19,1.5z"
            /></svg
        ></span>
      </button>
    </span>
  </header>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import VideoCameraMute from '@/controller/components/VideoCameraMute';
import VideoAudioMute from '@/controller/components/VideoAudioMute';
import VideoTimer from '@/controller/components/VideoTimer';
import lang from '@/controller/lang/en_GB';

export default {
  name: 'Header',
  components: { VideoTimer, VideoAudioMute, VideoCameraMute },
  computed: {
    ...mapState('controller', {
      showGlobalSettings: (state) => state.showGlobalSettings,
      assessmentController: (state) => state.assessmentController,
    }),
    hideExitBtn() {
      return this.$route.meta.hideExitBtn;
    },
  },
  methods: {
    ...mapActions('controller', [
      'setShowGlobalSettingsAction',
      'showConfirmAction',
      'exitApplicationAction',
    ]),
    toggleShowGlobalSettings() {
      this.setShowGlobalSettingsAction(!this.showGlobalSettings);
      this.$refs.settingsButton.blur();
    },
    endSession() {
      this.showConfirmAction({
        title: lang.confirm.stop_assessment.title,
        body: lang.confirm.stop_assessment.body,
        cancelCallback: () => {},
        okayCallback: () => {
          if (this.assessmentController) {
            this.assessmentController
              .abandonAssessment()
              .catch(() => {}) // still logout if there's any issue with abandoning the assessment
              .then(() => {
                this.exitApplicationAction();
              });
          } else {
            this.exitApplicationAction();
          }
        },
        cancelLabel: lang.confirm.stop_assessment.cancel_label,
        okayLabel: lang.confirm.stop_assessment.okay_label,
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
