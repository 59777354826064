const lang = {
  // page title suffix
  page_title_suffix: ' - AOS Visual Acuity',

  // server error
  error_message_server_error_title: 'API Error.',
  error_message_server_error_body:
    'There has been a problem on the server, this assessment cannot continue.',

  // connection error
  error_message_no_connection_title: 'No internet connection.',
  error_message_no_connection_body:
    'Connection to the internet is required, this assessment cannot continue.',

  // session
  error_message_session_expired_title: 'Session expired.',
  error_message_session_expired_body: 'Your session has expired, this assessment cannot continue.',

  // video call
  error_message_video_call_title: 'Video call error.',
};

export default lang;
