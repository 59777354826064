import { mapActions, mapState } from 'vuex';
import { appStatusCheck } from '@/shared/mixins/appStatusCheck';
import { browserNavigationCheck } from '@/shared/mixins/browserNavigationCheck';
import lang from '@/patient/lang/en_GB';

export const patientAppStatusCheck = {
  mixins: [appStatusCheck, browserNavigationCheck],
  computed: {
    ...mapState('patient', {
      appClass: (state) => state.assessmentClient,
    }),
  },
  methods: {
    ...mapActions('patient', ['addErrorAction']),
    showStateError() {
      this.addErrorAction({
        id: 'bad-state',
        title: lang.error.app.bad_state.title,
        body: lang.error.app.bad_state.body,
        dismissable: false,
      });
    },
    showBrowserNavigationError() {
      this.addErrorAction({
        id: 'browser-navigation',
        title: lang.error.app.history_navigation.title,
        body: lang.error.app.history_navigation.body,
        dismissable: true,
      });
    },
  },
};
