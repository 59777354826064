import AssessmentController from '@/controller/classes/AssessmentController';
import { createConnectingMessageBroker } from '@/shared/classes/MessageBroker';
import { getAppRouteElements } from '@/controller/router/appStateRoutes';

import { sharedActions } from '@/shared/store/actions';
import ControllerApi from '@/controller/api';
import lang from '@/controller/lang/en_GB';
import { getConfig } from '@/shared/config';

export const controllerActions = Object.assign(
  {
    storeApplicationQueryAction({ commit }, query) {
      const storedQueryParams = ['token', 'userId', 'patientId'].reduce((queryMap, queryParam) => {
        if (query[queryParam]) {
          queryMap[queryParam] = query[queryParam];
        }
        return queryMap;
      }, {});
      commit('setInitialApplicationQuery', storedQueryParams);
    },
    loginControllerAction({ commit, dispatch, state }, payload) {
      return dispatch('createDataServiceAction')
        .then((_) => dispatch('setApiAction', new ControllerApi(state.dataService)))
        .then((_) => state.api.login(payload.token, payload.patientId, payload.userId));
    },
    loadPatientInformation({ commit, dispatch, state }) {
      return state.api
        .getPatientInfo()
        .then((patientData) => commit('setPatientInfo', patientData))
        .catch((err) => {
          if (err.message.includes('unexpected success response')) {
            dispatch('addErrorAction', {
              id: 'patient-load-error',
              title: lang.error.app.patient_info.title,
              body: lang.error.app.patient_info.body,
              restart: true,
              dismissable: false,
            });
          }
          throw err;
        });
    },
    loadUserInformationAction({ commit, dispatch, state }) {
      return state.api
        .getUserInfo()
        .then((userData) => commit('setUserInfo', userData))
        .catch((err) => {
          if (err.message.includes('unexpected success response')) {
            dispatch('addErrorAction', {
              id: 'user-load-error',
              title: lang.error.app.user_info.title,
              body: lang.error.app.user_info.body,
              restart: true,
              dismissable: false,
            });
          }
          throw err;
        });
    },
    async loadAssessmentInformationAction({ commit, state }) {
      const initialisationData = await state.api.initialiseNewAssessment();

      commit('setInvitationDetails', {
        link: initialisationData.invitationLink,
        pin: initialisationData.invitationPin,
      });
    },
    async initialiseAssessmentAction({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        const commsDefinition = state.api.getCommsDefinition();
        if (commsDefinition) {
          resolve(commsDefinition);
        }
        reject(new Error('comms parameters not defined'));
      })
        .then((commsDefinition) => {
          return createConnectingMessageBroker(commsDefinition);
        })
        .then((broker) => {
          dispatch('setAssessmentControllerAction', new AssessmentController(broker));
        })
        .catch(() => {
          dispatch('addErrorAction', {
            id: 'connection-error',
            title: lang.error.message_broker.error.title,
            body: lang.error.message_broker.error.body,
            restart: true,
            dismissable: false,
          });
        });
    },
    clearInvitationDetailsAction({ commit }) {
      commit('setInvitationDetails', undefined);
    },
    invitePatientByEmailAction({ state }, [email, name]) {
      if (!state.invitationDetails) {
        throw new Error('Cannot send invitation without invitation details in store');
      }
      return state.api.sendInvitationEmail(
        email,
        name,
        state.invitationDetails.link,
        state.invitationDetails.pin,
      );
    },
    invitePatientByMobileAction({ state }, [mobileNumber, name]) {
      if (!state.invitationDetails) {
        throw new Error('Cannot send invitation without invitation details in store');
      }
      return state.api.sendInvitationSms(
        mobileNumber,
        name,
        state.invitationDetails.link,
        state.invitationDetails.pin,
      );
    },
    setAssessmentControllerAction({ commit, dispatch }, assessmentController) {
      commit('setAssessmentController', assessmentController);
      // ensure we set the initial workflow state correctly
      dispatch('setAssessmentWorkflowStateAction', assessmentController.workflowState);
      // pre-load the video call settings for when the call is initialised
      dispatch('getVideoCallSettingsAction');

      assessmentController.workflowStateChanged.add((workflowState) => {
        dispatch('setAssessmentWorkflowStateAction', workflowState);
      });
      assessmentController.newError.add((errorData) => {
        dispatch('addErrorAction', errorData);
      });
      assessmentController.clearError.add((errorId) => {
        dispatch('removeErrorAction', errorId);
      });
      assessmentController.appStateUpdate.add(() => {
        dispatch('checkRouteByAppWorkflowStatesAction');
      });
    },
    setAssessmentWorkflowStateAction({ commit, dispatch }, workflowState) {
      commit('setAssessmentWorkflowState', workflowState);
    },
    async initialiseExaminationControllerAction({ dispatch, state }) {
      if (!state.assessmentController) {
        console.error('cannot init examination controller when assessment controller not defined.');
        return;
      }

      if (state.examinationController) {
        // already initialised
        return;
      }

      await dispatch(
        'setExaminationControllerAction',
        state.assessmentController.examinationController,
      );
    },
    async setExaminationControllerAction({ commit, dispatch }, examinationController) {
      commit('setExaminationController', examinationController);

      // init the new controller with all its handlers etc
      examinationController.initialise();
      examinationController.limitLogMarByPatientDisplayData = getConfig(
        'VUE_APP_ENABLE_PATIENT_DISPLAY_CONSTRAINTS',
        true,
      );

      examinationController.workflowStateChanged.add((workflowState) => {
        dispatch('setExaminationWorkflowStateAction', workflowState);
      });
      examinationController.appStateUpdate.add(() => {
        dispatch('checkRouteByAppWorkflowStatesAction');
      });

      await dispatch(
        'setPatientDistanceTrackingControllerAction',
        examinationController.patientDistanceTrackingController,
      );

      // ensure routing and workflow state is correct now we've initialised
      dispatch('setExaminationWorkflowStateAction', examinationController.workflowState);
      dispatch('checkRouteByAppWorkflowStatesAction');
    },
    setExaminationWorkflowStateAction({ commit, dispatch }, workflowState) {
      commit('setExaminationWorkflowState', workflowState);
    },
    getVideoCallSettingsAction({ commit, state }) {
      commit('setVideoCallSettings', state.api.getVideoCallInformation());
    },
    async initialiseVideoControllerAction({ commit, dispatch, state }) {
      if (!state.assessmentController) {
        console.error('cannot initialise video when assessment controller not set.');
        return;
      }

      if (!state.videoCallSettings) {
        await dispatch('getVideoCallSettingsAction');
      }

      return state.videoCallSettings.then((config) => {
        commit('setVideoController', state.assessmentController.createVideoController(config));
      });
    },
    async saveExaminationResultsAction({ commit, state }, [measurementSettings, results]) {
      if (!measurementSettings) {
        throw new Error('measurementSettings required for saving');
      }

      if (!results) {
        throw new Error('results required for saving');
      }

      return state.api.saveResults(state.api.formatAppResultsForApi(measurementSettings, results));
    },

    checkRouteByAppWorkflowStatesAction({ commit, state }) {
      // assessment controller
      const newRoute = getAppRouteElements(state.assessmentController);

      if (
        Array.isArray(newRoute.routeName) &&
        newRoute.routeName.length > 0 &&
        newRoute.routeName !== undefined
      ) {
        commit('setTargetRoute', newRoute);
      }
    },

    logoutAction({ commit, dispatch, state }) {
      // logs out the API, routing must be handled by caller
      return dispatch('baseLogoutAction').then(() => {
        ['AssessmentWorkflowState', 'ExaminationWorkflowState', 'CalibrationWorkflowState'].forEach(
          (workflowState) => {
            commit(`set${workflowState}`, undefined);
          },
        );
        commit('setPatientInfo', undefined);
        commit('setInvitationDetails', undefined);
        commit('setAssessmentController', undefined);
        commit('setExaminationController', undefined);
        commit('setPatientDistanceTrackingController', undefined);

        if (state.assessmentController) {
          state.assessmentController.release();
        }
      });
    },

    exitApplicationAction({ commit, dispatch }) {
      // ensure logout and take to the application exit route
      return dispatch('logoutAction').then(() => {
        commit('setTargetRoute', { routeName: 'controller.exit', query: {} });
      });
    },

    restartApplicationAction({ commit }) {
      // setting to restart will force an application reload through the route switcher
      commit('setTargetRoute', 'restart');
    },

    setPatientDistanceTrackingControllerAction({ commit }, data) {
      commit('setPatientDistanceTrackingController', data);
    },

    setShowGlobalSettingsAction({ commit }, data) {
      commit('setShowGlobalSettings', data);
    },

    setGlobalSettingsConfirmedAction({ commit }) {
      commit('setGlobalSettingsConfirmed', true);
    },

    setSidebarResultsAction({ commit }, data) {
      commit('setSidebarResults', data);
    },

    setVideoCallStartTimestampAction({ commit }, data) {
      commit('setVideoCallStartTimestamp', data);
    },
    announceTerminationAction({ state }) {
      if (state.assessmentController) {
        state.assessmentController.abandonAssessment().then(() => {
          // take care of controller workflow
          state.assessmentController.endAssessment();
        });
      }
    },

    setCalibrationDataAction({ commit }, data) {
      commit('setCalibrationData', data);
    },
  },
  sharedActions,
);
