import { sharedMutations } from '@/shared/store/mutations';
import { markRaw } from 'vue';

export const patientMutations = Object.assign(
  {
    setAssessmentClient(state, assessmentClient) {
      state.assessmentClient = assessmentClient;
    },
    setVideoClient(state, videoClient) {
      state.videoClient = videoClient;
    },
    setPatientDistanceTrackerReady(state, ready) {
      state.patientDistanceTrackerReady = ready;
    },
    setPatientDistanceTrackerModelReady(state, ready) {
      state.patientDistanceTrackerModelReady = ready;
    },
    setPatientDistanceTracker(state, patientDistanceTracker) {
      state.patientDistanceTracker = markRaw(patientDistanceTracker);
    },
    setPatientDistanceTrackingClient(state, patientDistanceTrackingClient) {
      state.patientDistanceTrackingClient = patientDistanceTrackingClient;
    },
    setExaminationClient(state, examinationClient) {
      state.examinationClient = examinationClient;
    },
    setExaminationDisplayState(state, examinationDisplayState) {
      state.examinationDisplayState = examinationDisplayState;
    },
    setVideoParticipantTracks(state, participants) {
      state.videoParticipants = participants;
    },
  },
  sharedMutations,
);
