/* istanbul ignore file */
export default class MockGenerator {
  constructor(optotype) {
    this.optotype = optotype;
    this.letterSet = [];
    this.mockGeneratedLine = [];
  }

  generateLine(previousLines, length) {
    return this.mockGeneratedLine;
  }
}
