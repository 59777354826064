/* istanbul ignore file */
export const mockTracker = {
  created: [],
  clearMock: () => {
    mockTracker.created = [];
  },
};

export default class MockChannel {
  constructor(config) {
    this.config = config;
    mockTracker.created.push(this);
  }

  subscribe() {
    return Promise.resolve();
  }

  waitFor() {}
  broadcast() {
    return Promise.resolve();
  }
}
