import { states as examinationWorkFlowStates } from '@/controller/classes/ExaminationController/workflow';
import { events as measurementBrokerMessages } from '@/shared/classes/Measurement/events';
import { measurementTypes } from '@/controller/library';
import CalibrationController from '@/controller/classes/CalibrationController';
import MeasureController from '@/controller/classes/MeasureController';

import { keyChecker } from '@/shared/utils';
import PatientDistanceTrackingController from '@/controller/classes/PatientDistanceTrackingController';
import { generatorFactory } from '@/controller/classes/MeasureController/Generator';

const Signal = require('signals');

export default class ExaminationController {
  constructor(messageBroker, targetId) {
    this._messageBroker = messageBroker;
    this.targetId = targetId;
    // define properties for reactivity
    this._workflowState = examinationWorkFlowStates.INIT;
    this._isCalibrated = false;
    this.supportedLogMarRanges = {};
    this._measureController = undefined;
    this._calibrationController = undefined;
    this._patientDistanceTrackingController = undefined;
    this.limitLogMarByPatientDisplayData = true;
  }

  /**
   * Set up asynchronous behaviours. This should be called before using the controller
   * Note: this is separated from the constructor to allow it to be proxied (made reactive) before
   * the necessary callbacks are defined. This ensures that they act on the proxied instance.
   */
  initialise() {
    this._initialiseSignals();
    this._initialiseMessageBrokerHandlers();
  }

  get patientDistanceTrackingController() {
    if (this._patientDistanceTrackingController === undefined) {
      this._patientDistanceTrackingController = new PatientDistanceTrackingController(
        this._messageBroker,
        this.targetId,
      );
      this._patientDistanceTrackingController.initialise();
    }

    return this._patientDistanceTrackingController;
  }

  get calibrationController() {
    if (this._calibrationController === undefined) {
      this._calibrationController = new CalibrationController(this._messageBroker, this.targetId);
      this._calibrationController.initialise();

      this._calibrationController.patientPixelSizeChanged.add((pixelSize) => {
        this.setPatientPixelSize(pixelSize);
      });
      this._calibrationController.appStateUpdate.add(() => {
        this._dispatchAppStateUpdate();
      });
    }

    return this._calibrationController;
  }

  get measureController() {
    if (this._measureController === undefined) {
      this._measureController = new MeasureController(this._messageBroker, this.targetId);
      this._measureController.appStateUpdate.add(() => {
        this._dispatchAppStateUpdate();
      });
    }

    return this._measureController;
  }

  get workflowState() {
    return this._workflowState;
  }

  set workflowState(state) {
    if (!Object.values(examinationWorkFlowStates).includes(state)) {
      throw new Error(`Invalid workflow state ${state}`);
    }
    this._workflowState = state;
    this.workflowStateChanged.dispatch(state);

    this._dispatchAppStateUpdate();
  }

  get isCalibrated() {
    return this._isCalibrated;
  }

  setPatientPixelSize(pixelSize) {
    return this._messageBroker.sendAcknowledgedMessage(
      measurementBrokerMessages.SET_PIXEL_SIZE,
      this.targetId,
      pixelSize,
    );
  }

  beginCalibration() {
    if (this.workflowState === examinationWorkFlowStates.CALIBRATE) {
      throw new Error('Already calibrating');
    }
    if (this.workflowState !== examinationWorkFlowStates.INIT) {
      throw new Error(`Cannot begin calibration from current workflow state ${this.workflowState}`);
    }
    return this._messageBroker
      .sendAcknowledgedMessage(measurementBrokerMessages.CALIBRATE, this.targetId)
      .then(() => {
        return this.calibrationController.calibrate(() => {
          this._isCalibrated = false;
          this.workflowState = examinationWorkFlowStates.CALIBRATE;
        });
      })
      .then(() => {
        return this._messageBroker.sendAcknowledgedMessage(
          measurementBrokerMessages.WAIT,
          this.targetId,
        );
      })
      .then(() => {
        this._isCalibrated = true;
        this.workflowState = examinationWorkFlowStates.INIT;
      });
  }

  get measurementTypes() {
    return this._measurementTypes ?? measurementTypes;
  }

  set measurementTypes(measurementTypes) {
    this._measurementTypes = measurementTypes;
  }

  getMeasurementType(measurementTypeId) {
    const filtered = this.measurementTypes.filter(
      (measurementType) => measurementType.id === measurementTypeId,
    );
    if (filtered.length !== 1) {
      throw new Error(`Invalid measurementTypeId ${measurementTypeId}`);
    }

    return filtered[0];
  }

  updateMeasurementTypeSupport() {
    if (!this._patientDisplaySettings.pixelSize) {
      // cannot calculate without pixel size being set
      return;
    }

    if (!this.limitLogMarByPatientDisplayData) {
      return;
    }

    // object indexed by measurement type id and then test type id
    // defining the max and min logMar supported by the optotype of that
    // testType at the measurement distance
    this.supportedLogMarRanges = this.measurementTypes.reduce(
      (byMeasurementTypeId, measurementType) => {
        byMeasurementTypeId[measurementType.id] = measurementType.testTypes.reduce(
          (byTestTypeId, testType) => {
            byTestTypeId[testType.id] = generatorFactory(
              testType.generator,
              testType.optotype,
            ).calculateSupportedLogMarRange(
              {
                width: this._patientDisplaySettings.container.width,
                height: this._patientDisplaySettings.container.height,
              },
              this._patientDisplaySettings.pixelSize,
              measurementType.distance,
              measurementType.maxLogMar,
              measurementType.minLogMar,
              measurementType.spacingScale ?? 1,
            );
            return byTestTypeId;
          },
          {},
        );
        return byMeasurementTypeId;
      },
      {},
    );

    this.setLogMarRangeOverrides();
  }

  /**
   * Begin measurement with the measurement controller, managing the workflow state
   * through the promise chain of sending messages and setting the right configuration.
   *
   * @param measurementTypeId
   * @returns {*}
   */
  beginMeasurement(measurementTypeId) {
    if (this.workflowState === examinationWorkFlowStates.PERFORM) {
      throw new Error('Already measuring');
    }

    if (measurementTypeId === undefined) {
      measurementTypeId = this.measurementTypes[0].id;
    }

    if (!this.isCalibrated) {
      throw new Error('Cannot begin measurement when not calibrated.');
    }

    const measurementType = this.getMeasurementType(measurementTypeId);

    return this._messageBroker
      .sendAcknowledgedMessage(measurementBrokerMessages.PERFORM, this.targetId)
      .then(() => {
        return this.measureController.begin(
          measurementType,
          () => {
            // measurement type distance is in mm, but distance tracking is all in cm
            this.patientDistanceTrackingController.targetTrackingDistance =
              measurementType.distance / 10;
            // automatically want to track patient distance at the start of a measurement
            this.patientDistanceTrackingController.unpause();
            this.setLogMarRangeOverrides();
            this.workflowState = examinationWorkFlowStates.PERFORM;
          },
          () => {
            this.patientDistanceTrackingController.targetTrackingDistance = undefined;
            // would expect it to already be paused, but just in case
            this.patientDistanceTrackingController.pause();
            this.workflowState = examinationWorkFlowStates.INIT;
          },
        );
      });
  }

  /**
   * Set the logMar range of the measure controller based on what the patient display can support
   *
   * By always explicitly setting the controller logMars, we ensure that we restore the full
   * range if the supported range is expanded by changes to the patient display
   */
  setLogMarRangeOverrides() {
    if (!this.measureController || !this.measureController.measurementType) {
      return;
    }

    const measurementType = this.measureController.measurementType;

    // update the test type limits to expose to lower level components (settings)
    this.measureController.setLogMarLimitsForTestTypes(
      this.supportedLogMarRanges[measurementType.id],
    );
  }

  /**
   * Remove all asynchronous behaviour from the controller
   */
  release() {
    this._removeAllSignalHandlers();
    if (this._patientDistanceTrackingController) {
      this._patientDistanceTrackingController.release();
      delete this._patientDistanceTrackingController;
    }
    if (this._calibrationController) {
      this._calibrationController.release();
      delete this._calibrationController;
    }
    if (this._measureController) {
      this._measureController.release();
      delete this._measureController;
    }
  }

  _initialiseSignals() {
    this.workflowStateChanged = new Signal();
    this.appStateUpdate = new Signal();
  }

  _removeAllSignalHandlers() {
    this.workflowStateChanged.removeAll();
    this.appStateUpdate.removeAll();
  }

  _handlePatientDisplayUpdate(patientDisplaySettings) {
    if (!keyChecker(patientDisplaySettings, ['pixelSize', 'container'])) {
      throw new Error('Cannot update patient display settings as missing keys');
    }

    if (
      !this._patientDisplaySettings ||
      this._patientDisplaySettingsAreDifferent(patientDisplaySettings, this._patientDisplaySettings)
    ) {
      this._patientDisplaySettings = patientDisplaySettings;
      this.updateMeasurementTypeSupport();
    }
  }

  _initialiseMessageBrokerHandlers() {
    [
      [measurementBrokerMessages.UPDATE_PATIENT_DISPLAY_SETTINGS, '_handlePatientDisplayUpdate'],
    ].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );
  }

  _dispatchAppStateUpdate() {
    this.appStateUpdate.dispatch();
  }

  _patientDisplaySettingsAreDifferent(a, b) {
    return (
      a.container.height !== b.container.height ||
      a.container.width !== b.container.width ||
      a.pixelSize !== b.pixelSize
    );
  }
}
