import BaseGenerator from '@/controller/classes/MeasureController/Generator/BaseGenerator';

export default class WordGenerator extends BaseGenerator {
  // defines the number of short and long words to use based on the line length
  lengthToWordSizeRatio = {
    5: [2, 3],
  };

  get shortWords() {
    if (!this._shortWords) {
      this._shortWords = this.optotype.symbolOptions.filter((word) => word.length < 5);
    }
    return this._shortWords;
  }

  get longWords() {
    if (!this._longWords) {
      this._longWords = this.optotype.symbolOptions.filter((word) => word.length >= 5);
    }
    return this._longWords;
  }

  wordSetsForLength(length) {
    if (this.lengthToWordSizeRatio[length] === undefined) {
      return [
        {
          count: length,
          words: this.optotype.symbolOptions,
        },
      ];
    }

    return [
      {
        count: this.lengthToWordSizeRatio[length][0],
        words: this.shortWords,
      },
      {
        count: this.lengthToWordSizeRatio[length][1],
        words: this.longWords,
      },
    ];
  }

  _maximumLineSpacingRequired(symbolCount, spacingScale) {
    const shortWordLength = 4;
    const longWordLength = 5;
    const wordCounts = this.lengthToWordSizeRatio[symbolCount];
    let totalLineLength = shortWordLength * wordCounts[0] + longWordLength * wordCounts[1];
    // add spaces - between symbols, and then one either side for crowding box
    totalLineLength += (symbolCount + 1) * spacingScale;
    // crowding box line thickness
    totalLineLength += 0.2 * 2;

    return totalLineLength;
  }

  _pickRandomWord(from, exceptWords, notStartingWith) {
    let availableWords = from.filter((word) => !exceptWords.includes(word));
    if (notStartingWith) {
      availableWords = availableWords.filter((word) => word.indexOf(notStartingWith) !== 0);
    }
    return availableWords[Math.floor(Math.random() * availableWords.length)];
  }

  /**
   * Will not repeat words from the most recent 3 previousLines, oldest lines are expected to be first in
   * previousLines array. Length will default to 1 if not provided
   *
   * @param previousLines
   * @param length
   * @returns {*[]}
   */
  generateLine(previousLines, length) {
    if (previousLines === undefined) {
      previousLines = [];
    }
    if (length === undefined) {
      length = 1;
    }
    if (length < 1) {
      throw new Error(`invalid line length ${length}`);
    }
    if (length > 1 && this.lengthToWordSizeRatio[length] === undefined) {
      throw new Error(`line length of ${length} is unsupported`);
    }

    // flatten previous lines into single list of words
    const previousWords = previousLines.slice(-3).reduce((words, line) => words.concat(line), []);
    const line = [];
    const requiredWordCount = this.wordSetsForLength(length).map((wordSet) => wordSet.count);

    // generate until we've reached the required total
    while (
      requiredWordCount.reduce(
        (remainingRequired, remainingRequiredForSet) => remainingRequired + remainingRequiredForSet,
      ) > 0
    ) {
      const remainingWordSetIndexes = requiredWordCount.reduce(
        (wordSetIndexes, remainingRequiredForSet, wordSetIndex) => {
          if (remainingRequiredForSet > 0) {
            wordSetIndexes.push(wordSetIndex);
          }
          return wordSetIndexes;
        },
        [],
      );
      const selectedWordSetIndex =
        remainingWordSetIndexes[Math.floor(Math.random() * remainingWordSetIndexes.length)];

      line.push(
        this._pickRandomWord(
          this.wordSetsForLength(length)[selectedWordSetIndex].words,
          previousWords.concat(line),
          line.length ? line[0].charAt(0) : undefined,
        ),
      );
      requiredWordCount[selectedWordSetIndex]--;
    }

    return line;
  }
}
