<template>
  <div class="wrapper patient">
    <Notice />
    <main class="main">
      <PatientHeader />

      <router-view />

      <VAFooter />
      <ConfirmPopup />
      <ErrorPopup />
    </main>
  </div>
</template>

<script>
import PatientHeader from '@/patient/components/Header';
import ConfirmPopup from '@/patient/components/ConfirmPopup';
import ErrorPopup from '@/patient/components/ErrorPopup';
import Notice from '@/shared/components/Notice';
import VAFooter from '@/shared/components/VAFooter';

import { patientAppStatusCheck } from '@/patient/mixins/appStatusCheck';
import { patientRouteSwitcher } from '@/patient/mixins/routeSwitcher';

export default {
  name: 'PatientWrapper',
  components: { Notice, ConfirmPopup, PatientHeader, VAFooter, ErrorPopup },
  mixins: [patientAppStatusCheck, patientRouteSwitcher],
};
</script>
