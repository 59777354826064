import WordGenerator from '@/controller/classes/MeasureController/Generator/word';
import LetterGenerator from '@/controller/classes/MeasureController/Generator/letter';
import { optotypeFactory } from '@/shared/classes/Optotype';
import MockGenerator from '@/controller/classes/MeasureController/Generator/mock';

const generatorTypes = {
  word: WordGenerator,
  letter: LetterGenerator,
  mock: MockGenerator,
};

const createdGenerators = {};

const generatorFactory = (type, optotypeId) => {
  const createdKey = type + '-' + optotypeId;
  if (!createdGenerators[createdKey]) {
    const optoType = optotypeFactory(optotypeId);
    createdGenerators[createdKey] = new generatorTypes[type](optoType);
  }

  return createdGenerators[createdKey];
};

export { generatorFactory };
