import { generateCommsDefinitionForPusher, log } from '@/shared/utils';
import BaseApi from '@/shared/api';

export default class PatientApi extends BaseApi {
  constructor(dataService) {
    super(dataService, '/p/auth/refresh');
  }

  async login(invitationId, invitationPin) {
    return this.dataService
      .silenceSessionErrorHandling()
      .setRequestId('login')
      .postToAPI('/p/auth', {
        token: invitationId,
        pin: invitationPin,
      })
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          this.setBearer(response.data.bearer);
          this.loggedIn = true;
          if (!this.sessionData && !this.sessionData.msgChannelName) {
            throw new Error('missing msg broker channel name from session data.');
          }

          return {
            commsDefinition: generateCommsDefinitionForPusher(
              response.data.bearer,
              this.sessionData.msgChannelName,
            ),
          };
        }
        throw new Error('Unexpected login response');
      })
      .catch((error) => {
        log('login error', error);
        this.loggedIn = false;
        if (error.response) {
          return Promise.reject(error.response);
        }

        return Promise.reject(error);
      });
  }
}
