<template>
  <div v-if="error" class="pop-up pop-up-error" data-test="error-popup">
    <div class="pop-up-content">
      <div class="panel">
        <div class="panel-header">
          <h1>{{ error.title }}</h1>
        </div>
        <div class="panel-content">
          <p v-html="error.body"></p>
          <p v-if="error.debugInfo">
            <small>({{ error.debugInfo }})</small>
          </p>
        </div>
        <div class="btn-row space-above" v-if="error.dismissable">
          <button class="item-left" data-test="error-popup-close" @click="errorPopupClose">
            Okay
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ErrorPopup',
  computed: {
    ...mapState('patient', {
      errors: (state) => state.errors,
    }),
    error() {
      return this.errors.length > 0 ? this.errors[0] : null;
    },
  },
  methods: {
    ...mapActions('patient', ['removeErrorAction']),
    errorPopupClose() {
      this.removeErrorAction(this.error.id);
    },
  },
};
</script>

<style scoped></style>
