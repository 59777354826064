<template>
  <header class="header header-patient">
    <span class="logo-wrapper">
      <span class="logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155 22">
          <path
            d="M124.64,19.51a1,1,0,0,0,1.72,0l8.53-14.37a.77.77,0,0,0-1.34-.76L125.7,17.61a.23.23,0,0,1-.4,0L117.45,4.38a.77.77,0,0,0-1.34.76Z"
            style="fill: #3ac7ff; fill-rule: evenodd"
          />
          <path
            d="M144.64,4.49a1,1,0,0,1,1.72,0h0l8.53,14.37a.77.77,0,0,1-1.34.76h0L151.1,15.5H139.9l-2.45,4.12a.75.75,0,0,1-.57.37h-.1a.76.76,0,0,1-.67-1.14h0Zm1.06,1.9a.23.23,0,0,0-.4,0h0L140.79,14h9.42Z"
            style="fill: #3ac7ff; fill-rule: evenodd"
          />
          <rect x="100" width="1" height="22" style="fill: #113c50" />
          <path
            id="S"
            d="M74.1,10.12h-7a4.22,4.22,0,0,1-4.27-4.18,4.23,4.23,0,0,1,4.27-4.19H78.42a.88.88,0,0,0,.89-.87A.88.88,0,0,0,78.42,0H67.06A6,6,0,0,0,61,5.94a6,6,0,0,0,6.06,5.94h7a4.19,4.19,0,1,1,0,8.37H62.25a.88.88,0,1,0,0,1.75H74.1a5.94,5.94,0,1,0,0-11.88"
            style="fill: #02181f; fill-rule: evenodd"
          />
          <path
            id="O"
            d="M42.61,0h.53a11,11,0,0,1,.25,22h-.53a11,11,0,0,1-.25-22ZM43,2h-.34a9,9,0,0,0-.05,18h.71a9,9,0,0,0,.05-18Z"
            style="fill: #02181f"
          />
          <path
            id="A"
            d="M14.18.67a1.38,1.38,0,0,0-2.36,0L.14,20.44A1,1,0,0,0,1.07,22,1.07,1.07,0,0,0,2,21.48L12.73,3.28a.32.32,0,0,1,.54,0L24,21.48a1.07,1.07,0,0,0,.92.52,1,1,0,0,0,.93-1.56Z"
            style="fill: #02181f; fill-rule: evenodd"
          />
        </svg>
      </span>
    </span>
    <span class="buttons-wrapper">
      <VideoCameraMute /><!--
            --><VideoAudioMute /><!--
            -->
      <button @click="toggleFullScreen" class="btn-frameless fullscreen-toggle">
        <span v-if="!showVideoMutes" class="text">Fullscreen </span
        ><span class="icon"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 21 21"
            style="enable-background: new 0 0 21 21"
            xml:space="preserve"
          >
            <path
              d="M1,8c0.5,0,0.9-0.4,1-0.9L2,7V2h5c0.6,0,1-0.4,1-1c0-0.5-0.4-0.9-0.9-1L7,0H1C0.5,0,0.1,0.4,0,0.9L0,1v6C0,7.6,0.4,8,1,8z"
            />
            <path
              d="M1,13c0.5,0,0.9,0.4,1,0.9L2,14v5h5c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L7,21H1c-0.5,0-0.9-0.4-1-0.9L0,20v-6C0,13.4,0.4,13,1,13z"
            />
            <path
              d="M20,8c-0.5,0-0.9-0.4-1-0.9L19,7V2h-5c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L14,0h6c0.5,0,0.9,0.4,1,0.9L21,1v6C21,7.6,20.6,8,20,8z"
            />
            <path
              d="M20,13c-0.5,0-0.9,0.4-1,0.9l0,0.1v5h-5c-0.6,0-1,0.4-1,1c0,0.5,0.4,0.9,0.9,1l0.1,0h6c0.5,0,0.9-0.4,1-0.9l0-0.1v-6C21,13.4,20.6,13,20,13z"
            />
          </svg>
        </span>
      </button>
    </span>
  </header>
</template>

<script>
import screenfull from 'screenfull';

import VideoCameraMute from '@/patient/components/VideoCameraMute';
import VideoAudioMute from '@/patient/components/VideoAudioMute';
import { mapState } from 'vuex';

export default {
  name: 'PatientHeader',
  components: { VideoCameraMute, VideoAudioMute },
  computed: {
    ...mapState('patient', {
      videoClient: (state) => state.videoClient,
    }),
    showVideoMutes() {
      return this.videoClient !== undefined;
    },
  },
  methods: {
    toggleFullScreen() {
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          screenfull.exit();
        } else {
          screenfull.request();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
