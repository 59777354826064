<template>
  <div class="wrapper controller" :class="[{ 'show-global-settings': showGlobalSettings }]">
    <Notice />
    <Header />

    <div class="content-wrapper">
      <div class="content add-grid" :class="layoutClass">
        <router-view />
      </div>
    </div>

    <VAFooter />

    <GlobalSettings />
    <ConfirmPopup />
    <ErrorPopup />
  </div>
</template>

<script>
import Header from '@/controller/components/Header';
import GlobalSettings from '@/controller/components/GlobalSettings';
import ErrorPopup from '@/controller/components/ErrorPopup';
import ConfirmPopup from '@/controller/components/ConfirmPopup';
import Notice from '@/shared/components/Notice';
import VAFooter from '@/shared/components/VAFooter';

import { controllerRouteSwitcher } from '@/controller/mixins/routeSwitcher';
import { controllerBrowserNavigationCheck } from '@/controller/mixins/browserNavigationCheck';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ControllerWrapper',
  components: { Notice, GlobalSettings, ConfirmPopup, Header, VAFooter, ErrorPopup },
  mixins: [controllerRouteSwitcher, controllerBrowserNavigationCheck],
  computed: {
    ...mapState('controller', {
      showGlobalSettings: (state) => state.showGlobalSettings,
    }),
    layoutClass() {
      return this.$route.meta.layoutClass ? this.$route.meta.layoutClass : '';
    },
    routerViewKey() {
      return this.$route.query.refresh ?? 'firstSession';
    },
  },
  methods: {
    ...mapActions('controller', ['storeApplicationQueryAction']),
  },
  created() {
    this.storeApplicationQueryAction(this.$route.query);
  },
};
</script>
