import BaseOptotype from '@/shared/classes/Optotype/BaseOptotype';

export default class Words extends BaseOptotype {
  words = [
    'axis',
    'flex',
    'text',
    'self',
    'wife',
    'asia',
    'bomb',
    'cent',
    'poem',
    'rome',
    'vein',
    'cure',
    'tart',
    'fact',
    'solo',
    'shed',
    'oral',
    'prey',
    'envy',
    'fuss',
    'site',
    'tomb',
    'loom',
    'fuse',
    'foul',
    'quiz',
    'span',
    'lash',
    'fare',
    'rash',
    'reef',
    'ruin',
    'loot',
    'disk',
    'cram',
    'poll',
    'tidy',
    'heel',
    'thaw',
    'feet',
    'gait',
    'daze',
    'moor',
    'plea',
    'drab',
    'debt',
    'fowl',
    'puff',
    'sixty',
    'extra',
    'mixed',
    'exist',
    'board',
    'piano',
    'faith',
    'forty',
    'magic',
    'worth',
    'scent',
    'eagle',
    'organ',
    'spain',
    'later',
    'bored',
    'unity',
    'quest',
    'lance',
    'focus',
    'chant',
    'eager',
    'serve',
    'worst',
    'egypt',
    'empty',
    'abuse',
    'noble',
    'hatch',
    'enemy',
    'frank',
    'hobby',
    'equal',
    'cobra',
    'logic',
    'reign',
    'weary',
    'haven',
    'bonus',
    'alter',
    'arena',
    'staff',
    'grief',
    'basic',
    'quake',
    'fibre',
    'alert',
    'chili',
    'vital',
    'agile',
    'flour',
    'speak',
    'grasp',
    'frail',
    'glare',
    'meant',
    'aisle',
    'trace',
    'onset',
    'minor',
    'sewer',
    'fatal',
    'tutor',
    'bluff',
    'alibi',
    'ferry',
    'adorn',
    'scoff',
    'dwell',
    'brawl',
    'cargo',
    'adapt',
    'gauge',
    'vague',
    'stern',
    'vivid',
    'haste',
    'brisk',
    'dense',
    'peril',
    'blunt',
    'adopt',
    'sniff',
    'tepee',
    'horde',
    'shear',
    'strew',
    'aloft',
    'truly',
    'rowdy',
    'shred',
    'sheer',
    'lofty',
    'waltz',
    'scour',
    'repel',
    'sneer',
    'spurt',
    'spree',
    'unfit',
  ];

  fontScale = 1.88;
  useFontScaleForPhysicalConstraints = false;
  borderRatio = 13; // 6.92 * 1.88 as per scaling requirement from Nilpa Shah

  getLinePadding(spacingScale) {
    if (spacingScale === undefined) {
      spacingScale = 1;
    }
    // we always get 0.5em of vertical spacing because of the double line height.
    // so we subtract this from the requested scale for the padding, but never want
    // negative
    const calcScale = Math.max(spacingScale - 0.8, -0.3);

    return `${calcScale}em 0em`;
  }

  controllerWrapperStyle = '--symbol-font-size:30px; --crowding-box-width:5px';

  get symbolOptions() {
    return this.words;
  }

  getHighlightTopPadding(spacingScale) {
    return spacingScale * -1 + 0.28;
  }

  getHighlightBottomPadding(spacingScale) {
    // slight quirk with the way the font lays out means we have to override the default here
    // this may need tweaking if additional scales are introduced.
    if (spacingScale === 1) {
      return -0.7;
    }

    return spacingScale * -1;
  }

  getSymbolDisplay(symbolId, spacingScale) {
    if (spacingScale === undefined) {
      spacingScale = 1;
    }

    return {
      content: `<span style="font-family: optotype-words-font; color: black;">${symbolId}</span>`,
      spacer: `<span style="font-family: optotype-words-font; font-size: ${spacingScale}em">x</span>`,
      id: symbolId,
    };
  }
}
