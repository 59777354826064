import { controllerActions } from './actions';
import { controllerGetters } from '@/controller/store/getters';
import { controllerMutations } from './mutations';
import { sharedState } from '@/shared/store/state';

const controllerState = Object.assign(
  {
    initialApplicationQuery: undefined,
    invitationDetails: undefined,
    assessmentController: undefined,
    assessmentWorkflowState: undefined,
    videoController: undefined,
    examinationController: undefined,
    examinationWorkflowState: undefined,
    calibrationController: undefined,
    calibrationWorkflowState: undefined,
    calibrationData: undefined,
    patientDistanceTrackingController: undefined,
    showGlobalSettings: false,
    // simple flag to indicate user has confirmed there settings during this session
    globalSettingsConfirmed: false,
    patientInfo: undefined,
    userInfo: undefined,
    sidebarResults: undefined,
    videoCallStartTimestamp: undefined,
  },
  sharedState,
);

export default {
  namespaced: true,
  state: () => controllerState,
  actions: controllerActions,
  getters: controllerGetters,
  mutations: controllerMutations,
};
