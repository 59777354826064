import { states as calibrationWorkFlowStates } from './workflow';
import { events as calibrationBrokerMessages } from '@/shared/classes/Calibration/events';

const Signal = require('signals');

export default class CalibrationController {
  constructor(messageBroker, targetId) {
    this._messageBroker = messageBroker;
    this.targetId = targetId;
    this._calibrationResolver = undefined;
    this._calibrationRejecter = undefined;
    this.screenPixelSizeCalibrationComplete = false;
  }

  initialise() {
    this._initialiseSignals();
    this._initialiseWorkflow();
    this._initialiseMessageBrokerHandlers();
  }

  get workflowState() {
    return this._workflowState;
  }

  set workflowState(state) {
    if (!Object.values(calibrationWorkFlowStates).includes(state)) {
      throw new Error(`Invalid workflow state ${state}`);
    }
    this._workflowState = state;

    this._dispatchAppStateUpdate();
  }

  _initialiseWorkflow() {
    this._workflowState = calibrationWorkFlowStates.INIT;
  }

  calibrate(onStartCallback) {
    return this._messageBroker
      .sendAcknowledgedMessage(calibrationBrokerMessages.CALIBRATE_INIT, this.targetId)
      .then(() => {
        if (onStartCallback !== undefined) {
          onStartCallback();
        }
        return this.startScreenPixelSizeCalibration();
      })
      .then(() => {
        return this._createCalibrationPromise();
      });
  }

  /**
   * Remove all asynchronous behaviour from the controller
   */
  release() {
    this._removeAllSignalHandlers();
  }

  _createCalibrationPromise() {
    return new Promise((resolve, reject) => {
      this._calibrationResolver = resolve;
      this._calibrationRejecter = reject;
    });
  }

  startScreenPixelSizeCalibration() {
    // CAN CHANGE TO startScreenPixelSizeCalibrationH if that one comes first
    return this.startScreenPixelSizeCalibrationCreditCard();
  }

  startScreenPixelSizeCalibrationH() {
    return this._messageBroker
      .sendAcknowledgedMessage(
        calibrationBrokerMessages.CALIBRATE_SCREEN_PIXEL_SIZE_H,
        this.targetId,
      )
      .then(() => {
        this.workflowState = calibrationWorkFlowStates.CALIBRATE_SCREEN_PIXEL_SIZE_H;
      });
  }

  startScreenPixelSizeCalibrationCreditCard() {
    return this._messageBroker
      .sendAcknowledgedMessage(
        calibrationBrokerMessages.CALIBRATE_SCREEN_PIXEL_SIZE_CREDIT_CARD,
        this.targetId,
      )
      .then(() => {
        this.screenPixelSizeCalibrationComplete = false;
        this.workflowState = calibrationWorkFlowStates.CALIBRATE_SCREEN_PIXEL_SIZE_CREDIT_CARD;
      });
  }

  startPatientDistanceCalibration() {
    return this._messageBroker
      .sendAcknowledgedMessage(calibrationBrokerMessages.CALIBRATE_PATIENT_DISTANCE, this.targetId)
      .then(() => {
        this.workflowState = calibrationWorkFlowStates.CALIBRATE_PATIENT_DISTANCE;
      });
  }

  /**
   * The letter height is information that is passed to calibration client, which in turn
   * will then be able to calculate its pixel size and use its message broker to return
   * that information to us.
   *
   * @param letterHeight
   * @returns {*}
   */
  setLetterHeight(letterHeight) {
    return this._messageBroker.sendAcknowledgedMessage(
      calibrationBrokerMessages.SET_PIXEL_SIZE_LETTER_HEIGHT,
      this.targetId,
      letterHeight,
    );
  }

  finishCalibration() {
    this.workflowState = calibrationWorkFlowStates.CALIBRATION_COMPLETE;
    this._complete();
  }

  _complete() {
    if (this._calibrationResolver !== undefined) {
      this._calibrationResolver();
    }
    this._calibrationResolver = undefined;
  }

  _fail() {
    if (this._calibrationRejecter !== undefined) {
      this._calibrationRejecter();
    }
    this._calibrationRejecter = undefined;
  }

  /**
   * Pixel size is set either via credit card sizing or H measurement.
   * If set set by H measurement then calibration auto completes, otherwise a 'screenPixelSizeCalibrationComplete' flag is set
   * to enable manual finish
   *
   * @param pixelSize
   * @private
   */
  _handleSetPixelSize(pixelSize) {
    this.patientPixelSizeChanged.dispatch(pixelSize);
    this.screenPixelSizeCalibrationComplete = true;

    // Auto proceed if using H method
    if (this.workflowState === calibrationWorkFlowStates.CALIBRATE_SCREEN_PIXEL_SIZE_H) {
      this.startPatientDistanceCalibration();
    }
  }

  _initialiseMessageBrokerHandlers() {
    [[calibrationBrokerMessages.SET_PIXEL_SIZE, '_handleSetPixelSize']].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );
  }

  _initialiseSignals() {
    this.patientPixelSizeChanged = new Signal();
    this.appStateUpdate = new Signal();
  }

  _removeAllSignalHandlers() {
    this.patientPixelSizeChanged.removeAll();
    this.appStateUpdate.removeAll();
  }

  _dispatchAppStateUpdate() {
    this.appStateUpdate.dispatch();
  }
}
