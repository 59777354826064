/**
 * @license
 * Copyright 2021 Sparca Ltd. All Rights Reserved.
 * Created by Michael Cheng & Claudio Gobetti.
 */

/**
 * Euclidean distance between two points in 2D space.
 * @param {point2D} a Point A.
 * @param {point2D} b Point B.
 */
const distance2D = (a, b) => {
  return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));
};

/**
 * Euclidean distance between two points in 3D space.
 * @param {point3D} a Point A.
 * @param {point3D} b Point B.
 */
const distance3D = (a, b) => {
  const x = Math.pow(a.x - b.x, 2);
  const y = Math.pow(a.y - b.y, 2);
  const z = Math.pow(a.z - b.z, 2);
  return Math.sqrt(x + y + z);
};

/**
 * Centroid of specified point cluster in 3D space.
 * @param {[point3D]} points The cluster of points.
 * @returns {point3D} The centroid.
 */
const centroid3D = (points) => {
  let x = 0.0;
  let y = 0.0;
  let z = 0.0;
  points.forEach((p) => {
    x += p.x;
    y += p.y;
    z += p.z;
  });
  x /= points.length;
  y /= points.length;
  z /= points.length;
  return { x: x, y: y, z: z };
};

export { distance2D, distance3D, centroid3D };
