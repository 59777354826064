import { states as assessmentDisplayStates } from '@/patient/classes/AssessmentClient/displayStates';
import { states as examinationDisplayStates } from '@/patient/classes/ExaminationClient/displayStates';
import { states as calibrationDisplayStates } from '@/patient/classes/CalibrationClient/displayStates';
import { states as measureDisplayStates } from '@/patient/classes/MeasureClient/displayStates';
import { combineRouteElements } from '@/shared/utils';

export function getAppRouteElements(assessmentClient) {
  const routeElements = {
    [assessmentDisplayStates.ASSESSMENT_STARTED]: () => {
      return combineRouteElements(
        { routeName: ['patient', 'assessment', 'examination'], params: {} },
        getExaminationClientRouteElements(assessmentClient.examinationClient),
      );
    },
    [assessmentDisplayStates.ASSESSMENT_CANCELLED]: {
      routeName: ['patient', 'assessment', 'cancelled'],
    },
    [assessmentDisplayStates.ASSESSMENT_FINISHED]: { routeName: ['patient', 'assessment', 'end'] },
  }[assessmentClient.assessmentDisplayState];

  if (routeElements instanceof Function) {
    return routeElements();
  }
  return routeElements;
}

function getExaminationClientRouteElements(examinationClient) {
  if (examinationClient === undefined) {
    return;
  }

  const routeElements = {
    [examinationDisplayStates.AWAITING_ACTION]: { routeName: [], params: {} },
    [examinationDisplayStates.CALIBRATE]: () => {
      return combineRouteElements(
        { routeName: [], params: {} },
        getCalibrationClientRouteElements(examinationClient.calibrationClient),
      );
    },
    [examinationDisplayStates.PERFORM]: () => {
      return combineRouteElements(
        { routeName: [], params: {} },
        getMeasurementClientRouteElements(examinationClient.measurementClient),
      );
    },
  }[examinationClient.examinationDisplayState];

  if (routeElements instanceof Function) {
    return routeElements();
  }
  return routeElements;
}

function getCalibrationClientRouteElements(calibrationClient) {
  if (calibrationClient === undefined) {
    return;
  }

  return (
    {
      [calibrationDisplayStates.INIT]: { routeName: [], params: {} },
    }[calibrationClient.calibrationDisplayState] ?? { routeName: ['calibrate'], params: {} }
  );
}

function getMeasurementClientRouteElements(measurementClient) {
  if (measurementClient === undefined) {
    return;
  }

  return (
    {
      [measureDisplayStates.INIT]: { routeName: [], params: {} },
    }[measurementClient.performDisplayState] ?? { routeName: ['measure'], params: {} }
  );
}
