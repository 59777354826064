/**
 * Provides shared video settings methods
 */
import { localSettings } from '@/shared/mixins/localSettings';
import { mapActions, mapState } from 'vuex';

export const patientLocalSettings = {
  mixins: [localSettings],
  data() {
    return {
      localStorageKey: 'PatientLocalSettings',
    };
  },
  computed: {
    ...mapState('patient', {
      localSettings: (state) => state.localSettings,
    }),
  },
  methods: {
    ...mapActions('patient', ['initLocalSettingsAction', 'setLocalSettingPropertyAction']),
  },
};
