// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

export default class BaseApi {
  constructor(dataService, refreshUrl) {
    this.dataService = dataService;
    this.loggedIn = false;
    this._bearerToken = undefined;
    this.refreshUrl = refreshUrl;
  }

  /**
   * Number of seconds prior to JWT expiry that a refresh request will be made
   *
   * @returns {number}
   * @constructor
   */
  static get SESSION_EXPIRY_BUFFER() {
    return 60;
  }

  /**
   * Retrieves session data from the current JWT payload
   *
   * @returns {unknown|undefined}
   */
  get sessionData() {
    if (!this.loggedIn) {
      return undefined;
    }
    const payload = jwt_decode(this._bearerToken);
    return payload.data ? payload.data : payload;
  }

  logout() {
    this.setBearer(undefined);
    this.loggedIn = false;
    this.clearSessionRefresh();
  }

  refreshSession() {
    if (!this.refreshUrl) {
      throw new Error('refreshUrl property must be set to allow session refresh.');
    }
    return this.dataService.postToAPI(this.refreshUrl).then((response) => {
      this.setBearer(response.data.bearer);
    });
  }

  setBearer(bearerToken) {
    if (bearerToken !== undefined) {
      const payload = jwt_decode(bearerToken);
      const now = Date.now() / 1000;

      if (payload.exp - now <= BaseApi.SESSION_EXPIRY_BUFFER) {
        throw new Error('token expires too soon for refreshing.');
      }

      this.scheduleSessionRefresh((payload.exp - (now + BaseApi.SESSION_EXPIRY_BUFFER)) * 1000);
    }

    this._bearerToken = bearerToken;
    this.dataService.setBearer(bearerToken);
  }

  scheduleSessionRefresh(inMilliseconds) {
    this.clearSessionRefresh();
    this._scheduledRefresh = setTimeout(() => {
      this.refreshSession();
    }, inMilliseconds);
  }

  clearSessionRefresh() {
    if (this._scheduledRefresh) {
      clearTimeout(this._scheduledRefresh);
    }
  }
}
