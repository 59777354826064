import { events } from '@/shared/classes/Video/events';

export default class VideoController {
  constructor(messageBroker, targetDeviceId, config) {
    this._messageBroker = messageBroker;
    this._targetDeviceId = targetDeviceId;
    this._config = config;
    this._initialiseMessageBrokerHandlers();
  }

  get type() {
    return this._config.type;
  }

  get joinSettings() {
    return {
      url: this.config.settings.url,
      token: this.config.settings.tokens[0],
    };
  }

  get joinSettingsForClient() {
    return {
      url: this.config.settings.url,
      token: this.config.settings.tokens[1],
    };
  }

  get config() {
    return this._config;
  }

  get configForClient() {
    return {
      type: this.type,
      settings: this.joinSettingsForClient,
    };
  }

  startCall() {
    return this._sendVideoCallDetails();
  }

  /**
   * Remove all asynchronous behaviour from the controller
   */
  release() {
    // nothing to do at the moment
  }

  _sendVideoCallDetails() {
    return this._messageBroker.sendUnacknowledgedMessage(
      events.SET_VIDEOCALL_DETAILS,
      this._targetDeviceId,
      this.configForClient,
    );
  }

  _handleGetVideoCallDetails() {
    this._sendVideoCallDetails();
  }

  _initialiseMessageBrokerHandlers() {
    [[events.GET_VIDEOCALL_DETAILS, '_handleGetVideoCallDetails']].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );
  }
}
