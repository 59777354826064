export default class BaseOptotype {
  /**
   * Font scaling is used to adjust the calculated pixel size for rendering optotype fonts.
   * For the words font (for example) this is to adjust the font to be the correct physical size,
   * and therefore it does not need to be taken into account when determining the physical constraints
   * of what sized letters can be displayed.
   *
   * For the auckland (for example) this is to adjust the stroke width, and as such it makes the lettering
   * larger than it otherwise would be for logMar, and therefore impacts what sized letters can be
   * displayed.
   *
   * @type {boolean}
   */
  useFontScaleForPhysicalConstraints = true;
  fontScale = 1;

  getLinePadding(spacingScale) {
    if (spacingScale === undefined) {
      spacingScale = 1;
    }
    // we always get 0.5em of vertical spacing because of the double line height.
    // so we subtract this from the requested scale for the padding, but never want
    // negative
    const calcScale = Math.max(spacingScale - 0.5, 0);

    return `${calcScale}em 0em`;
  }

  getHighlightTopPadding(spacingScale) {
    return spacingScale * -1;
  }

  getHighlightBottomPadding(spacingScale) {
    return spacingScale * -1;
  }
}
