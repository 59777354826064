<template>
  <button
    @click="toggleMuted"
    class="btn-reverse mute-audio form-group-item"
    :class="[{ 'btn-toggled': isMuted }]"
    data-test="btn-audio-mute"
    :disabled="!counterStartTimestamp"
  >
    <span class="sr-only">Mute audio</span
    ><span class="icon"
      ><svg class="default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M18,5.4V8.1a7.76,7.76,0,0,1,2,5.3,8,8,0,0,1-16,0A8.26,8.26,0,0,1,6,8.1V5.4a10,10,0,1,0,16,8A10.14,10.14,0,0,0,18,5.4Z"
        />
        <path
          d="M12,.4a4,4,0,0,0-4,4v7a4,4,0,0,0,8,0v-7A4,4,0,0,0,12,.4Zm2,11a2,2,0,0,1-4,0v-7a2,2,0,0,1,4,0Z"
        />
        <path d="M12,13.4a.94.94,0,0,1,1,1v3a1,1,0,0,1-2,0v-3A1,1,0,0,1,12,13.4Z" />
        <path
          d="M11.5,10.4h1a.47.47,0,0,1,.5.5h0a.47.47,0,0,1-.5.5h-1a.47.47,0,0,1-.5-.5h0A.47.47,0,0,1,11.5,10.4Z"
        />
        <path
          d="M11.5,7.4h1a.47.47,0,0,1,.5.5h0a.47.47,0,0,1-.5.5h-1a.47.47,0,0,1-.5-.5h0A.47.47,0,0,1,11.5,7.4Z"
        />
        <path
          d="M11.5,4.4h1a.47.47,0,0,1,.5.5h0a.47.47,0,0,1-.5.5h-1a.47.47,0,0,1-.5-.5h0A.47.47,0,0,1,11.5,4.4Z"
        /></svg
      ><svg class="alt" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M10,7.17V4.4a2,2,0,0,1,4,0v6.77l1.71,1.71A4.06,4.06,0,0,0,16,11.4v-7a4,4,0,0,0-8,0v.77Z"
        />
        <path d="M8,10.83v.57a4,4,0,0,0,4,4,4.36,4.36,0,0,0,.52-.05Z" />
        <path
          d="M19.36,16.53,20.85,18A9.85,9.85,0,0,0,22,13.4a10.14,10.14,0,0,0-4-8V8.1a7.76,7.76,0,0,1,2,5.3A8,8,0,0,1,19.36,16.53Z"
        />
        <path
          d="M16.89,19.71A7.92,7.92,0,0,1,12,21.4a8,8,0,0,1-8-8A8.21,8.21,0,0,1,5.68,8.51L4.26,7.09A9.92,9.92,0,0,0,2,13.4a10,10,0,0,0,10,10,9.93,9.93,0,0,0,6.31-2.26Z"
        />
        <path d="M11.12,14a1,1,0,0,0-.12.45v3a1,1,0,0,0,2,0V15.83Z" />
        <path
          d="M11.5,7.4h1a.47.47,0,0,1,.5.5h0a.47.47,0,0,1-.5.5h-1a.47.47,0,0,1-.5-.5h0A.47.47,0,0,1,11.5,7.4Z"
        />
        <path
          d="M11.5,4.4h1a.47.47,0,0,1,.5.5h0a.47.47,0,0,1-.5.5h-1a.47.47,0,0,1-.5-.5h0A.47.47,0,0,1,11.5,4.4Z"
        />
        <rect
          x="11"
          y="-3.39"
          width="2"
          height="30.78"
          transform="translate(-4.97 12) rotate(-45)"
        /></svg
    ></span>
  </button>
</template>

<script>
import { controllerLocalSettings } from '@/controller/mixins/localSettings';
import { mapState } from 'vuex';

export default {
  name: 'VideoAudioMute',
  mixins: [controllerLocalSettings],
  data() {
    return {
      settingKey: 'audioMuted',
    };
  },
  computed: {
    ...mapState('controller', {
      counterStartTimestamp: (state) => state.videoCallStartTimestamp,
    }),
    isMuted() {
      return this.getLocalSetting(this.settingKey);
    },
  },
  methods: {
    toggleMuted() {
      this.setLocalSetting(this.settingKey, !this.isMuted);
    },
  },
  mounted() {
    // console.log('Audio mute button created');
    if (this.getLocalSetting(this.settingKey) === undefined) {
      // console.log('Is currently undefined');
      this.setLocalSetting(this.settingKey, false);
    }
  },
};
</script>

<style scoped></style>
