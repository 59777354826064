import { events } from '@/shared/classes/Video/events';

/**
 * The Video Client is an abstraction to manage the receipt and request of
 * the video call settings from the Video Controller on the ECP side of the
 * application.
 *
 * To support asynchronous initialisation, it will ask the Video Controller
 * to send over the settings for the call when its created. It exposes a
 * waitForVideoCallSettings promise that is only resolved once these have been
 * received, which allows any UI component to proceed with call joining only
 * once the settings are defined.
 */
export default class VideoClient {
  constructor(messageBroker, controllerDeviceId) {
    this._messageBroker = messageBroker;
    this._controllerDeviceId = controllerDeviceId;
    this._initialiseMessageBrokerHandlers();
    this._requestVideoCallDetails();
  }

  get type() {
    return this._config !== undefined ? this._config.type : undefined;
  }

  get joinSettings() {
    return this._config !== undefined ? this._config.settings : {};
  }

  /**
   * When the returned promise from this is resolved, we have video call
   * settings that can be used to initialise the call.
   *
   * @returns {Promise}
   */
  waitForVideoCallSettings() {
    if (this._config !== undefined) {
      return Promise.resolve(this);
    }

    const settingsPromise = new Promise((resolve) => {
      this._settingsResolver = resolve;
    });
    return this._requestVideoCallDetails().then((_) => {
      return settingsPromise;
    });
  }

  /**
   * Send a message to the controller device to request the video call config
   *
   * @returns {Promise}
   * @private
   */
  _requestVideoCallDetails() {
    return this._messageBroker
      .sendUnacknowledgedMessage(events.GET_VIDEOCALL_DETAILS, this._controllerDeviceId)
      .then((_) => this);
  }

  /**
   * stores the call details received from the message, and then
   * calls the cached resolver for retrieving them (if one is defined)
   *
   * @param data
   * @private
   */
  _handleSettingVideoCallDetails(data) {
    this._config = data;
    if (this._settingsResolver !== undefined) {
      this._settingsResolver();
    }
    this._settingsResolver = undefined;
  }

  _initialiseMessageBrokerHandlers() {
    [[events.SET_VIDEOCALL_DETAILS, '_handleSettingVideoCallDetails']].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );
  }
}
