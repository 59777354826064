import { states as assessmentWorkFlowStates } from './workflow';
import { events as assessmentBrokerMessages } from '@/shared/classes/Assessment/events';

import VideoController from '@/controller/classes/VideoController';
import ExaminationController from '@/controller/classes/ExaminationController';

import { log } from '@/shared/utils';

import lang from '@/controller/lang/en_GB';
const Signal = require('signals');

/**
 * The overall controller of the entire Assessment process once communication is initialised
 */
export default class AssessmentController {
  constructor(messageBroker) {
    this._messageBroker = messageBroker;
    this._initialiseSignals();
    this._initialiseWorkflow();
    this._initialiseMessageBrokerHandlers();
    this._initialiseMessageBrokerSignalHandlers();
    // set properties for reactivity
    this._patientDeviceId = undefined; // we track this so that we can send messages to the patient device
    this._examinationController = undefined;
  }

  get videoController() {
    return this._videoController;
  }

  get workflowState() {
    return this._workflowState;
  }

  set workflowState(state) {
    if (!Object.values(assessmentWorkFlowStates).includes(state)) {
      throw new Error(`Invalid workflow state ${state}`);
    }
    this._workflowState = state;
    this.workflowStateChanged.dispatch(state);

    this._dispatchAppStateUpdate();
  }

  get examinationController() {
    if (this._examinationController === undefined) {
      this._examinationController = new ExaminationController(
        this._messageBroker,
        this._patientDeviceId,
      );
    }

    return this._examinationController;
  }

  get patientPresent() {
    return this._patientDeviceId !== undefined;
  }

  startAssessment() {
    return this._messageBroker
      .sendAcknowledgedMessage(assessmentBrokerMessages.ASSESSMENT_START, this._patientDeviceId)
      .then(() => {
        this.workflowState = assessmentWorkFlowStates.IDENTIFY_PATIENT;
      });
  }

  endAssessment() {
    this.workflowState = assessmentWorkFlowStates.COMPLETED;
  }

  /**
   * Terminate the assessment without further interaction with patient
   * whether or not they have connected - note does not change workflow state
   * here, as often when abandoning we don't want to perform any further workflow
   * processing
   */
  abandonAssessment() {
    let abandonedPromise;
    if (this._patientDeviceId) {
      abandonedPromise = this._messageBroker.sendUnacknowledgedMessage(
        assessmentBrokerMessages.ASSESSMENT_CANCELLED,
        this._patientDeviceId,
      );
    } else {
      abandonedPromise = Promise.resolve();
    }
    return abandonedPromise.then(() => {
      this._patientDeviceId = undefined;
    });
  }

  closePatientConnection() {
    if (!this._patientDeviceId) {
      // no patient device to disconnect
      return Promise.resolve();
    }

    return this._messageBroker
      .sendAcknowledgedMessage(assessmentBrokerMessages.ASSESSMENT_END, this._patientDeviceId)
      .then(() => {
        this._patientDeviceId = undefined;
      });
  }

  /**
   * Remove all asynchronous behaviour from the controller
   */
  release() {
    this._messageBroker.release();
    this._removeAllSignalHandlers();
    if (this._videoController) {
      this._videoController.release();
      delete this._videoController;
    }
    if (this._examinationController) {
      this._examinationController.release();
      delete this._examinationController;
    }
  }

  patientPassedIdentification() {
    this.workflowState = assessmentWorkFlowStates.PATIENT_ID_SUCCESS;
  }

  patientFailedIdentification() {
    return this.closePatientConnection().then(() => {
      this.workflowState = assessmentWorkFlowStates.PATIENT_ID_FAIL;
    });
  }

  createVideoController(config) {
    if (this._videoController !== undefined) {
      // throw new Error('Cannot have multiple video controllers created. Reset first.');
      log('Cannot have multiple video controllers created. Reset first.');
    } else {
      this._videoController = new VideoController(
        this._messageBroker,
        this._patientDeviceId,
        config,
      );
    }
    return this.videoController;
  }

  _handlePatientReady() {
    this.workflowState = assessmentWorkFlowStates.PATIENT_READY;
  }

  _handlePatientLeave() {
    if (this.workflowState === assessmentWorkFlowStates.COMPLETED || !this.patientPresent) {
      // it does not matter that the patient has left
      return;
    }

    this._patientDeviceId = undefined;

    if (this.workflowState === assessmentWorkFlowStates.PATIENT_CONNECTED) {
      this.workflowState = assessmentWorkFlowStates.PATIENT_LEFT_BEFORE_READY;
    } else {
      this._newError({
        id: 'patient-abandon',
        title: lang.error.patient.abandon_assessment.title,
        body: lang.error.patient.abandon_assessment.body,
        end: true,
        restart: false,
        dismissable: false,
      });

      this.workflowState = assessmentWorkFlowStates.PATIENT_LEFT;
    }
  }

  _initialiseWorkflow() {
    this._workflowState = assessmentWorkFlowStates.AWAITING_PATIENT;
  }

  _initialiseMessageBrokerSignalHandlers() {
    this._messageBroker.remoteConnectionReady.add((clientId) => {
      // TODO: this will need to handle multiple device connections if we support desktop/phone combination
      this._patientDeviceId = clientId;
      this.workflowState = assessmentWorkFlowStates.PATIENT_CONNECTED;

      this._messageBroker.sendAcknowledgedMessage(
        assessmentBrokerMessages.ASSESSMENT_INIT,
        this._patientDeviceId,
        { controllerDeviceId: this._messageBroker.id },
      );
    });
    this._messageBroker.messageAcknowledgmentDelayed.add((messageId) => {
      this._newError({
        id: messageId,
        title: lang.error.message_broker.delay.title,
        body: lang.error.message_broker.delay.body,
        dismissable: true,
        restart: false,
      });
    });
    this._messageBroker.delayedMessageAcknowledged.add((messageId) => {
      this._clearError(messageId);
    });
  }

  _initialiseMessageBrokerHandlers() {
    [
      [assessmentBrokerMessages.ASSESSMENT_PATIENT_READY, '_handlePatientReady'],
      [assessmentBrokerMessages.ASSESSMENT_PATIENT_LEAVE, '_handlePatientLeave'],
    ].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );
  }

  _initialiseSignals() {
    this.workflowStateChanged = new Signal();
    this.appStateUpdate = new Signal();
    this.newError = new Signal();
    this.clearError = new Signal();
  }

  _removeAllSignalHandlers() {
    this.workflowStateChanged.removeAll();
    this.appStateUpdate.removeAll();
    this.newError.removeAll();
    this.clearError.removeAll();
  }

  _newError(errorData) {
    this.newError.dispatch(errorData);
  }

  _clearError(errorId) {
    this.clearError.dispatch(errorId);
  }

  _dispatchAppStateUpdate() {
    this.appStateUpdate.dispatch();
  }
}
