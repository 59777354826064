<template>
  <div v-if="error" class="pop-up pop-up-error" data-test="error-popup">
    <div class="pop-up-content">
      <div class="panel">
        <div class="panel-header">
          <h1>{{ error.title }}</h1>
        </div>
        <div class="panel-content">
          <p v-html="error.body"></p>
          <p v-if="error.debugInfo">
            <small>({{ error.debugInfo }})</small>
          </p>
          <div v-if="error.copyData" style="display: flex; align-content: flex-start; width: 100%">
            <span style="white-space: nowrap; padding-right: 10px; margin-bottom: 5px"
              >Copy to clipboard</span
            >
            <div class="icon copy likeA" @click="copyDataToClipboard" style="display: block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 14 14"
                style="enable-background: new 0 0 14 14"
                xml:space="preserve"
              >
                <path
                  d="M9.2,0.8H3.2v1h6.1c0.8,0,1.6,0.3,2.1,0.9C12,3.2,12.2,4,12.2,4.8v6h1v-6c0-1.1-0.4-2.1-1.2-2.8C11.3,1.2,10.3,0.8,9.2,0.8z"
                />
                <path
                  d="M8.2,3.2h-5c-1.4,0-2.5,1.1-2.5,2.5v5c0,1.4,1.1,2.5,2.5,2.5h5c1.4,0,2.5-1.1,2.5-2.5v-5C10.8,4.3,9.6,3.2,8.2,3.2zM9.8,10.8c0,0.8-0.7,1.5-1.5,1.5h-5c-0.8,0-1.5-0.7-1.5-1.5v-5c0-0.8,0.7-1.5,1.5-1.5h5c0.8,0,1.5,0.7,1.5,1.5V10.8z"
                />
              </svg>
            </div>
          </div>
          <div style="overflow: hidden; max-height: 25vh">
            <pre style="background-color: lightgrey">{{ error.copyData }}</pre>
          </div>
        </div>
        <div class="btn-row space-above" v-if="error.end || error.restart || error.dismissable">
          <button
            class="item-left"
            v-if="error.end"
            data-test="error-popup-end"
            @click="endSession"
          >
            End session
          </button>
          <button
            class="item-left"
            v-if="error.restart"
            data-test="error-popup-restart"
            @click="restartSession"
          >
            Restart
          </button>
          <button
            class="item-right"
            v-if="error.dismissable"
            data-test="error-popup-close"
            @click="errorPopupClose()"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { copyToClipboard } from '@/shared/utils';

export default {
  name: 'ErrorPopup',
  computed: {
    ...mapState('controller', {
      errors: (state) => state.errors,
    }),
    error() {
      return this.errors.length > 0 ? this.errors[0] : null;
    },
  },
  methods: {
    ...mapActions('controller', [
      'removeAllErrorsAction',
      'removeErrorAction',
      'exitApplicationAction',
      'restartApplicationAction',
    ]),
    errorPopupClose(all = false) {
      if (all) {
        this.removeAllErrorsAction();
      } else {
        this.removeErrorAction(this.error.id);
      }
    },
    restartSession() {
      this.restartApplicationAction().then(() => {
        this.errorPopupClose(true);
      });
    },
    endSession() {
      this.exitApplicationAction().then(() => {
        this.errorPopupClose(true);
      });
    },
    copyDataToClipboard() {
      copyToClipboard(this.error.copyData ?? 'No data to copy');
    },
  },
};
</script>

<style scoped></style>
