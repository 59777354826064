import { sharedGetters } from '@/shared/store/getters';

export const controllerGetters = Object.assign(
  {
    patientId: (state) => {
      if (!state.api || !state.api.sessionData) {
        return undefined;
      }
      return state.api.sessionData.patientId;
    },
    userId: (state) => {
      if (!state.api || !state.api.sessionData) {
        return undefined;
      }
      return state.api.sessionData.userId;
    },
    patientPresent: (state) => {
      if (!state.assessmentController) {
        return false;
      }
      return state.assessmentController.patientPresent;
    },
  },
  sharedGetters,
);
