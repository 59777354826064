import MockChannel from '@/shared/classes/CommsChannel/mock';
import PusherChannel from '@/shared/classes/CommsChannel/pusher';

const channelTypes = {
  pusher: PusherChannel,
  mock: MockChannel,
};

const commsChannelFactory = (type, config) => {
  return new channelTypes[type](config);
};

export { commsChannelFactory };
