import SloanLetters from '@/shared/classes/Optotype/SloanLetters';
import LandoltC from '@/shared/classes/Optotype/LandoltC';
import AucklandPicture from '@/shared/classes/Optotype/AucklandPicture';
import Words from '@/shared/classes/Optotype/Words';

const optotypes = {
  'landolt-c': LandoltC,
  'sloan-letter': SloanLetters,
  'auckland-picture': AucklandPicture,
  word: Words,
};

const optotypeFactory = (optoType) => {
  return new optotypes[optoType]();
};

export { optotypeFactory };
