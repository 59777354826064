import BaseOptotype from '@/shared/classes/Optotype/BaseOptotype';

export default class SloanLetters extends BaseOptotype {
  letterSet = ['C', 'D', 'H', 'K', 'N', 'O', 'R', 'S', 'V', 'Z'];
  iconStyle = 'width: 1em; height: 1em; vertical-align: middle; overflow: hidden;';

  controllerWrapperStyle = '--symbol-font-size:40px; --crowding-box-width:15px';

  get symbolOptions() {
    return this.letterSet;
  }

  getSymbolDisplay(symbolId, spacingScale) {
    if (!this.letterSet.includes(symbolId)) {
      throw new Error(`Unrecognised ${symbolId}`);
    }

    if (spacingScale === undefined) {
      spacingScale = 1;
    }

    const spacerStyle = `width: ${spacingScale}em; height: 1em; vertical-align: middle; overflow: hidden;`;

    return {
      content:
        '<svg style="' +
        this.iconStyle +
        '"><use xlink:href="/svg/sloan-letters-2021.svg#' +
        symbolId +
        '"></use></svg>',
      spacer: `<svg style="${spacerStyle}"><use xlink:href="/svg/sloan-letters-2021.svg#X"></use></svg>`,
      id: symbolId,
    };
  }
}
