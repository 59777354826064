export function getMediaDevices(filters) {
  return navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
    return navigator.mediaDevices.enumerateDevices().then((devices) => {
      return filters && filters.length
        ? devices.filter((device) => filters.includes(device.kind))
        : devices;
    });
  });
}

export default { getMediaDevices };
