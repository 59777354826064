export const sharedState = {
  dataService: undefined,
  api: undefined,
  messageBroker: undefined,
  errors: [],
  confirm: undefined,
  targetRoute: {},
  videoCallSettings: undefined,
  localSettings: undefined,
};
