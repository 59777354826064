<template>
  <div class="wrapper patient">
    <Notice />
    <main class="main">
      <PatientHeader />
      <div class="content-wrapper wrapper-vertical-center">
        <div class="content add-grid">
          <div class="login" v-if="invitation_id" data-test="login-form">
            <h1>Visual Assessment</h1>
            <p>Please enter your PIN to start.</p>
            <div>
              <input
                type="text"
                class="pin"
                :class="[{ error: loginFailure }]"
                maxlength="4"
                pattern="\d*"
                placeholder="____"
                v-model="pin"
                data-test="input-pin-field"
                @keyup.enter="loginPatient"
              />
            </div>
            <p v-if="showErrors && showPinLengthError" class="footnote error-message">
              Your pin must be {{ requiredPinLength }} digits long
              <span class="icon"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                  style="enable-background: new 0 0 16 16"
                  xml:space="preserve"
                >
                  <path
                    d="M8,15.5c-4.1,0-7.5-3.4-7.5-7.5S3.9,0.5,8,0.5s7.5,3.4,7.5,7.5S12.1,15.5,8,15.5z M8,1.5C4.4,1.5,1.5,4.4,1.5,8
                                        s2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5S11.6,1.5,8,1.5z"
                  />
                  <path
                    d="M7.6,9.7L7.2,6.1V4.4h1.5v1.7L8.4,9.7H7.6z M7.3,11.6v-1.4h1.4v1.4H7.3z"
                  /></svg
              ></span>
            </p>
            <p
              v-if="showErrors && loginFailure"
              class="footnote error-message"
              data-test="login-error"
            >
              Your details are not recognised, please check and try again
              <span class="icon"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                  style="enable-background: new 0 0 16 16"
                  xml:space="preserve"
                >
                  <path
                    d="M8,15.5c-4.1,0-7.5-3.4-7.5-7.5S3.9,0.5,8,0.5s7.5,3.4,7.5,7.5S12.1,15.5,8,15.5z M8,1.5C4.4,1.5,1.5,4.4,1.5,8
                                        s2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5S11.6,1.5,8,1.5z"
                  />
                  <path
                    d="M7.6,9.7L7.2,6.1V4.4h1.5v1.7L8.4,9.7H7.6z M7.3,11.6v-1.4h1.4v1.4H7.3z"
                  /></svg
              ></span>
            </p>
            <div>
              <button
                data-test="login-button"
                @click="loginPatient"
                @keyup.enter="loginPatient"
                :disabled="loggingIn"
              >
                Confirm
              </button>
            </div>
          </div>
          <div class="login" data-test="missing-data-message" v-else>
            <h1>Welcome</h1>
            <p>
              To go further you need access details, please speak to your Eye Care Professional.
            </p>
          </div>
        </div>
        <ErrorPopup />
      </div>
      <VAFooter />
    </main>
  </div>
</template>

<script>
import PatientHeader from '@/patient/components/Header';
import ErrorPopup from '@/patient/components/ErrorPopup';
import Notice from '@/shared/components/Notice';
import VAFooter from '@/shared/components/VAFooter';
import { mapActions } from 'vuex';

export default {
  name: 'PatientAuth',
  components: { Notice, ErrorPopup, PatientHeader, VAFooter },
  data() {
    return {
      pin: '',
      requiredPinLength: 4,
      loginFailure: false,
      loggingIn: false,
      showErrors: false,
    };
  },
  computed: {
    invitation_id() {
      return this.$route.params.invitation_id ? this.$route.params.invitation_id : null;
    },
    showPinLengthError() {
      return !this.validPinLength;
    },
    validPinLength() {
      return this.pin.length === this.requiredPinLength;
    },
  },
  methods: {
    ...mapActions('patient', ['loginPatientAction']),
    loginPatient() {
      if (this.loggingIn) {
        return;
      }
      this.showErrors = true;
      if (this.invitation_id && this.validPinLength) {
        this.loggingIn = true;
        this.loginFailure = false;
        this.loginPatientAction({ invitationId: this.invitation_id, invitationPin: this.pin })
          .then(() => {
            this.$router.push({ name: 'patient.assessment.welcome' }).catch(() => {});
          })
          .catch(() => {
            this.loginFailure = true;
          })
          .finally(() => {
            this.loggingIn = false;
          });
      }
    },
  },
  mounted() {
    if (this.$route.query.pin) {
      this.pin = this.$route.query.pin;
    }
  },
  watch: {
    pin: {
      handler() {
        this.showErrors = false;
      },
    },
  },
};
</script>
