<template>
  <span
    class="btn btn-reverse timer form-group-item"
    :class="[{ 'timer-active': counterStartTimestamp, disabled: !counterStartTimestamp }]"
    >Call time - <span class="timer-even-width">{{ counterElapsed.h }}</span> :
    <span class="timer-even-width">{{ counterElapsed.m }}</span> :
    <span class="timer-even-width">{{ counterElapsed.s }}</span></span
  >
</template>

<script>
import { mapState } from 'vuex';
import { getCurrentTimestamp, getStructuredElapsedTime } from '@/shared/utils';

export default {
  name: 'VideoTimer',
  data() {
    return {
      defaultElapsed: {
        h: '00',
        m: '00',
        s: '00',
      },
      counterInterval: undefined,
      counterElapsed: undefined,
    };
  },
  computed: {
    ...mapState('controller', {
      counterStartTimestamp: (state) => state.videoCallStartTimestamp,
    }),
  },
  methods: {
    startCounter() {
      this.counterInterval = setInterval(this.updateElapsedCounterTime, 1000);
    },
    resetCounter() {
      if (this.counterInterval) {
        clearInterval(this.counterInterval);
        this.counterElapsed = this.defaultElapsed;
      }
    },
    updateElapsedCounterTime() {
      this.counterElapsed = getStructuredElapsedTime(
        this.counterStartTimestamp,
        getCurrentTimestamp(),
      );
    },
  },
  watch: {
    counterStartTimestamp(newValue, oldValue) {
      if (oldValue === undefined && newValue !== undefined) {
        this.startCounter();
      } else if (newValue === undefined) {
        this.resetCounter();
      }
    },
  },
  created() {
    this.counterElapsed = this.defaultElapsed;
  },
  unmounted() {
    this.resetCounter();
  },
};
</script>

<style scoped></style>
