import { events as assessmentBrokerMessages } from '@/shared/classes/Assessment/events';
import VideoClient from '@/patient/classes/VideoClient';
import { states as assessmentDisplayStates } from '@/patient/classes/AssessmentClient/displayStates';
import ExaminationClient from '@/patient/classes/ExaminationClient';
import lang from '@/patient/lang/en_GB';
import PatientDistanceTrackingClient from '@/patient/classes/PatientDistanceTrackingClient';
import { log } from '@/shared/utils';

const Signal = require('signals');

export default class AssessmentClient {
  constructor(messageBroker) {
    this._messageBroker = messageBroker;
    this.isFinishing = false;

    this._initialiseSignals();
    this._initialiseMessageBrokerSignalHandlers();
    this._initialisePromises();
    this._initialiseMessageBrokerHandlers();
    this._initialiseAssessmentDisplayState();
  }

  /**
   * Returns a promise that resolves once we have received a message from the ECP controller
   * to begin the assessment.
   *
   * @returns {Promise<unknown>}
   */
  waitForControllerReady() {
    return this._controllerReady.promise;
  }

  /**
   * The VideoClient instance that has been created for managing the call for the patient to the
   * ECP.
   *
   * @returns {VideoClient}
   */
  get videoClient() {
    return this._videoClient;
  }

  get assessmentDisplayState() {
    return this._assessmentDisplayState;
  }

  set assessmentDisplayState(state) {
    if (!Object.values(assessmentDisplayStates).includes(state)) {
      throw new Error(`Invalid display state ${state}`);
    }
    this._assessmentDisplayState = state;

    this._dispatchAppStateUpdate();
  }

  /**
   * Create a VideoClient to manage the call for the patient device
   *
   * @returns {VideoClient}
   */
  createVideoClient() {
    if (this._videoClient !== undefined) {
      throw new Error('Cannot have multiple video clients created. Reset first.');
    }
    if (this._controllerDeviceId === undefined) {
      throw new Error('Cannot create video client without controller device being set.');
    }
    this._videoClient = new VideoClient(this._messageBroker, this._controllerDeviceId);

    return this.videoClient;
  }

  /**
   * The ExaminationClient instance that has been created to manage the measurement display process
   * for the patient device.
   *
   * @returns {ExaminationClient}
   */
  get examinationClient() {
    return this._examinationClient;
  }

  /**
   * Create a ExaminationClient to manage the measurement processes for the patient device
   *
   * @returns {ExaminationClient}
   */
  createExaminationClient() {
    if (this._examinationClient !== undefined) {
      throw new Error('Cannot have multiple examination clients created. Reset first.');
    }
    if (this._controllerDeviceId === undefined) {
      throw new Error('Cannot create examination client without controller device being set.');
    }
    this._examinationClient = new ExaminationClient(this._messageBroker, this._controllerDeviceId);

    this._examinationClient.appStateUpdate.add(() => {
      this._dispatchAppStateUpdate();
    });

    return this.examinationClient;
  }

  get patientDistanceTrackingClient() {
    if (this._patientDistanceTrackingClient === undefined) {
      this._patientDistanceTrackingClient = new PatientDistanceTrackingClient(
        this._messageBroker,
        this._controllerDeviceId,
      );
    }

    return this._patientDistanceTrackingClient;
  }

  /**
   * Called when the patient has clicked through all initial slides and is ready to start
   */
  setClientReady() {
    this._messageBroker.sendAcknowledgedMessage(
      assessmentBrokerMessages.ASSESSMENT_PATIENT_READY,
      this._controllerDeviceId,
    );
  }

  /**
   * Called when patient chooses to abandon the assessment
   */
  abandonAssessment() {
    this._messageBroker
      .sendAcknowledgedMessage(
        assessmentBrokerMessages.ASSESSMENT_PATIENT_LEAVE,
        this._controllerDeviceId,
      )
      .then(() => {
        // Move patient to end screen when they've quit
        this.assessmentDisplayState = assessmentDisplayStates.ASSESSMENT_FINISHED;
      });
  }

  _initialisePromises() {
    this._controllerReady = this._constructCallbackPromise();
  }

  _initialiseMessageBrokerSignalHandlers() {
    this._messageBroker.messageAcknowledgmentDelayed.add((messageId) => {
      log(['messageDelayed signal received', messageId]);
      this._newError({
        id: messageId,
        title: lang.error.message_broker.delay.title,
        body: lang.error.message_broker.delay.body,
        dismissable: false,
      });
    });
    this._messageBroker.delayedMessageAcknowledged.add((messageId) => {
      log(['delayedMessageAcknowledged signal received', messageId]);
      this._clearError(messageId);
    });
  }

  _constructCallbackPromise() {
    const promise = {};
    promise.promise = new Promise((resolve, reject) => {
      promise.resolve = resolve;
      promise.reject = reject;
    });
    return promise;
  }

  _handleAssessmentInit(data) {
    this._controllerDeviceId = data.controllerDeviceId;
  }

  _handleAssessmentStart() {
    this._controllerReady.resolve();
    this.assessmentDisplayState = assessmentDisplayStates.ASSESSMENT_STARTED;
  }

  _handleAssessmentEnd() {
    if (this.assessmentDisplayState !== assessmentDisplayStates.ASSESSMENT_STARTED) {
      this.assessmentDisplayState = assessmentDisplayStates.ASSESSMENT_CANCELLED;
    } else {
      this.assessmentDisplayState = assessmentDisplayStates.ASSESSMENT_FINISHED;
    }
  }

  _handleAssessmentCancelled() {
    this.assessmentDisplayState = assessmentDisplayStates.ASSESSMENT_CANCELLED;
  }

  _initialiseMessageBrokerHandlers() {
    [
      [assessmentBrokerMessages.ASSESSMENT_INIT, '_handleAssessmentInit'],
      [assessmentBrokerMessages.ASSESSMENT_START, '_handleAssessmentStart'],
      [assessmentBrokerMessages.ASSESSMENT_END, '_handleAssessmentEnd'],
      [assessmentBrokerMessages.ASSESSMENT_CANCELLED, '_handleAssessmentCancelled'],
    ].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );
  }

  _initialiseSignals() {
    this.newError = new Signal();
    this.clearError = new Signal();
    this.appStateUpdate = new Signal();
  }

  _newError(errorData) {
    this.newError.dispatch(errorData);
  }

  _clearError(errorId) {
    this.clearError.dispatch(errorId);
  }

  _dispatchAppStateUpdate() {
    this.appStateUpdate.dispatch();
  }

  _initialiseAssessmentDisplayState() {
    this._assessmentDisplayState = assessmentDisplayStates.ASSESSMENT_PENDING;
  }
}
