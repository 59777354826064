import BaseGenerator from '@/controller/classes/MeasureController/Generator/BaseGenerator';

export default class LetterGenerator extends BaseGenerator {
  get letterSet() {
    return this.optotype.symbolOptions;
  }

  _maximumLineSpacingRequired(symbolCount, spacingScale) {
    let totalLineLength = symbolCount;
    // add spaces - between symbols, and then one either side for crowding box
    totalLineLength += (symbolCount + 1) * spacingScale;
    // crowding box line thickness
    totalLineLength += 0.2 * 2;

    return totalLineLength;
  }

  generateLine(previousLines, length) {
    const firstLetterOfPreviousLine = previousLines.length
      ? previousLines[previousLines.length - 1][0]
      : undefined;

    const requiredLetterSetSize = firstLetterOfPreviousLine ? length + 1 : length;
    if (this.letterSet.length < requiredLetterSetSize) {
      throw new Error('Not enough available letters to support required line length of ' + length);
    }

    const selectedLetters = [];
    while (selectedLetters.length < length) {
      const candidateLetters = this.letterSet.filter(
        (letter) =>
          (selectedLetters.length !== 0 || letter !== firstLetterOfPreviousLine) && // don't start line with same letter
          !selectedLetters.includes(letter), // don't repeat letters
      );

      selectedLetters.push(candidateLetters[Math.floor(Math.random() * candidateLetters.length)]);
    }

    return selectedLetters;
  }
}
