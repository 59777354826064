<template>
  <footer class="footer">
    <section>
      <span
        >&copy; {{ fullYear }}
        <a href="https://aos-hub.com/" target="_blank" rel="noopener noreferrer"
          >Advanced Ophthalmic Systems Ltd</a
        >
        &nbsp;&mdash;&nbsp; AOS VA v{{ applicationVersion }}</span
      >
      <slot></slot>
    </section>
  </footer>
</template>
<script>
import { version } from './../../../package.json';
export default {
  name: 'VAFooter',
  data() {
    return {
      applicationVersion: version ?? '0.0.1-alpha',
      fullYear: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="scss" scoped></style>
