import { patientActions } from './actions';
import { patientGetters } from '@/patient/store/getters';
import { patientMutations } from './mutations';
import { sharedState } from '@/shared/store/state';
import { getConfig } from '@/shared/config';

const patientState = Object.assign(
  {
    assessmentClient: undefined,
    videoClient: undefined,
    patientDistanceTrackerReady: false,
    patientDistanceTrackerModelReady: false,
    patientDistanceTracker: undefined,
    patientDistanceTrackingClient: undefined,
    examinationClient: undefined,
    examinationDisplayState: undefined,
    calibrationDisplayState: null,
    lineDisplayDelay: getConfig('VUE_APP_PATIENT_LINE_DELAY', 0),
    videoParticipants: null,
  },
  sharedState,
);

export default {
  namespaced: true,
  state: () => patientState,
  getters: patientGetters,
  actions: patientActions,
  mutations: patientMutations,
};
