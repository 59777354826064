import { events as measurementBrokerMessages } from '@/shared/classes/PatientDistanceTracking/events';
import { log } from '@/shared/utils';

export default class PatientDistanceTrackingClient {
  constructor(messageBroker, controllerDeviceId) {
    this._messageBroker = messageBroker;
    this._controllerDeviceId = controllerDeviceId;
    this.currentDistance = undefined;
    this._initialised = false;
    this.calibrationCallback = undefined;
    this.resetCallback = undefined;
    this.activeCallback = undefined;
  }

  /**
   * Set up asynchronous behaviours. This should be called before using the controller
   * Note: this is separated from the constructor to allow it to be proxied (made reactive) before
   * the necessary callbacks are defined. This ensures that they act on the proxied instance.
   */
  initialise() {
    if (this._initialised) {
      return;
    }
    this._initialiseMessageBrokerHandlers();
    this._initialised = true;
  }

  updateDistance(distance) {
    this.currentDistance = distance;
    this._messageBroker.sendUnacknowledgedMessage(
      measurementBrokerMessages.DISTANCE_MEASURED,
      this._controllerDeviceId,
      {
        distance: distance,
      },
    );
  }

  setCalibrationCallback(callback) {
    this.calibrationCallback = callback;
  }

  setResetCallback(callback) {
    this.resetCallback = callback;
  }

  setActiveCallback(callback) {
    this.activeCallback = callback;
  }

  _handleCalibrateRequest(data) {
    if (this.calibrationCallback) {
      this.calibrationCallback(data.height, data.gender);
    } else {
      log('received calibration request for distance tracking with no callback defined');
    }
  }

  _handleResetRequest() {
    if (this.resetCallback) {
      this.resetCallback();
    } else {
      log('received reset request for distance tracking with no callback defined');
    }
  }

  _handlePauseRequest() {
    if (this.activeCallback) {
      this.activeCallback(false);
    } else {
      log('received pause request for distance tracking with no callback defined');
    }
  }

  _handleUnPauseRequest() {
    if (this.activeCallback) {
      this.activeCallback(true);
    } else {
      log('received pause request for distance tracking with no callback defined');
    }
  }

  _initialiseMessageBrokerHandlers() {
    [
      [measurementBrokerMessages.CALIBRATE, '_handleCalibrateRequest'],
      [measurementBrokerMessages.RESET, '_handleResetRequest'],
      [measurementBrokerMessages.PAUSE, '_handlePauseRequest'],
      [measurementBrokerMessages.UNPAUSE, '_handleUnPauseRequest'],
    ].forEach((cmdMap) =>
      this._messageBroker.attachMessageHandler(cmdMap[0], this[cmdMap[1]].bind(this)),
    );
  }
}
