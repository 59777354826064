import { getConfig } from '@/shared/config';

/**
 * Log for debugging mode
 * @param {*} content
 */
export const log = (content) => {
  if (getConfig('VUE_APP_DEBUG', false)) {
    // eslint-disable-next-line no-console
    console.log(content);
  }
};

export const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const keyChecker = (object, requiredKeys) => {
  return (
    requiredKeys.filter((requiredKey) => !Object.keys(object).includes(requiredKey)).length === 0
  );
};

export const roundAccurately = (number, decimalPlaces) => {
  return Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
};

export const combineRouteElements = (baseRouteElements, additionalRouteElements) => {
  if (additionalRouteElements === undefined) {
    return baseRouteElements;
  }

  return {
    routeName: baseRouteElements.routeName.concat(additionalRouteElements.routeName),
    params: Object.assign({}, baseRouteElements.params, additionalRouteElements.params),
  };
};

export const debounce = function (func, wait = 300) {
  let timeout;
  return function () {
    const args = arguments;
    const context = this;
    // this allows us to test debounced functions by not using timeouts
    if (wait <= 0) {
      func.apply(context, args);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    }
  };
};

export function getCurrentTimestamp() {
  return new Date();
}

export function getStructuredElapsedTime(startTime, endTime) {
  let timeDiff = endTime.getTime() - startTime.getTime();

  // Convert time difference from milliseconds to seconds
  timeDiff = timeDiff / 1000;
  const seconds = Math.floor(timeDiff % 60);
  const secondsAsString = seconds < 10 ? '0' + seconds : seconds;

  // Convert time difference from seconds to minutes using %
  timeDiff = Math.floor(timeDiff / 60);
  const minutes = timeDiff % 60;
  const minutesAsString = minutes < 10 ? '0' + minutes : minutes;

  // Convert time difference from minutes to hours
  timeDiff = Math.floor(timeDiff / 60);
  const hours = timeDiff % 24;

  // Convert time difference from hours to days
  timeDiff = Math.floor(timeDiff / 24);
  const days = timeDiff;
  const totalHours = hours + days * 24; // add days to hours
  const totalHoursAsString = totalHours < 10 ? '0' + totalHours : totalHours;

  return {
    h: totalHoursAsString,
    m: minutesAsString,
    s: secondsAsString,
  };
}

/**
 * Hardcoded generation of comms definition for use in message broker
 * @param jwt
 * @param channelName
 * @returns {{}}
 */
export const generateCommsDefinitionForPusher = (jwt, channelName) => {
  try {
    return {
      type: 'pusher',
      settings: {
        channelName: channelName,
        settings: {
          APP_KEY: process.env.VUE_APP_PUSHER_KEY,
          options: {
            authEndpoint: `${process.env.VUE_APP_BACKEND_ROOT}/auth/msg-broker`,
            cluster: 'eu',
            auth: {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            },
          },
        },
      },
    };
  } catch (error) {
    log('ERROR: could not decode jwt', error);
  }
};

/**
 * Copys string to the clipboard
 */
export const copyToClipboard = (string) => {
  navigator.clipboard.writeText(string);
};

export default { log, isInViewport };
